global.themes = [
    {
        id: 0,

        // THEMED COLORS
        theme_lighteen: '#D7EEFF',
        theme_lighter: '#BBD6FD',
        theme_light: '#95B2DC',
        theme_medium: '#5F7AA1',
        theme_dark: '#3D5273',
        theme_darker: '#29364A',

        theme_white: '#FFFFFF',

        // BUTTONS
        button_dark: '#3D5273',

        // MENU
        menu: '#DDEEFF',
        menu_item: '#BBDDFF',
        menu_line: '#BBDDFF',
        menu_selected: '#0077FF',
    },

]