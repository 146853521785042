import React, { useEffect, useState, useRef } from 'react';
import './react/App.css';
import './react/w3.css';
import './themes';
import { Button, IconButton, InputAdornment, MenuItem, Paper, Select, TextField } from '@mui/material';
import { Routes, Route, Link, useNavigate, Navigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import "animate.css/animate.min.css";
import { sk, cz } from './globals.js';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt, faShoppingCart, faSearch, faAngleDoubleLeft, faChevronLeft, faAngleDoubleRight, faChevronRight, faBars, faCross, faTimesCircle, faEye, faAngleLeft, faCheckCircle, faAngleRight, faUser, faAsterisk, faExclamation, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Drawer } from '@mui/material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme2-6.css";
import { InputLabel } from '@mui/material';
import { Badge } from '@mui/material';
import { DialogActions } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { styles } from './styles';
import './items.css';

export const Init = () => {
    // Initializovanie Cookies

    // LOCAL STORE - REMOVE ALL
    localStorage.removeItem('esthederm-salon-token');
    localStorage.removeItem('esthederm-salon-order-typ');
    //localStorage.removeItem('esthederm-salon-cookies');
    localStorage.removeItem('esthederm-salon-page');
    localStorage.removeItem('esthederm-salon-product-id');

    // COOKIES - mazat len pocas demo testovania
    if (global.testing == true) {
        localStorage.removeItem('cookies');
    }
}

export const dbURL = () => {
    if (global.testing == true) {
        return global.db_url + '/';
    } else {
        return window.location.origin + '/app_web/';
    }
}

export function GetImageLink(path) {
    var result = '';
    let uniqueId = '?' + global.version + '+231sdf65f4s';

    if (path != '' && path != false && path != null) {
        result = path.substring(0, 4) == 'http' ? path : global.images_url + "/" + path + uniqueId;
    }
    return result;
}

export function IsEmpty(data) {
    // kontrola ci je retazec prázdny
    if (data.trim() == '') {
        return true;
    } else {
        return false;
    }

}

export function notNumber(data) {
    // nie je císlo ?
    var number = data.trim().replaceAll(' ', '');
    number = number.replaceAll(',', '.');

    if (number != '') {
        if (isNaN(number)) {
            return true
        } else {
            return false;
        }
    } else {
        // prázdny retazec
        return true;
    }
}

export function nonEmail(mail) {
    // kontrola správnosti e-mailovej adresy - ak je chyba vráti -> true
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(mail)) {
        return (false)
    }
    return (true);
};

export const Verify = () => {
    Debug('alias: ', LoadAlias());
    Debug('salon: ', LoadSalon());
    Debug('language: ', LoadLanguage());
    Debug('client: ', LoadClient());
    Debug('public: ', LoadPublic());
    Debug('settings: ', LoadSalonSettings());

    if (LoadLanguage() != null) {
        if (LoadAlias() != null) {
            if (LoadSalon() != null) {
                if (LoadClient() != null) {
                    return true;
                } else if (LoadPublic() != null) {
                    return true;
                }
            }
        }
    }
    return false;
}

export const ChangeLanguageURL = () => {
    // zmena jazyka podla URL koncovky    
    var url = window.location.origin;
    if (global.testing == true) {
        url = 'https://clients.vision-app.sk';
    }
    var ext = url.split('.').pop();
    if (ext.toLowerCase() == 'cz') {
        var lang = 1;
    } else {
        var lang = 0;
    }

    return lang;
}

export const GetLanguage = (lang) => {
    // Vráti odkaz na jazykovú verziu    
    switch (parseInt(lang)) {
        case 0:
            return sk;
        case 1:
            return cz;
        default:
            return sk;
    }
}

export const ChangeLanguage = (lang) => {
    // zmena jazyka
    SaveLanguage(lang);
    return lang;
}

export const SaveProductsTime = () => {
    var now = new Date();
    now.setMinutes(now.getMinutes() + global.redraw_time);
    var tm = new Date(now);
    var tm = now.getFullYear() + now.getMonth().toString().padStart(2, '0') + now.getDate().toString().padStart(2, '0');
    tm = tm + now.getHours().toString().padStart(2, '0') + now.getMinutes().toString().padStart(2, '0');
    localStorage.setItem('products_time', tm);
}

export const NeedRedrawProducts = () => {
    var stored = localStorage.getItem('products_time');
    var now = new Date();
    var tm = now.getFullYear() + now.getMonth().toString().padStart(2, '0') + now.getDate().toString().padStart(2, '0');
    tm = tm + now.getHours().toString().padStart(2, '0') + now.getMinutes().toString().padStart(2, '0');
    Debug('Need redrap products time', tm);
    Debug('Need redrap products time', stored);
    if (parseInt(tm) > parseInt(stored)) {
        return true;
    } else {
        return false;
    }
}


export const SaveTime = () => {
    var now = new Date();
    now.setMinutes(now.getMinutes() + global.redraw_time);
    var tm = new Date(now);
    var tm = now.getFullYear() + now.getMonth().toString().padStart(2, '0') + now.getDate().toString().padStart(2, '0');
    tm = tm + now.getHours().toString().padStart(2, '0') + now.getMinutes().toString().padStart(2, '0');
    localStorage.setItem('redraw_time', tm);
}

export const NeedRedraw = () => {
    var stored = localStorage.getItem('redraw_time');
    var now = new Date();
    var tm = now.getFullYear() + now.getMonth().toString().padStart(2, '0') + now.getDate().toString().padStart(2, '0');
    tm = tm + now.getHours().toString().padStart(2, '0') + now.getMinutes().toString().padStart(2, '0');
    Debug('Stored time:', stored);
    Debug('Now time:', tm);
    if (parseInt(tm) > parseInt(stored)) {
        return true;
    } else {
        return false;
    }
}

export const SaveToken = (token) => {
    localStorage.setItem('esthederm-salon-token', token);
}

export const LoadToken = () => {
    var token = localStorage.getItem('esthederm-salon-token');
    if (token != null) {
        return token;
    } else {
        return false;
    }
}

export const SaveCookies = (data) => {
    localStorage.setItem('esthederm-salon-cookies', data);
}


export const LoadCookies = () => {
    var data = localStorage.getItem('esthederm-salon-cookies');
    if (data != null) {
        return data;
    } else {
        return null;
    }
}


export const SaveCardQuantity = (data) => {
    localStorage.setItem('card_quantity', data);
}


export const LoadCardQuantity = () => {
    var data = localStorage.getItem('card_quantity');
    if (data != null) {
        return data;
    } else {
        return 0;
    }
}


export const SaveOrderTyp = (data) => {
    localStorage.setItem('esthederm-salon-order-typ', data);
}


export const LoadOrderTyp = () => {
    var data = localStorage.getItem('esthederm-salon-order-typ');
    if (data != null) {
        return data;
    } else {
        return false;
    }
}

export const SavePage = (data) => {
    localStorage.setItem('esthederm-salon-page', data);
}


export const LoadPage = () => {
    var data = localStorage.getItem('esthederm-salon-page');
    if (data != null) {
        return data;
    } else {
        return false;
    }
}

export const SaveScroll = (data) => {
    localStorage.setItem('esthederm-salon-product-id', data);
}

export const RemoveScroll = (data) => {
    localStorage.removeItem('esthederm-salon-product-id');
}

export const LoadScroll = () => {
    var data = localStorage.getItem('esthederm-salon-product-id');
    if (data != null) {
        return data;
    } else {
        return null;
    }
}

export const SaveAlias = (alias) => {
    localStorage.setItem('alias', alias);
}


export const LoadAlias = () => {
    var alias = localStorage.getItem('alias');
    if (alias != null) {
        return alias;
    } else {
        return null;
    }
}

export const SaveLogedIn = (log) => {
    localStorage.setItem('loged_in', log);
}


export const LoadLogedIn = () => {
    var log = localStorage.getItem('loged_in');
    if (log != null) {
        if (log == 'true') {
            return true
        } else {
            return false;
        }
    } else {
        return null;
    }
}


export const SaveLanguage = (lang) => {
    localStorage.setItem('esthederm-salon-language', lang);
}


export const LoadLanguage = () => {
    var lang = localStorage.getItem('esthederm-salon-language');
    if (lang != null) {
        return lang;
    } else {
        return false;
    }
}

export const SaveProductNames = (data) => {
    localStorage.setItem('product_names', JSON.stringify(data));
}

export const LoadProductNames = () => {
    var products = localStorage.getItem('product_names');
    if (products != null) {
        return JSON.parse(products);
    } else {
        return null;
    }
}


export const SaveProducts = (data) => {
    localStorage.setItem('products', JSON.stringify(data));
}

export const LoadProducts = () => {
    var data = localStorage.getItem('products');
    if (data != null) {
        return JSON.parse(data);
    } else {
        return null;
    }
}

export const SaveSections = (data) => {
    localStorage.setItem('sections', JSON.stringify(data));
}

export const LoadSections = () => {
    var data = localStorage.getItem('sections');
    if (data != null) {
        return JSON.parse(data);
    } else {
        return null;
    }
}

export const SaveCategories = (data) => {
    localStorage.setItem('categories', JSON.stringify(data));
}

export const LoadCategories = () => {
    var data = localStorage.getItem('categories');
    if (data != null) {
        return JSON.parse(data);
    } else {
        return null;
    }
}

export const SaveSalon = (data) => {
    localStorage.setItem('salon', JSON.stringify(data));
}

export const LoadSalon = () => {
    var user = localStorage.getItem('salon');
    if (user != null) {
        return JSON.parse(user);
    } else {
        return null;
    }
}

export const SaveClient = (data) => {
    localStorage.setItem('client', JSON.stringify(data));
}

export const LoadClient = () => {
    var client = localStorage.getItem('client');
    if (client != null) {
        return JSON.parse(client);
    } else {
        return null;
    }
}

export const SaveSalonSettings = (data) => {
    localStorage.setItem('salon_settings', JSON.stringify(data));
}

export const LoadSalonSettings = () => {
    var user = localStorage.getItem('salon_settings');
    if (user == '[]' || user == '') {
        user = null;
    }
    if (user != null) {
        return JSON.parse(user);
    } else {
        return null;
    }
}


export const SavePublic = (id) => {
    localStorage.setItem('public', id);
}

export const LoadPublic = () => {
    var id = localStorage.getItem('public');
    if (id != null) {
        return id;
    } else {
        return null;
    }
}

export const Loading = () => {
    const logo = require('./react/loading.png');
    const size = 60;

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000' }}>
            <Paper elevation={5} style={{ ...styles.Block, width: size, height: size, backgroundColor: global.theme_blue, borderRadius: (size + 5) / 2 }}>
                <img src={logo} style={{ marginTop: 0, width: size - 10, height: size - 10 }}></img>
                <CircularProgress style={{ position: 'absolute', color: global.theme_white }} thickness={2} size={size - 2} />
            </Paper>
        </Backdrop>
    );
}


export const GetStatus = (lang, id) => {
    switch (parseInt(id)) {
        case 0:
            return lang.order_created;
            break;
        case 1:
            return lang.order_in_proccess;
            break;
        case 2:
            return lang.order_ready;
            break;
        case 3:
            return lang.order_sended;
            break;
        case 4:
            return lang.order_finished;
            break;
        case 5:
            return lang.order_canceled;
            break;
    }
}

export const GetStatusColor = (id) => {
    switch (parseInt(id)) {
        case 0:
            return global.theme_red;
            break;
        case 1:
            return global.theme_red;
            break;
        case 2:
            return global.theme_red;
            break;
        case 3:
            return global.theme_dark_green;
            break;
        case 4:
            return global.theme_dark_green;
            break;
        case 5:
            return global.theme_dark_gray;
            break;
    }
}

export const NavigationMenu = (props) => {
    /*
            USAGE:
            <NavigationMenu func={NavigationMenuPress.bind(this)} />

            const NavigationMenuPress = (value) => {
                Debug(value)
            }

    */
    const navigate = useNavigate();

    const [search, setSearch] = useState('');
    const [searchErr, setSearchErr] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    const logo = require('./react/logo.png');
    const logo_white = require('./react/logo_ie_white.png');

    const logoSize = 50;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const menuColor = global.theme_dark_red;

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    var color = global.themes[props.theme];

    var lang = GetLanguage(props.language);
    let { func } = props;
    const inputRef = useRef(null);

    useEffect(() => {
        return () => {
        };

    }, [props.products, props.alias]);


    const SearchOpen = () => {
        setShowSearch(true);
        // FOCUS TO TEXTAREA
        setTimeout(() => {
            inputRef.current.focus();
        }, 300);
    }


    const SearchClose = () => {
        setShowSearch(false);
    }


    const SearchProccess = () => {
        navigate("/" + props.alias + '/search/' + search, { replace: true });
    }

    const Logout = () => {
        navigate('/logout');
    }

    const OpenMenu = () => {
        setOpen(true);
    }


    const CloseMenu = () => {
        setOpen(false);
    }

    const Login = () => {
        setOpen(false);
        setShowLogin(true);
    }

    const LoginResult = (typ, token) => {
        setShowLogin(false);
        if (typ == true) {
            SaveToken(token);
            SavePage(0);
            SaveScroll(0);
            navigate('/login');
        }
    }

    const EshopMenuPress = (section_id, category_id) => {
        setOpen(false);
        func(section_id, category_id);
    }

    const MyAccount = () => {
        setOpen(false);
        navigate('/' + props.alias + '/account');
    }

    return (
        // ***************************************************************************************************************************************************************************
        // SMALL SCREENS
        // ***************************************************************************************************************************************************************************
        <div style={{ position: 'sticky', top: 0, zIndex: 31000 }}>
            {isSmall == true || isMedium == true ?
                // SMALL SCREEN
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', backgroundColor: global.theme_black, paddingLeft: 10, paddingRight: 10 }}>
                    <IconButton onClick={() => OpenMenu()} style={{ height: 35 }}>
                        <FontAwesomeIcon style={{ marginRight: 15, color: global.theme_white }} icon={faBars} />
                    </IconButton>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
                        <IconButton onClick={() => SearchOpen()} style={{ height: 35 }}>
                            <FontAwesomeIcon style={{ marginRight: 15, color: global.theme_white }} icon={faSearch} />
                        </IconButton>
                        <Button onClick={() => navigate("/" + props.alias + '/card')} style={{ color: global.theme_white }}>
                            <FontAwesomeIcon style={{ marginRight: 15 }} icon={faShoppingCart} />{lang.basket}
                        </Button>
                    </div>
                    <Drawer
                        anchor={'left'}
                        open={isOpen}
                        onClose={CloseMenu}
                        style={{ width: 300, zIndex: 32000 }}
                    >
                        <div style={{ ...styles.BlockCenter, width: width > 370 ? 370 : width, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <Paper elevation={5} style={{ ...styles.BlockLeft, paddingTop: 10, paddingBottom: 30, backgroundColor: color.menu }}>
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.Block, width: logoSize + 20 }}>
                                        <img src={logo} style={{ width: '100%', height: '100%', maxWidth: logoSize, maxHeight: logoSize, objectFit: 'contain' }}></img>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '75%' }}>
                                        <p style={{ ...styles.TextNormal }}><b>Esthederm</b> Salon</p>
                                        <div style={{ ...styles.BlockLeft, borderTop: '1px solid ' + color.menu_line, marginTop: 2 }}>
                                            <p style={{ ...styles.TextXTiny, textAlign: 'left', marginTop: 2 }}>{props.client.name} {props.client.surname}</p>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.Block, width: 50 }}>
                                        <IconButton onClick={() => CloseMenu()} style={{ width: 34, height: 34, backgroundColor: global.theme_black }}>
                                            <p style={{ ...styles.TextTiny, color: global.theme_white, fontWeight: '600' }}>X</p>
                                        </IconButton>
                                    </div>
                                </div>
                                {props.client != false ?
                                    <div style={{ ...styles.BlockRow, marginTop: 30 }}>
                                        <div style={{ ...styles.Block }}>
                                            <Button onClick={() => MyAccount()} style={{ ...styles.ButtonDarkSmall, backgroundColor: global.theme_white, border: '1px solid ' + global.theme_dark, width: 130 }}>
                                                <p style={{ ...styles.TextTiny, color: global.theme_black, fontWeight: '600', letterSpacing: 1 }}>{lang.my_account}</p>
                                            </Button>
                                        </div>
                                        <div style={{ ...styles.Block }}>
                                            <Button onClick={() => Logout()} style={{ ...styles.ButtonDarkSmall, backgroundColor: global.theme_white, border: '1px solid ' + global.theme_dark, width: 130 }}>
                                                <p style={{ ...styles.TextTiny, color: global.theme_black }}>{lang.logout}</p>
                                            </Button>
                                        </div>
                                    </div>
                                    :
                                    <div style={{ ...styles.Block, marginTop: 30 }}>
                                        <Button onClick={() => Login()} style={{ ...styles.ButtonDarkSmall, backgroundColor: global.theme_blue, width: 150 }}>
                                            <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.login_button}</p>
                                        </Button>
                                    </div>
                                }
                            </Paper>

                            <div style={{ ...styles.BlockLeft, width: '95%', marginTop: 10 }}>
                                {/* DOMOV */}
                                <Button onClick={() => navigate("/" + props.alias + '/home')} style={{ ...styles.ButtonMenu, marginTop: 5, marginBottom: 5, backgroundColor: props.index == 0 ? color.menu_selected : '#00000000', borderRadius: 20 }}>
                                    <p style={{ ...styles.TextNormal, marginLeft: 15, color: props.index == 0 ? global.theme_white : global.theme_black }}>{lang.home}</p>
                                </Button>

                                {/* SLUZBY */}
                                <Button onClick={() => navigate("/" + props.alias + '/services')} style={{ ...styles.ButtonMenu, marginTop: 5, marginBottom: 5, backgroundColor: props.index == 6 ? color.menu_selected : '#00000000', borderRadius: 20 }}>
                                    <p style={{ ...styles.TextNormal, marginLeft: 15, color: props.index == 6 ? global.theme_white : global.theme_black }}>{lang.services}</p>
                                </Button>

                                {/* KONTAKT */}
                                <Button onClick={() => navigate("/" + props.alias + '/contact')} style={{ ...styles.ButtonMenu, marginTop: 5, marginBottom: 5, backgroundColor: props.index == 2 ? color.menu_selected : '#00000000', borderRadius: 20 }}>
                                    <p style={{ ...styles.TextNormal, marginLeft: 15, color: props.index == 2 ? global.theme_white : global.theme_black }}>{lang.contact}</p>
                                </Button>

                                {/* ESHOP */}
                                {props.index != 10 && props.salon.eshop == true ?
                                    <Button onClick={() => navigate("/" + props.alias + '/eshop')} style={{ ...styles.ButtonMenu, marginTop: 5, marginBottom: 5, backgroundColor: props.index == 1 ? color.menu_selected : '#00000000', borderRadius: 20 }}>
                                        <p style={{ ...styles.TextNormal, marginLeft: 15, color: props.index == 1 ? global.theme_white : global.theme_black }}>{lang.eshop}</p>
                                    </Button>
                                    : null}
                            </div>

                            {props.index == 10 ?
                                <div style={{ ...styles.BlockLeft, width: '95%', marginTop: 10, paddingTop: 10, paddingBottom: 10, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <div style={{ ...styles.BlockLeft, paddingTop: 10, paddingBottom: 10 }}>
                                        <p style={{ ...styles.TextTiny, marginLeft: 10, fontWeight: '600' }}>{lang.eshop}</p>
                                    </div>
                                    <EshopMenu theme={props.theme} isSmall={false} sectionID={props.sectionID} categoryID={props.categoryID} language={props.language} sections={props.sections} func={EshopMenuPress.bind(this)} />
                                </div>
                                : null}

                            <div style={{ ...styles.BlockLeft, width: '95%', paddingBottom: 10, marginTop: 10, paddingTop: 10, borderTop: '1px solid ' + global.theme_light_gray }}>
                                {/* VYBRAT SALON */}
                                <Button onClick={() => navigate('/logout')} style={{ ...styles.ButtonMenu, marginTop: 5, marginBottom: 5, backgroundColor: '#00000000', borderRadius: 20 }}>
                                    <p style={{ ...styles.TextNormal, marginLeft: 15, color: global.theme_black }}>{lang.salon_choose}</p>
                                </Button>
                            </div>

                        </div>
                    </Drawer>
                </div>
                :
                // LARGE SCREENS
                <div style={{}}>
                    <div style={{ display: 'flex', flexDirection: 'row', padding: 0, height: 70, justifyContent: 'center', alignItems: 'center', background: global.theme_black }}>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 30, width: '60%' }}>
                            <div style={{ marginRight: 50, paddingTop: 0 }}>
                                <a onClick={() => navigate("/" + props.alias + '/home')}>
                                    <img src={logo} height={50} />
                                </a>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 10 }}>

                                {/* DOMOV */}
                                <Button onClick={() => navigate("/" + props.alias + '/home')} style={{ display: 'flex', padding: 5, paddingLeft: 20, paddingRight: 20, backgroundColor: props.index == 0 ? menuColor : '#00000000', marginRight: 10, height: 30, alignItems: 'center', borderRadius: 25, color: global.theme_white, textTransform: 'none', fontSize: 16 }}>
                                    {lang.home}
                                </Button>

                                {/* SLUZBY */}
                                <Button onClick={() => navigate("/" + props.alias + '/services')} style={{ display: 'flex', padding: 5, paddingLeft: 20, paddingRight: 20, backgroundColor: props.index == 6 ? menuColor : '#00000000', marginRight: 10, height: 30, alignItems: 'center', borderRadius: 25, color: global.theme_white, textTransform: 'none', fontSize: 16 }}>
                                    {lang.services}
                                </Button>

                                {/* KONTAKT */}
                                <Button onClick={() => navigate("/" + props.alias + '/contact')} style={{ display: 'flex', padding: 5, paddingLeft: 20, paddingRight: 20, backgroundColor: props.index == 2 ? menuColor : '#00000000', marginRight: 10, height: 30, alignItems: 'center', borderRadius: 25, color: global.theme_white, textTransform: 'none', fontSize: 16 }}>
                                    {lang.contact}
                                </Button>

                                {/* ESHOP */}
                                {props.salon.eshop == true &&
                                    <Button onClick={() => navigate("/" + props.alias + '/eshop')} style={{ display: 'flex', padding: 5, paddingLeft: 20, paddingRight: 20, backgroundColor: props.index == 1 ? menuColor : '#00000000', marginRight: 10, height: 30, alignItems: 'center', borderRadius: 25, color: global.theme_white, textTransform: 'none', fontSize: 16, border: '1px solid ' + global.theme_white }}>
                                        {lang.eshop}
                                    </Button>}
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingRight: 30, justifyContent: 'flex-end', alignItems: 'center', width: '40%' }}>
                            <IconButton onClick={() => SearchOpen()} style={{ height: 35 }}>
                                <FontAwesomeIcon style={{ marginRight: 15, color: global.theme_white }} icon={faSearch} />
                            </IconButton>
                            <div style={{ marginRight: 40 }}>
                                {props.salon != false ?
                                    <p style={{ color: global.theme_white }}>{lang.call}: <a style={{ textDecoration: 'none' }} href={'tel://' + props.salon.mobil}>{props.salon.mobil}</a></p>
                                    : null}
                            </div>
                            {props.salon.eshop == true &&
                                <Button onClick={() => navigate("/" + props.alias + '/card')} style={{ color: global.theme_white }}>
                                    {props.quantity > 0 ?
                                        <Badge badgeContent={props.quantity == undefined ? 0 : props.quantity} color="secondary" anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}>

                                            <FontAwesomeIcon style={{ marginRight: 10 }} icon={faShoppingCart} />
                                        </Badge>
                                        :
                                        <FontAwesomeIcon style={{ marginRight: 10 }} icon={faShoppingCart} />
                                    }
                                    <p style={{ margin: 0, marginLeft: 15 }}>
                                        {lang.basket}
                                    </p>
                                </Button>}
                        </div>
                    </div >
                    {props.settings != null ?
                        props.settings.trade_free_enabled == true ?
                            <div style={{ display: 'flex', width: '100%', paddingBottom: 0, paddingTop: 0, backgroundColor: global.theme_light, justifyContent: 'center', alignItems: 'center' }}>
                                <p style={{ margin: 6, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto', color: global.theme_black }}>{lang.trade_free_ads} {GetPrice(props.settings.trade_free_price)} {lang.money}</p>
                            </div>
                            : null
                        : null}
                </div>
            }


            {/* =============================================================================
                HLADAŤ
                ============================================================================= */}
            <Backdrop open={showSearch} >
                <Paper elevation={3} style={{ width: isSmall == true ? width - 20 : 450, height: 280, background: global.theme_white, borderRadius: 20 }}>
                    <div style={{ ...styles.BlockRow, borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ ...styles.Block, width: 40, height: 60 }}>
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_black }} icon={faSearch} />
                            </div>
                            <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: 60 }}>
                                <p style={{ ...isSmall ? styles.TextSmall : styles.TextNormal, fontWeight: '500' }}>{lang.search_label}</p>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: 60, height: 60 }}>
                            <IconButton onClick={() => SearchClose()} style={{ width: 36, height: 36, backgroundColor: global.theme_black }}>
                                <p style={{ ...styles.TextSmall, color: global.theme_white }}>X</p>
                            </IconButton>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: 200 }}>
                        <div style={{ display: 'flex', width: '95%', padding: 6, justifyContent: 'center', flexDirection: 'column' }}>
                            <p style={{ ...styles.TextTiny, marginLeft: 10 }}>{lang.search}</p>
                            <TextField
                                inputRef={inputRef}
                                size='small'
                                variant="outlined"
                                value={search}
                                onInput={e => setSearch(e.target.value)}
                                InputProps={{
                                    style: { backgroundColor: global.theme_white, borderRadius: 20, color: global.theme_black },
                                }}
                            />
                        </div>

                        <Button onClick={() => SearchProccess()} style={{ ...styles.ButtonDark, backgroundColor: global.theme_black, marginTop: 40 }} variant="contained">{lang.search}</Button>

                    </div>
                </Paper>
            </Backdrop>

            {showLogin == true ?
                <DialogLogin language={props.language} func={LoginResult.bind(this)} />
                : null}

        </div>
    );
}


export const BottomMenu = (props) => {
    /*
            USAGE:
            <BottomMenu func={BottomMenuPress.bind(this)} />

            const BottomMenuPress = (value) => {
                Debug(value)
            }

    */
    const [showDialog, setShowDialog] = useState(false);
    const [typ, setTyp] = useState(0);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });


    var lang = GetLanguage(props.language);

    useEffect(() => {

        return () => {
        };

    }, []);

    const ShowText = (typ_) => {
        setTyp(typ_);
        setShowDialog(true);
    }

    return (
        // ***************************************************************************************************************************************************************************
        // SMALL SCREENS
        // ***************************************************************************************************************************************************************************
        <div style={{ ...styles.Block, backgroundColor: global.theme_black }}>
            <div style={{ ...isSmall ? styles.Block : styles.BlockRowRaw }}>
                <div style={{ fontSize: 13, margin: isLarge ? 10 : 5 }}>
                    <Button onClick={() => ShowText(1)} style={{ ...styles.ButtonLink, color: global.theme_light_gray, width: 300 }}>{lang.gdpr}</Button>
                </div>
                <div style={{ fontSize: 13, margin: isLarge ? 10 : 5 }}>
                    <Button onClick={() => ShowText(0)} style={{ ...styles.ButtonLink, color: global.theme_light_gray, width: 300 }}>{lang.sell_docs}</Button>
                </div>
                <div style={{ fontSize: 13, margin: isLarge ? 10 : 5 }}>
                    <Button onClick={() => ShowText(2)} style={{ ...styles.ButtonLink, color: global.theme_light_gray, width: 300 }}>{lang.cookies_rules}</Button>
                </div>
            </div >

            {showDialog == true ?
                <DialogRules typ={typ} salon={props.salon} func={() => setShowDialog(false)} />
                : null}
        </div>
    )
}



export const Footer = (props) => {
    /*
            <Footer language={language} />
    */

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    var lang = GetLanguage(props.language);

    const logo_sk = require('./react/logo_black_sk_.png');
    const logo_cz = require('./react/logo_black_cz_.png');

    const apple = require('./react/apple.png');
    const android = require('./react/google_play.png');

    var now = new Date();
    var year = now.getFullYear();

    return (
        <div style={{ padding: 20, paddingBottom: 80, backgroundColor: global.theme_black }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div>
                    <img src={props.language == 0 ? logo_sk : logo_cz} width={133} height={80} />
                </div>
                <div style={{ color: global.theme_medium_gray, fontSize: 14 }}>
                    <p>{lang.copyright} {year} - {lang.www}</p>
                </div>
                {props.islogged == true ?
                    <div style={{ ...styles.Block, marginTop: 20, paddingTop: 20, borderTop: '1px solid #202020' }}>
                        <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.app_download}</p>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockRowRaw, marginTop: 10 }}>
                            <a target={'_blank'} href={global.appstore} style={{ ...styles.Block, textDecoration: 'none', height: 50, width: 200, backgroundColor: '#FFFFFF17', borderRadius: 10 }}>
                                <div style={{ ...styles.Block, height: 50, width: 200 }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <img src={apple} width={32} height={32} />
                                        <div style={{ height: 32, display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 15 }}>
                                            <p style={{ ...styles.TextTiny, color: global.theme_white }}>{'App Store'}</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a target={'_blank'} href={global.googleplay} style={{ ...styles.Block, textDecoration: 'none', height: 50, width: 200, marginTop: isSmall ? 20 : 0, backgroundColor: '#FFFFFF17', borderRadius: 10, marginLeft: isSmall ? 0 : 20 }}>
                                <div style={{ ...styles.Block, height: 50, width: 200 }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <img src={android} width={32} height={32} />
                                        <div style={{ height: 32, display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 15 }}>
                                            <p style={{ ...styles.TextTiny, color: global.theme_white }}>{'Google Play'}</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    : null}
            </div>
        </div>
    );
}


export const GetSize = () => {
    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });


    if (isSmall == true) {
        return global.size_small;
    } else if (isMedium == true) {
        return global.size_medium;
    } else {
        return global.size_large;
    }
}


export const Debug = (label, data) => {
    if (global.debugging == true) {
        var dt = data == undefined ? '' : data;
        if (dt == '') {
            console.log(label);
        } else {
            console.log(label, dt);
        }
    }
}


export const BannerBlack = (props) => {
    /*
        <BannerBlack language={language} />
    */
    const logo_sk = require('./react/logo_black_sk_.png'); // 310 x 183 px
    const logo_cz = require('./react/logo_black_cz_.png'); // 310 x 183 px
    const logo_ie = require('./react/logo_ie_black.png'); // 460 x 220 px

    var lang = GetLanguage(props.language);

    return (
        <div style={{ backgroundColor: global.theme_black, padding: 20, display: 'flex', flexDirection: 'row' }}>
            {GetSize() != 0 ?
                <div style={{ display: 'flex', width: '25%', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <img src={props.language == 0 ? logo_sk : logo_cz} height={100} />
                </div>
                : null}
            {GetSize() == 2 ?
                <div style={{ display: 'flex', width: '50%', justifyContent: 'center', alignItems: 'center' }}>
                    <h3 style={{ color: global.theme_white }}>{lang.ie_salons.toUpperCase()}</h3>
                </div>
                : null}
            {GetSize() < 2 ?
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <h4 style={{ color: global.theme_white, margin: 0 }}>{lang.salons.toUpperCase()}</h4>
                    <p style={{ color: global.theme_white, margin: 0 }}>{lang.ie.toUpperCase()}</p>
                </div>
                : null}
            {GetSize() != 0 ?
                <div style={{ display: 'flex', width: '25%', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <img src={logo_ie} width={460 / 3} height={220 / 3} />
                </div>
                : null}

        </div>
    )
}

export const BannerImage = (props) => {
    /*
        <BannerImage typ={0} language={language} />
    */

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    var lang = GetLanguage(props.language);

    useEffect(() => {
        return () => {
        };

    }, [props.file]);


    return (
        <div style={{ backgroundColor: global.theme_white, padding: 0, display: 'flex', flexDirection: 'row' }}>
            {props.typ == 0 ?
                isSmall == true ?
                    <img src={GetImageLink(props.file.link_mobil)} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                    :
                    <img src={GetImageLink(props.file.link)} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                : null}
            {props.typ == 4 ?
                // BANNER V KOSIKU - LETNA AKCIA
                isSmall == true ?
                    <img src={global.images_url + '/' + props.file.link_mobil} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                    :
                    <img src={global.images_url + '/' + props.file.link} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                : null}

        </div>
    )
}


export const BannerSlider = (props) => {
    /*
        <BannerSlider language={language} />
    */

    /*
        const banner_0_sk = require('./react/banner/eshop_leto_2022_sk.jpg'); // 2560 x 358 px SK
        const banner_0_cz = require('./react/banner/eshop_leto_2022_cz.jpg'); // 2560 x 358 px CZ
        const banner_0_small_sk = require('./react/banner/eshop_leto_2022_sk_small.jpg'); // 800 x 358 px SK
        const banner_0_small_cz = require('./react/banner/eshop_leto_2022_cz_small.jpg'); // 800 x 358 px CZ
    */

    const banner_0_sk = require('./react/banner/banner_sk_22_10.jpg'); // 2560 x 358 px SK
    const banner_0_cz = require('./react/banner/banner_cz_22_10.jpg'); // 2560 x 358 px CZ
    const banner_0_small_sk = require('./react/banner/banner_sm_sk_22_10.jpg'); // 800 x 358 px SK
    const banner_0_small_cz = require('./react/banner/banner_sm_cz_22_10.jpg'); // 800 x 358 px CZ

    const banner_1_sk = require('./react/banner/banner_sk_22_09.jpg'); // 2560 x 358 px SK
    const banner_1_cz = require('./react/banner/banner_cz_22_09.jpg'); // 2560 x 358 px CZ
    const banner_1_small_sk = require('./react/banner/banner_sm_sk_22_09.jpg'); // 800 x 358 px SK
    const banner_1_small_cz = require('./react/banner/banner_sm_cz_22_09.jpg'); // 800 x 358 px CZ

    const banner_2_sk = require('./react/banner/intro_banner_sk.png'); // 2560 x 358 px
    const banner_2_cz = require('./react/banner/intro_banner_cz.png'); // 2560 x 358 px
    const banner_2_small_sk = require('./react/banner/intro_banner_small_sk.png'); // 800 x 358 px
    const banner_2_small_cz = require('./react/banner/intro_banner_small_cz.png'); // 800 x 358 px

    const banner_3_sk = require('./react/banner/eshop_banner_2_sk.jpg'); // 2560 x 358 px
    const banner_3_cz = require('./react/banner/eshop_banner_2_cz.jpg'); // 2560 x 358 px
    const banner_3_small_sk = require('./react/banner/eshop_banner_small_2_sk.jpg'); // 800 x 358 px
    const banner_3_small_cz = require('./react/banner/eshop_banner_small_2_cz.jpg'); // 800 x 358 px

    const banner_4_sk = require('./react/banner/eshop_banner_3_sk.jpg'); // 2560 x 358 px
    const banner_4_cz = require('./react/banner/eshop_banner_3_cz.jpg'); // 2560 x 358 px
    const banner_4_small_sk = require('./react/banner/eshop_banner_3_small_sk.jpg'); // 800 x 358 px
    const banner_4_small_cz = require('./react/banner/eshop_banner_3_small_cz.jpg'); // 800 x 358 px

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    var lang = GetLanguage(props.language);

    const sliderSettings = {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 750,
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingBottom: 40 }}>
            <Slider  {...sliderSettings}>
                {props.banners.map(item => (
                    <div key={item.id}>
                        <div style={{ width: '100%', height: 'auto' }}>
                            {isSmall == true ?
                                <img src={global.images_url + '/' + item.link_mobil} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                                :
                                <img src={global.images_url + '/' + item.link} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                            }
                        </div>
                    </div>
                ))}
                {/*
                <div>
                    <div style={{ width: '100%', height: 'auto' }}>
                        {isSmall == true ?
                            <img src={props.language == 0 ? banner_1_small_sk : banner_1_small_cz} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                            :
                            <img src={props.language == 0 ? banner_1_sk : banner_1_cz} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                        }
                    </div>
                </div>
                <div>
                    <div style={{ width: '100%', height: 'auto' }}>
                        {isSmall == true ?
                            <img src={props.language == 0 ? banner_2_small_sk : banner_2_small_cz} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                            :
                            <img src={props.language == 0 ? banner_2_sk : banner_2_cz} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                        }
                    </div>
                </div>
                <div>
                    <div style={{ width: '100%', height: 'auto' }}>
                        {isSmall == true ?
                            <img src={props.language == 0 ? banner_3_small_sk : banner_3_small_cz} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                            :
                            <img src={props.language == 0 ? banner_3_sk : banner_3_cz} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                        }
                    </div>
                </div>
                <div>
                    <div style={{ width: '100%', height: 'auto' }}>
                        {isSmall == true ?
                            <img src={props.language == 0 ? banner_4_small_sk : banner_4_small_cz} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                            :
                            <img src={props.language == 0 ? banner_4_sk : banner_4_cz} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                        }
                    </div>
                </div>
                */}
            </Slider>
        </div>
    )
}


export const BannerSliderMyPage = (props) => {
    /*
        <BannerSliderMyPage settings={settings} language={language} />
    */

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    var lang = GetLanguage(props.language);

    const sliderSettings = {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        speed: 750,
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingBottom: 40 }}>
            <Slider  {...sliderSettings}>
                {props.banners.map((item, index) => (
                    <div key={item.id}>
                        <div style={{ width: '100%', height: 'auto' }}>
                            <img src={GetImageLink(item.banner)} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    )
}



export const SalonPhoto = (props) => {
    /*
        <Photo source={}/>
    */
    const salon_photo = require('./react/salon_photo_2.jpg'); // 1700 x 600 px

    return (
        <div style={{ backgroundColor: global.theme_white, padding: 0, display: 'flex', flexDirection: 'row' }}>
            <img src={props.source != '' ? GetImageLink(props.source) : salon_photo} width={props.size} />
        </div>
    )
}


export const BannerTop = (props) => {
    /*
        <BannerTop typ={0} language={language} func={BannerTopPress.bind(this)} />

            const BannerTopPress = (value) => {
                Debug(value)
            }

            PRESS: 0 - nič
            PRESS: 1 - prihlásiť sa
            PRESS: 2 - odhlásiť sa            
            PRESS: 3 - môj účet
            PRESS: 4 - iba prihlasenie

    */

    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);
    const [name, setName] = useState('');
    const [showLogout, setShowLogout] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [username, setUsername] = useState('zakaznik');
    const [password, setPassword] = useState('12345678');
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showLoginQuestion, setLoginQuestion] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    var lang = GetLanguage(props.language);
    let { func } = props;

    useEffect(() => {

        if (props.client != false) {
            setName(props.client.name + ' ' + props.client.surname);
        }

        if (props.typ == 4) {
            // zobraziť len prihlasenie
            setUsername('');
            setPassword('');
            setLoginQuestion(true);
        }

    }, [props.user, props.client, props.salon]);

    const Press = (value, data) => {
        setBusy(false);
        func(value, data);
    }

    const Logout = () => {
        navigate('/logout');
        setShowLogout(true);
    }

    const LogoutProccess = () => {
        navigate('/logout');
    }

    const Account = () => {
        Press(3);
    }

    const QuestionClose = () => {
        if (props.typ == 4) {
            Press(4, false);
        } else {
            setLoginQuestion(false);
        }
    }

    const LoginResult = (typ, token) => {
        setShowLogin(false);
        if (typ == true) {
            SaveToken(token);
            Press(1, token);
        }
    }

    return (
        <div>
            {
                props.typ != 4 ?
                    isSmall == true ?
                        <div style={{ backgroundColor: global.theme_white, padding: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', height: 35 }}>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                {props.logged == true ?
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                        <Button onClick={() => Account()} style={{ ...styles.ButtonLink, width: 190, borderRadius: 10 }} variant="text">{name.substring(0, name.length < 19 ? name.length : 18)}{name.length < 19 ? '' : '...'}</Button>
                                        <Button onClick={() => Logout()} style={{ color: global.theme_black, fontSize: 12, marginRight: 0 }} variant="text">{lang.logout}</Button>
                                    </div>
                                    :
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Button onClick={() => setShowLogin(true)} style={{ color: global.theme_black }} variant="text">{lang.login}<FontAwesomeIcon style={{ marginLeft: 5 }} icon={faSignInAlt} /></Button>
                                    </div>
                                }
                            </div>
                        </div>
                        :
                        <div style={{ backgroundColor: global.theme_white, padding: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', height: 35 }}>
                            <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <h4 style={{ marginLeft: 20 }}><b>Esthederm</b> Salon</h4>
                            </div>
                            <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                {props.salon != false ? props.logged == true ?
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Button onClick={() => Account()} style={{ ...styles.ButtonLink, width: undefined, marginRight: 20 }} variant="text">
                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginRight: 10 }} icon={faUser} />
                                            <p style={{ ...styles.TextTiny }}>{name}</p>
                                        </Button>
                                        {/*
                                        <Button onClick={() => Logout()} style={{ marginLeft: 30, top: 2, color: global.theme_black, marginRight: 20 }} variant="text">{lang.logout}</Button>
                                        */}
                                    </div>
                                    :
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {props.chooseSalon == true ?
                                            <Button onClick={() => LogoutProccess()} style={{ borderRadius: 0, marginRight: 30, color: global.theme_white, fontWeight: 'bold', fontSize: 14, backgroundColor: global.theme_dark_red }} variant="text">{lang.eshops}</Button>
                                            : null}
                                        <Button onClick={() => setShowLogin(true)} style={{ color: global.theme_black }} variant="text">{lang.login}<FontAwesomeIcon style={{ marginLeft: 5 }} icon={faSignInAlt} /></Button>
                                    </div>
                                    : null}
                            </div>
                        </div>

                    : null
            }
            <div>

                {/* =============================================================================
                    ZOSTAT NEPRIHLASENY / PRIHLASIT SA - OTAZKA
                ============================================================================= */}
                <Dialog open={showLoginQuestion} maxWidth={'md'}>
                    <div style={{ backgroundColor: global.theme_gray, width: 300, padding: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: 50 }}></div>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                <h3 style={{ margin: 0 }}>{lang.logins}</h3>
                            </div>
                            <div style={{ width: 50 }}>
                                <IconButton onClick={() => QuestionClose(false)} style={{ height: 35 }}>
                                    <p style={{ margin: 0, padding: 0 }}>X</p>
                                </IconButton>
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', padding: 20 }}>
                            <p style={{ fontSize: 16, fontFamily: 'roboto', color: global.theme_black, fontWeight: 'bold', textAlign: 'center' }}>{lang.login_text}!</p>
                        </div>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', padding: 10, flexDirection: 'column' }}>
                            <Button onClick={() => setShowLogin(true)} style={{ backgroundColor: global.theme_green, width: '100%' }} variant="contained">{lang.login}</Button>
                            <Button onClick={() => QuestionClose(false)} style={{ marginTop: 20, backgroundColor: global.theme_black, width: '100%' }} variant="contained">{lang.buy_without_login}</Button>
                        </div>
                    </div>
                </Dialog>

                {showLogin == true ?
                    <DialogLogin language={props.language} func={LoginResult.bind(this)} />
                    : null}

            </div >
        </div>
    )
}


export const MyLine = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
            <div style={{ height: 1.5, backgroundColor: global.theme_black, width: 50 }}></div>
        </div>
    )
}


export const PaginationBar = (props) => {
    /*
            USAGE:
            <PaginationBar color={theme_light} page={page} max={ } func={PaginationReturn.bind(this)} />
 
            const Redraw = (page) => {
                var data = services;
            data = data.slice(global.items_max * page, (global.items_max * page) + global.items_max)
            setServicesFiltered(data);
            }
 
            const PaginationReturn = (value) => {
                Redraw(value);
            }

            */

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });


    const [page, setPage] = useState(0);

    let { func } = props;

    useEffect(() => {
        setPage(props.page);
        Debug('PAGINATION REDRAW ........');
        return () => {
        };
    }, []);

    const Press = (page_) => {
        func(page_);
    }

    const firstPage = () => {
        setPage(0);
        Press(0);
    }

    const prevPage = () => {
        var p = page;
        if (page > 0) {
            p -= 1;
        }
        setPage(p);
        Press(p);
    }

    const nextPage = () => {
        var p = page;
        if (page * global.items_max < (props.max - global.items_max)) {
            p += 1;
        }
        setPage(p);
        Press(p);
    }

    const lastPage = () => {
        var p = Math.floor(props.max / global.items_max);
        setPage(p);
        Press(p);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', paddingTop: 10, paddingBottom: 10 }}>
            <div style={{ display: 'flex', flexDirection: 'row', width: '50%', paddingLeft: isLarge == true ? 20 : 0, paddingRight: isLarge == true ? 20 : 0 }}>
                <div style={{ display: 'flex', width: isLarge == true ? 100 : 70 }}>
                    <Button
                        onClick={() => firstPage()}
                        style={{ opacity: page == 0 ? 0 : 1, backgroundColor: '#00000000', height: 40, width: 40, borderRadius: 20, color: global.theme_dark, border: '2px solid ' + global.theme_dark }}
                        disabled={page == 0 ? true : false}
                    >
                        <FontAwesomeIcon style={{ marginRight: 0 }} icon={faAngleDoubleLeft} />
                    </Button>
                </div>
                <div style={{ display: 'flex', width: isLarge == true ? 100 : 70 }}>
                    <Button
                        onClick={() => prevPage()}
                        style={{ opacity: page == 0 ? 0 : 1, backgroundColor: '#00000000', margin: 0, padding: 0, height: 40, width: 40, borderRadius: 20, color: global.theme_dark, border: '2px solid ' + global.theme_dark }}
                        disabled={page == 0 ? true : false}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                </div>
            </div>
            {/*
            <div style={{ display: 'flex', width: '100%' }}></div>
            */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end', width: '50%', paddingLeft: isLarge == true ? 20 : 0, paddingRight: isLarge == true ? 20 : 0 }}>
                <div style={{ display: 'flex', width: isLarge == true ? 100 : 70 }}>
                    <Button
                        onClick={() => nextPage()}
                        style={{ opacity: page * global.items_max < (props.max - global.items_max) ? 1 : 0, backgroundColor: '#00000000', height: 40, width: 40, borderRadius: 20, color: global.theme_dark, border: '2px solid ' + global.theme_dark }}
                        disabled={page * global.items_max < (props.max - global.items_max) ? false : true}
                    >
                        <FontAwesomeIcon style={{ marginRight: 0 }} icon={faChevronRight} />
                    </Button>
                </div>
                <div style={{ display: 'flex', width: isLarge == true ? 100 : 70 }}>
                    <Button
                        onClick={() => lastPage()}
                        style={{ opacity: page * global.items_max < (props.max - global.items_max) ? 1 : 0, backgroundColor: '#00000000', height: 40, width: 40, borderRadius: 20, color: global.theme_dark, border: '2px solid ' + global.theme_dark }}
                        disabled={page * global.items_max < (props.max - global.items_max) ? false : true}
                    >
                        <FontAwesomeIcon style={{ marginRight: 0 }} icon={faAngleDoubleRight} />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export function ValidateEmail(mail) {
    // kontrola správnosti e-mailovej adresy
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2, 5})+$/.test(mail)) {
        return (true)
    }
    return (false)
};

export const ProductCard = (props) => {
    /*
            USAGE:
            <ProductCard language={language} item={item} func={ProductCardPress.bind(this)} />
 
            const ProductCardPress = (value) => {
                Debug(value)
            }

            */
    const [isOver, setOver] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const dialogHeight = isSmall ? props.buy == true ? 390 : 360 : props.buy == true ? 420 : 390;
    var lang = GetLanguage(props.language);


    let { func } = props;

    const Press = (item, value) => {
        func(item, value);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', margin: isSmall ? 2 : 5, marginBottom: 35, width: props.size, height: dialogHeight, paddingBottom: 10, border: '1px solid ' + global.theme_gray }}>
            <a onClick={() => Press(props.item, 0)} onMouseLeave={() => setOver(false)} onMouseOver={() => setOver(true)} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: global.theme_white, paddingTop: 20 }} >

                <div style={{ display: 'flex', justifyContent: 'center', width: props.size - 20, height: props.size - 20 }}>
                    <img src={GetImageLink(props.item.image)} style={{ width: props.size - 20, height: props.size - 20 }} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', paddingLeft: 5, paddingRight: 5, height: 100, overflow: 'hidden' }}>
                    <p style={{ margin: 0, fontSize: 13, fontWeight: 'normal', color: global.theme_dark_gray, fontFamily: 'roboto' }}>{global.testing == true ? props.item.id + ' - ' : ''}{lang.ie}</p>
                    <p style={{ margin: 0, fontSize: 15, textDecorationLine: isOver == false ? 'none' : 'underline', fontWeight: 'bold', color: global.theme_black, fontFamily: 'roboto' }}>{props.item.label}</p>
                    <p style={{ margin: 0, fontSize: 13, color: global.theme_dark_gray, fontFamily: 'roboto' }}>{props.item.note_short}</p>
                </div>
            </a>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'flex-end', alignItems: 'center', marginTop: 0, paddingLeft: 5, paddingRight: 5 }}>
                {
                    props.ready == true ?
                        props.item.eur != null ?
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: props.buy == true ? 'flex-end' : 'flex-start' }}>
                                <p style={{ margin: 0, fontSize: 16, fontWeight: 'bold', color: global.theme_black, fontFamily: 'roboto' }}>{props.item.eur.toString().replace('.', ',')} {lang.money}</p>
                                {props.buy == true ?
                                    <Button onClick={() => Press(props.item, 1)} variant="contained" style={{ textTransform: 'none', color: global.theme_white, backgroundColor: global.theme_black, fontSize: 14, width: '100%', height: 30 }}>{lang.buy}</Button>
                                    : null}
                            </div>
                            : null
                        : null
                }
            </div>
        </div>
    )
}


export const Ordering = (props) => {
    /*
            USAGE:
            <Ordering language={language} func={OrderingPress.bind(this)} />
 
        const OrderingPress = (value) => {
                Debug(value)
            }
            */

    const [typ, setTyp] = useState(0);
    var lang = GetLanguage(props.language);

    let { func } = props;

    useEffect(() => {
        setTyp(props.typ);

        return () => {
        };
    }, []);

    const Press = (value) => {
        func(value);
    }

    const Change = (event) => {
        setTyp(event.target.value);
        Press(event.target.value);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', height: 40, justifyContent: 'center', alignItems: 'center' }}>
            <InputLabel style={{ marginRight: 10 }} id="order-select">{lang.ordering}:</InputLabel>
            <Select
                id="order-select"
                style={{ textAlign: 'left', color: global.theme_black, width: 200, borderRadius: 20 }}
                value={props.typ}
                size='small'
                onChange={Change}
            >
                <MenuItem value={0}>{lang.ordering_name.toLowerCase()}</MenuItem>
                <MenuItem value={1}>{lang.ordering_price_low.toLowerCase()}</MenuItem>
                <MenuItem value={2}>{lang.ordering_price_high.toLowerCase()}</MenuItem>
            </Select>
        </div>
    )
}



export const EshopMenu = (props) => {
    /*
            USAGE:
            <EshopMenu func={EshopMenuPress.bind(this)} />
 
            const EshopMenuPress = (value) => {
                Debug(value)
            }

            */
    const logo = require('./react/logo_midle_gray_circle.png');

    // IS SMALL
    const [selected, setSelected] = useState(0);
    const [smallCategories, setSmallCategories] = useState([]);

    const [theme, setTheme] = useState(props.theme == undefined ? 0 : props.theme);

    const isSmall = props.isSmall;

    var lang = GetLanguage(props.language);
    let { func } = props;
    let color = global.themes[props.theme];

    useEffect(() => {
        var small_categories = [];

        if (props.sections != false) {
            var n = 0;
            props.sections.forEach(section => {
                let data = {
                    id: n,
                    label: section.label,
                    section_id: section.id,
                    category_id: 0,
                }
                small_categories.push(data);
                n++;

                if (section.categories != false) {
                    if (section.categories.length > 1) {
                        section.categories.forEach(category => {
                            let data = {
                                id: n,
                                label: category.label,
                                section_id: section.id,
                                category_id: category.id,
                            }
                            small_categories.push(data);
                            n++;
                        });
                    }
                }
            });
        }

        setSmallCategories(small_categories);

        return () => {
        };

    }, [props.sections, props.sectionID, props.categoryID]);


    const Press = (section_id, category_id) => {
        func(section_id, category_id);
    }

    const ChangeMenu = (value) => {
        var values = value.split('@');
        var section_id = parseInt(values[0]);
        var category_id = parseInt(values[1]);
        //setSelected(value);

        Press(section_id, category_id);
    }

    return (
        // ***************************************************************************************************************************************************************************
        // SMALL SCREENS
        // ***************************************************************************************************************************************************************************
        <div>
            {isSmall == true ?
                // SMALL SCREEN
                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingLeft: 10, paddingRight: 10 }}>
                    <FormControl sx={{ width: 300 }}>
                        <Select
                            value={selected}
                            onChange={(event) => ChangeMenu(event.target.value)}
                            size='small'
                            style={{ borderRadius: 20 }}
                        >
                            <MenuItem disabled value={0}><em>{lang.category_choose}</em></MenuItem>

                            {smallCategories.map((item) => (
                                <MenuItem key={item.id} style={{ backgroundColor: global.theme_white, fontWeight: item.category_id == 0 ? 'bold' : '400' }} value={String(item.section_id) + '@' + String(item.category_id)}>
                                    <p style={{ ...styles.TextTiny, marginLeft: item.category_id == 0 ? 0 : 20 }}>
                                        {item.label}
                                    </p>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                :
                // LARGE SCREENS
                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', paddingLeft: 10, paddingRight: 10 }}>
                    {props.sections != false ? props.sections.map((item) => (
                        <div key={item.id} style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', marginBottom: 10 }}>
                            <Button onClick={() => Press(item.id, false)} variant="text" style={{ width: '100%', color: global.theme_black, textTransform: 'none', textAlign: 'left', alignItems: 'self-start', justifyContent: 'left', fontSize: 16, fontWeight: 'bold', backgroundColor: props.sectionID == item.id && props.categoryID == 0 ? color.menu_item : undefined, borderRadius: 20 }}>
                                <p style={{ ...styles.TextSmall, marginLeft: 5 }}>
                                    {item.label}
                                </p>
                            </Button>
                            {item.categories != false ? item.categories.length > 1 ? item.categories.map((element) => (
                                <div key={element.id} style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', marginBottom: 10 }}>
                                    <Button onClick={() => Press(item.id, element.id)} variant="text" style={{ width: '100%', color: global.theme_black, textTransform: 'none', textAlign: 'left', alignItems: 'self-start', justifyContent: 'left', fontSize: 16, fontWeight: '400', backgroundColor: props.categoryID == element.id ? color.menu_item : undefined, borderRadius: 20 }}>
                                        <p style={{ ...styles.TextTiny, marginLeft: 15 }}>
                                            {element.label}
                                        </p>
                                    </Button>
                                </div>
                            )) : null : null}
                        </div>
                    )) : null}


                </div>
            }
        </div >
    );
}


export const BonusInfo = (props) => {
    /*
            USAGE:
            <BonusInfo typ={0} lang={lang} language={language} func={BonusInfoPress.bind(this)} />
 
            const BonusInfoPress = (value) => {
                Debug(value)
            }

            */

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value);
    }


    return (
        <div>
            {props.typ == 1 ?
                props.language == 0 ?
                    <div style={{ padding: 10 }}>
                        <p>Vernostný systém kozmetického salónu je spôsob, ako Vám chceme poďakovať za kúpu našich produktov.</p>
                        <p>Pri nákupe produktov v našom e-shope prostredníctom klientskej aplikácie, alebo priamo na webe získavate vernostné body. Za každé euro nákupu produktov získavate 1 vernostný bod. Do ceny sa nepočíta poplatok za doručenie a platbu.</p>
                        <div>
                            <h4><b>1€ = 1 Vernostný bod</b></h4>
                        </div>
                        <p>Za každých 400 vernostných bodov automaticky získavate darčekovú poukážku v hodnote 15 €. Poukážku môžete využiť na zľavu z ošetrenia Institut Esthederm v našom kozmetickom salóne. Darčeková poukážka je platná len v kozmetickom salóne, v ktorom bola vystavená. Poukážka má platnosť 1 rok od vystavenia.</p>
                        <div>
                            <h5><b>400 Vernostných bodov = 15 € zľava</b></h5>
                        </div>
                        <p>V prípade, že nákup, za ktorý ste obdržali vernostné body zrušíte, alebo pozmeníte - vernostné body budu za daný nákup upravené a darčeková poukážka môže byť pri odpočítaní bodov vymazaná.</p>
                    </div>
                    :
                    props.language == 1 ?
                        <div>
                            <p>Věrnostní systém kosmetického salonu je způsob, jak Vám chceme poděkovat za koupi našich produktů.</p>
                            <p>Při nákupu produktů v našem e-shopu prostřednictvím klientské aplikace nebo přímo na webu získáváte věrnostní body. Za každé euro nákupu produktů získáváte 1 věrnostní bod. Do ceny se nepočítá poplatek za doručení a platbu.</p>
                            <div>
                                <h4><b>1Kč = 1 Věrnostní bod</b></h4>
                            </div>
                            <p>Za každých 400 věrnostních bodů automaticky získáváte dárkový poukaz v hodnotě 15 €. Poukázku můžete využít na slevu z ošetření Institut Esthederm v našem kosmetickém salonu. Dárková poukázka je platná pouze v kosmetickém salonu, ve kterém byla vystavena. Poukázka má platnost 1 rok od vystavení.</p>
                            <div>
                                <h5><b>12500 Věrnostních bodů = 500 Kč sleva</b></h5>
                            </div>
                            <p>V případě, že nákup, za který jste obdrželi věrnostní body zrušíte, nebo pozměníte - věrnostní body budou za daný nákup upraveny a dárková poukázka může být při odečtení bodů vymazána.</p>
                        </div>
                        : null
                :
                <Dialog
                    open={true}
                    scroll={'paper'}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle style={{ backgroundColor: global.theme_gray }} id="scroll-dialog-title">{props.lang.bonus_system}</DialogTitle>
                    {props.language == 0 ?
                        <div style={{ padding: 10 }}>
                            <p>Vernostný systém kozmetického salónu je spôsob, ako Vám chceme poďakovať za kúpu našich produktov.</p>
                            <p>Pri nákupe produktov v našom e-shope prostredníctom klientskej aplikácie, alebo priamo na webe získavate vernostné body. Za každé euro nákupu produktov získavate 1 vernostný bod. Do ceny sa nepočíta poplatok za doručenie a platbu.</p>
                            <div>
                                <h4><b>1€ = 1 Vernostný bod</b></h4>
                            </div>
                            <p>Za každých 400 vernostných bodov automaticky získavate darčekovú poukážku v hodnote 15 €. Poukážku môžete využiť na zľavu z ošetrenia Institut Esthederm v našom kozmetickom salóne. Darčeková poukážka je platná len v kozmetickom salóne, v ktorom bola vystavená. Poukážka má platnosť 1 rok od vystavenia.</p>
                            <div>
                                <h5><b>400 Vernostných bodov = 15 € zľava</b></h5>
                            </div>
                            <p>V prípade, že nákup, za ktorý ste obdržali vernostné body zrušíte, alebo pozmeníte - vernostné body budu za daný nákup upravené a darčeková poukážka môže byť pri odpočítaní bodov vymazaná.</p>
                        </div>
                        : null}
                    {props.language == 1 ?
                        <div>
                            <p>Věrnostní systém kosmetického salonu je způsob, jak Vám chceme poděkovat za koupi našich produktů.</p>
                            <p>Při nákupu produktů v našem e-shopu prostřednictvím klientské aplikace nebo přímo na webu získáváte věrnostní body. Za každé euro nákupu produktů získáváte 1 věrnostní bod. Do ceny se nepočítá poplatek za doručení a platbu.</p>
                            <div>
                                <h4><b>1Kč = 1 Věrnostní bod</b></h4>
                            </div>
                            <p>Za každých 400 věrnostních bodů automaticky získáváte dárkový poukaz v hodnotě 15 €. Poukázku můžete využít na slevu z ošetření Institut Esthederm v našem kosmetickém salonu. Dárková poukázka je platná pouze v kosmetickém salonu, ve kterém byla vystavena. Poukázka má platnost 1 rok od vystavení.</p>
                            <div>
                                <h5><b>12500 Věrnostních bodů = 500 Kč sleva</b></h5>
                            </div>
                            <p>V případě, že nákup, za který jste obdrželi věrnostní body zrušíte, nebo pozměníte - věrnostní body budou za daný nákup upraveny a dárková poukázka může být při odečtení bodů vymazána.</p>
                        </div>
                        : null}
                    <DialogActions>
                        <Button onClick={() => Press(true)}>{props.lang.close}</Button>
                    </DialogActions>
                </Dialog>
            }
        </div >
    );
}


export const EncodeValue = (section_, category_, page_) => {
    // zakodovanie riadku v hlavicke URL
    var check = parseInt(section_) + parseInt(category_) + parseInt(page_);
    return 'id-' + check.toString().padStart(2, '0') + '=' + section_.toString() + '-' + category_.toString() + '-' + page_.toString();
}


export const DecodeValue = (value) => {
    // odkodovanie riadku v hlavicke URL
    var result = null;
    var tmp = value.split('=');
    if (tmp.length == 2) {
        var start = tmp[0].split('-');
        var end = tmp[1].split('-');
        if (start.length == 2 && end.length == 3) {
            var section_ = parseInt(end[0]);
            var category_ = parseInt(end[1]);
            var page_ = parseInt(end[2]);
            var check = parseInt(start[1]);

            if ((section_ + category_ + page_) == check) {
                return { section_id: section_, category_id: category_, page: page_ };
            }

        }
    }
    return '';
}


export const GetPrice = (price_) => {
    // VRATI CENU NAFORMATOVANU
    var tmp = parseFloat(price_).toFixed(2).toString();
    return tmp.replace('.', ',');
}


export const OrderError = (props) => {
    /* Pri odosielani nastala chyba

            USAGE:
            <OrderError lang={lang} language={language} func={OrderErrorPress.bind(this)} />
 
            const OrderErrorPress = (value) => {
                Debug(value)
            }

            */

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const dialogWidth = 500;
    const dialogHeight = 250;
    const titleHeight = 50;
    const buttonHeight = 80;

    let { func } = props;
    let lang = props.lang;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value);
    }


    return (
        <div>
            <Backdrop open={true} >
                <Paper elevation={3} style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight, borderRadius: 20 }}>
                    <div style={{ ...styles.Block, height: titleHeight }}>
                        <p style={{ ...styles.TextNormal, fontWeight: '600' }}>{lang.order_error}</p>
                    </div>
                    <div style={{ ...styles.Block, height: dialogHeight - titleHeight - buttonHeight }}>
                        <div style={{ ...styles.Block, width: '96%' }}>
                            <FontAwesomeIcon style={{ fontSize: 30, color: global.theme_dark_red }} icon={faExclamationTriangle} />
                            <p style={{ ...styles.TextSmall, marginTop: 10 }}>{props.lang.order_error_text}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <Button onClick={() => Press(true)} style={{ ...styles.ButtonSelect }}>{lang.close}</Button>
                    </div>
                </Paper>
            </Backdrop>
        </div >
    );
}


export const OrderRegisterError = (props) => {
    /* Pri odosielani nastala chyba

            USAGE:
            <OrderRegisterError lang={lang} language={language} func={OrderRegisterErrorPress.bind(this)} />
 
            const OrderRegisterErrorPress = (value) => {
                Debug(value)
            }

            */

    const dialogWidth = 500;
    const dialogHeight = 250;
    const titleHeight = 50;
    const buttonHeight = 80;

    let { func } = props;
    let lang = props.lang;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value);
    }


    return (
        <div>
            <Backdrop open={true} >
                <Paper elevation={3} style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight, borderRadius: 20 }}>
                    <div style={{ ...styles.Block, height: titleHeight }}>
                        <p style={{ ...styles.TextNormal, fontWeight: '600' }}>{lang.warning}</p>
                    </div>
                    <div style={{ ...styles.Block, height: dialogHeight - titleHeight - buttonHeight }}>
                        <div style={{ ...styles.Block, width: '96%' }}>
                            <FontAwesomeIcon style={{ fontSize: 30, color: global.theme_dark_red }} icon={faExclamationTriangle} />
                            <p style={{ ...styles.TextSmall, marginTop: 10 }}>{props.lang.email_already_registered}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <Button onClick={() => Press(0)} style={{ ...styles.ButtonSelect }}>{lang.close}</Button>
                    </div>
                </Paper>
            </Backdrop>
        </div >
    );
}



export const CookiesInfo = (props) => {
    /*
            USAGE:
            <CookiesInfo lang={lang} func={CookiesInfoPress.bind(this)} />
 
            const CookiesInfoPress = (value) => {
                Debug(value)
            }

            */

    const [showCookies, setShowCookies] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value);
    }


    return (
        <div>
            <div style={{ ...styles.Block, position: 'fixed', bottom: 0, backgroundColor: global.theme_black, paddingTop: isSmall ? 5 : 10, paddingBottom: isSmall ? 5 : 10 }}>
                <div style={{ ...styles.BlockRowRaw, width: '96%', maxWidth: 800 }}>
                    <div style={{ ...styles.Block }}>
                        <p style={{ ...isSmall ? styles.TextXTiny : styles.TextTiny, color: global.theme_white }}>{props.lang.cookies}</p>
                        <Button onClick={() => setShowCookies(true)} style={{ ...styles.ButtonLink, color: global.theme_white, marginTop: isSmall ? 5 : 0 }}><u>{props.lang.cookies_info}</u></Button>
                    </div>

                    <IconButton onClick={() => Press(false)} style={{ width: 36, height: 36, backgroundColor: global.theme_black, border: '1px solid #FFFFFF', marginLeft: isSmall ? 0 : 20 }}>
                        <p style={{ ...styles.TextXTiny, color: global.theme_white, fontWeight: '600' }}>X</p>
                    </IconButton>
                </div >
            </div>
            {showCookies == true ?
                <DialogRules typ={2} salon={props.salon} func={() => setShowCookies(false)} />
                : null}
        </div>
    );
}


export const EshopBusy = (props) => {
    /*
            USAGE:
            <EshopBusy lang={lang} />


            */

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    useEffect(() => {

        return () => {
        };

    }, []);


    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: global.theme_dark_red, justifyContent: 'center', alignItems: 'center', paddingLeft: 10, paddingRight: 10 }}>
            <p style={{ margin: 5, fontFamily: 'roboto', fontSize: 18, color: global.theme_white }}>{props.lang.eshop_not_ready}</p>
        </div>
    );
}




export const Template = (props) => {
    /*
            USAGE:
            <BonusInfo lang={lang} language={language} func={BonusInfoPress.bind(this)} />
 
            const BonusInfoPress = (value) => {
                Debug(value)
            }

            */

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value);
    }


    return (
        <div>
        </div >
    );
}


export const DialogLogin = (props) => {
    /*
       <DialogLogin language={language} func={LoginResult.bind(this)} />

    */

    const [isBusy, setBusy] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [username, setUsername] = useState(global.testing == true ? 'zakaznik' : '');
    const [password, setPassword] = useState(global.testing == true ? '12345678' : '');
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [index, setIndex] = useState(0);
    const [password2, setPassword2] = useState('');
    const [codeSystem, setCodeSystem] = useState('');
    const [userID, setUserID] = useState(0);
    const [showError, setShowError] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const logo = require('./react/logo.png'); // 460 x 220 px
    const logo_size = 85;

    const dialogWidth = 350;
    const dialogHeight = 480;
    const radius = 20;

    var lang = GetLanguage(props.language);
    let { func } = props;

    useEffect(() => {

    }, []);

    const db_login = async () => {
        if (isBusy == false) {
            setBusy(true);

            try {
                const response = await fetch(
                    dbURL() + 'login_user', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        username: username,
                        password: password,
                        language: ChangeLanguageURL()
                    })
                })

                const json = await response.json();
                Debug('Login-user');
                Debug(json);
                setBusy(false);

                if (json.ok > 0) {
                    setShowLogin(false);
                    SaveToken(json.token);
                    Press(1, json.token);
                } else {
                    setShowLogin(true);
                    setError(lang.login_error);
                }

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    };

    const db_recover = async () => {
        if (isBusy == false) {
            setBusy(true);
            try {
                const response = await fetch(
                    dbURL() + 'recover', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email,
                    })
                })

                const json = await response.json();
                Debug(json.ok);
                setBusy(false);
                Debug(json);
                if (json.ok > 0) {
                    if (json.checked == true) {
                        setCodeSystem(json.code);
                        setUserID(json.user_id);
                        setIndex(2);
                    } else {
                        setError(lang.lost_login_error);
                    }
                }

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    };

    const db_recover_update = async (data) => {
        // zmena hesla
        if (isBusy == false) {
            setBusy(true);
            try {
                const response = await fetch(
                    global.db_url + 'login_recover_update', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        data: data
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    // predvyplnit prihlasovací dialog s novym heslom
                    setUsername(email);
                    setIndex(4);
                } else {
                    setShowError(true);
                }
                setBusy(false);

            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }
    }

    const Press = (value, data) => {
        setBusy(false);
        func(value, data);
    }

    const Close = () => {
        Press(false, false);
    }

    const LoginProcess = () => {
        if (username.trim() != '' && password.trim() != '') {
            db_login();
        }
    }

    const Home = () => {
        setError('');
        setUsername('');
        setPassword('');
        setIndex(0);
    }


    const Forgot = () => {
        setError('');
        setEmail('');
        setPassword('');
        setPassword2('');
        setCode('');
        setIndex(1);
    }


    const ForgotProcess = () => {
        // KONTROLA EMAILOVEJ ADRESY
        setError('');
        if (email.trim() != '') {
            Debug('RECOVER');
            db_recover();
        }
    }

    const ForgotCheckCode = () => {
        // KONTROLA ZADANEHO KODU
        setError('');
        if (codeSystem == code.trim()) {
            setIndex(3);
        } else {
            setError(lang.login_pin_code_error);
        }
    }

    const ChangePassword = () => {
        // ULOZENIE NOVEHO HESLA
        setError('');
        var p1 = password.trim();
        var p2 = password2.trim();

        if (p1.length < 8) {
            setError(lang.password_error_len);
        } else if (p1 != p2) {
            setError(lang.passwords_not_equal);
        } else {
            var data = {
                id: userID,
                password: p1
            }

            Debug(data);
            db_recover_update(data);
        }
    }

    return (
        <Backdrop open={true} style={{ zIndex: 31000, backgroundColor: '#00000077' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, position: 'relative', height: dialogHeight, background: global.theme_gray, borderRadius: radius }}>

                <Paper elevation={6} style={{ ...styles.Block, position: 'relative', left: dialogWidth / 2 - ((logo_size + 0) / 2), width: logo_size, top: -(logo_size) / 2, height: logo_size, backgroundColor: global.theme_white, borderRadius: (logo_size + 40) / 2 }}>
                    <img src={logo} style={{ width: '100%', height: '100%', maxWidth: logo_size, maxHeight: logo_size, objectFit: 'contain', marginTop: 0 }}></img>
                </Paper>

                {index == 0 ?
                    // PRIHLASENIE
                    <div style={{ ...styles.BlockCenter, position: 'absolute', top: (logo_size + 40) / 2, height: dialogHeight - ((logo_size + 40) / 2) }}>
                        <p style={{ ...styles.TextLarge, letterSpacing: 2, fontWeight: '500', marginTop: 20 }}>{lang.login.toUpperCase()}</p>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 30 }}>
                            <p style={{ ...styles.TextInput }}>{lang.loginname}</p>
                            <TextField
                                value={username}
                                onInput={e => setUsername(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                style={{ width: '100%' }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                    }
                                }}
                                InputProps={{
                                    style: { backgroundColor: global.theme_white, borderRadius: 20 },
                                }}
                                variant="outlined"
                            />
                        </div>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 10 }}>
                            <p style={{ ...styles.TextInput }}>{lang.password}</p>
                            <TextField
                                value={password}
                                onInput={e => setPassword(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                style={{ width: '100%' }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                    }
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPassword(!showPassword)} style={{ width: 20, height: 20 }}>
                                            <FontAwesomeIcon style={{ height: 14, color: showPassword == true ? global.theme_dark_gray : global.theme_light_gray }} icon={faEye} />
                                        </IconButton>
                                    </InputAdornment>,
                                    style: { backgroundColor: global.theme_white, borderRadius: 20 },
                                }}
                                variant="outlined"
                                type={showPassword == true ? 'text' : 'password'}
                            />
                        </div>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 10 }}>
                            <Button onClick={() => Forgot()} style={{ ...styles.ButtonLink, width: 200 }}>{lang.lost_password}</Button>
                        </div>
                        <div style={{ ...styles.Block, height: 40 }}>
                            <p style={{ ...styles.TextTiny, color: global.theme_dark_red, fontWeight: '500' }}>{error}</p>
                        </div>
                        <div style={{ ...styles.Block, width: '90%', marginTop: 10 }}>
                            <Button onClick={() => LoginProcess()} style={{ ...styles.ButtonDark, width: 180 }}>{lang.login_button}</Button>
                        </div>
                        <div style={{ ...styles.Block, width: '90%', marginTop: 20 }}>
                            <Button onClick={() => Close(false)} style={{ ...styles.ButtonLink }}>{lang.close}</Button>
                        </div>

                    </div>
                    : null}

                {index == 1 ?
                    // ZABUDNUTE HESLO
                    <div style={{ ...styles.BlockCenter, position: 'absolute', top: (logo_size + 40) / 2, height: dialogHeight - ((logo_size + 40) / 2) }}>
                        <p style={{ ...styles.TextLarge, letterSpacing: 2, fontWeight: '500', marginTop: 20 }}>{lang.lost_password.toUpperCase()}</p>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 80 }}>
                            <p style={{ ...styles.TextInput }}>{lang.login_email}</p>
                            <TextField
                                value={email}
                                onInput={e => setEmail(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                style={{ width: '100%' }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                    }
                                }}
                                InputProps={{
                                    style: { backgroundColor: global.theme_white, borderRadius: 20 },
                                }}
                                variant="outlined"
                            />
                        </div>

                        <div style={{ ...styles.Block, height: 60 }}>
                            <p style={{ ...styles.TextTiny, color: global.theme_dark_red }}>{error}</p>
                        </div>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 10 }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <div style={{ ...styles.Block, width: 50 }}>
                                    <IconButton onClick={() => Home()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                                        <FontAwesomeIcon style={{ width: 10, color: global.theme_dark_blue }} icon={faAngleLeft} />
                                    </IconButton>
                                </div>
                                <div style={{ ...styles.Block, width: 200 }}>
                                    <Button onClick={() => ForgotProcess()} style={{ ...styles.ButtonDark }}>{lang.continue}</Button>
                                </div>
                                <div style={{ ...styles.Block, width: 50 }}></div>
                            </div>
                        </div>
                    </div>
                    : null}

                {index == 2 ?
                    // VLOZENIE PIN KODU
                    <div style={{ ...styles.BlockCenter, position: 'absolute', top: (logo_size + 40) / 2, height: dialogHeight - ((logo_size + 40) / 2) }}>
                        <p style={{ ...styles.TextLarge, letterSpacing: 2, fontWeight: '500', marginTop: 20 }}>{lang.lost_password.toUpperCase()}</p>
                        <div style={{ ...styles.Block, width: '95%', height: 80, marginTop: 10 }}>
                            <p style={{ ...styles.TextTiny }}>{lang.login_email_send}</p>
                        </div>
                        <div style={{ ...styles.Block, width: '90%', marginTop: 40 }}>
                            <p style={{ ...styles.TextInput }}>{lang.login_pin_code}</p>
                            <TextField
                                value={code}
                                onInput={e => setCode(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                style={{ width: '100%' }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                    }
                                }}
                                InputProps={{
                                    style: { backgroundColor: global.theme_white, borderRadius: 20 },
                                }}
                                variant="outlined"
                            />
                        </div>

                        <div style={{ ...styles.Block, height: 60 }}>
                            <p style={{ ...styles.TextTiny, color: global.theme_dark_red }}>{error}</p>
                        </div>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 10 }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <div style={{ ...styles.Block, width: 50 }}>
                                    <IconButton onClick={() => Home()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                                        <FontAwesomeIcon style={{ width: 10, color: global.theme_dark_blue }} icon={faAngleLeft} />
                                    </IconButton>
                                </div>
                                <div style={{ ...styles.Block, width: 200 }}>
                                    <Button onClick={() => ForgotCheckCode()} style={{ ...styles.ButtonDark }}>{lang.continue}</Button>
                                </div>
                                <div style={{ ...styles.Block, width: 50 }}></div>
                            </div>
                        </div>
                    </div>
                    : null}

                {index == 3 ?
                    // ZADANIE NOVEHO HESLA
                    <div style={{ ...styles.BlockCenter, position: 'absolute', top: (logo_size + 40) / 2, height: dialogHeight - ((logo_size + 40) / 2) }}>
                        <p style={{ ...styles.TextXLarge, letterSpacing: 2, fontWeight: '500', marginTop: 20 }}>{lang.lost_password.toUpperCase()}</p>
                        <div style={{ ...styles.Block, width: '90%', marginTop: 40 }}>
                            <p style={{ ...styles.TextInput }}>{lang.password_new}</p>
                            <TextField
                                value={password}
                                onInput={e => setPassword(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                style={{ width: '100%' }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                    }
                                }}
                                InputProps={{
                                    style: { backgroundColor: global.theme_white, borderRadius: 20 },
                                }}
                                variant="outlined"
                                type='password'
                            />
                        </div>
                        <div style={{ ...styles.Block, width: '90%', marginTop: 20 }}>
                            <p style={{ ...styles.TextInput }}>{lang.password_new_again}</p>
                            <TextField
                                value={password2}
                                onInput={e => setPassword2(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                style={{ width: '100%' }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                    }
                                }}
                                InputProps={{
                                    style: { backgroundColor: global.theme_white, borderRadius: 20 },
                                }}
                                variant="outlined"
                                type='password'
                            />
                        </div>

                        <div style={{ ...styles.Block, height: 60 }}>
                            <p style={{ ...styles.TextTiny, color: global.theme_dark_red }}>{error}</p>
                        </div>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 10 }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <div style={{ ...styles.Block, width: 50 }}>
                                    <IconButton onClick={() => Home()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                                        <FontAwesomeIcon style={{ width: 10, color: global.theme_dark_blue }} icon={faAngleLeft} />
                                    </IconButton>
                                </div>
                                <div style={{ ...styles.Block, width: 200 }}>
                                    <Button onClick={() => ChangePassword()} style={{ ...styles.ButtonDark }}>{lang.save}</Button>
                                </div>
                                <div style={{ ...styles.Block, width: 50 }}></div>
                            </div>
                        </div>
                    </div>
                    : null}

                {index == 4 ?
                    // HESLO BOLO ZMENENE
                    <div style={{ ...styles.Block, position: 'absolute', top: (logo_size + 40) / 2, height: dialogHeight - ((logo_size + 40) / 2) }}>
                        <FontAwesomeIcon style={{ fontSize: 50, color: global.theme_darker_green }} icon={faCheckCircle} />
                        <p style={{ ...styles.TextNormal, marginTop: 20 }}>{lang.password_ok}</p>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 50 }}>
                            <Button onClick={() => setIndex(0)} style={{ ...styles.ButtonDark }}>{lang.continue}</Button>
                        </div>
                    </div>
                    : null}

            </Paper>

            {isBusy == true ?
                <Loading ></Loading>
                : null}

            {/*showError == true ?
                        <ShowError text={lang.db_error} func={ShowErrorPress.bind(this)} />
                    : null*/}

        </Backdrop>
    )
}


export const CheckToken = (props) => {
    /*
        KONTROLA TOKENU PRED VSTUPOM NA KAZDU STRANKU
        Ak sa nejedná o vstupnú stránku (kde alias == undefined), skontroluje existujúci token,
        prípadne vytvorí nový public token (ak token neexistuje)


        <CheckToken alias={alias} func={TokenResult.bind(this)} />

        ----------------------------------------------------------------------------------------
        const TokenResult = (typ, json) => {
            if (typ == true) {
                setBusy(false);
            } else {
                navigate('/logout');
            }
        }

        Obnovenie stránky
        window.location.reload();
        ----------------------------------------------------------------------------------------
    */
    //const navigate = useNavigate();

    let { func } = props;

    useEffect(() => {
        var token = LoadToken();
        Debug('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx');
        Debug('CHECK TOKEN: ' + token);
        Debug('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx');
        if (token == false) {
            // nenájdený token
            if (props.alias != undefined) {
                // tvorba nového tokenu, ak nieje vstupná stránka (alias == undefined)
                db_token_create(props.alias);
            } else {
                // uvodná stránka - nie je potrebný token
                Press(false);
            }
        } else {
            // token sa našiel - získať údaje z DB
            db_token(token);
        }


        return () => {
        };

    }, []);

    /* *******************************************************************************
            ZISKANIE UDAJOV Z TOKENU
        ******************************************************************************* */
    const db_token = async (token) => {
        let language = ChangeLanguageURL();
        Debug('LANGUAGE: ' + language);
        try {
            const response = await fetch(
                dbURL() + 'token', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token,
                    language: language
                })
            })

            const json = await response.json();
            var ok = false;
            Debug('GET TOKEN DATA');
            Debug('TOKEN: ' + token);
            Debug(json);
            if (json.ok > 0) {
                Debug('GET TOKEN DATA - OK');
                if (json.verified == true) {
                    Debug('GET TOKEN DATA - VERIFED');
                    ok = true;
                    Press(true, json);
                }
            }

            if (ok == false) {
                // overenie nepreslo
                Press(false);
            }

        } catch (error) {
            // nastala chyba
            Press(false);
            Debug(error);
        }
    };

    /* *******************************************************************************
        VYTVORENIE TOKENU - PUBLIC
    ******************************************************************************* */
    const db_token_create = async (alias) => {
        Debug('TVORBA NOVEHO TOKENU ...');
        let language = ChangeLanguageURL();

        try {
            const response = await fetch(
                dbURL() + 'token_create', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    alias: alias,
                    language: language
                })
            })

            const json = await response.json();
            var ok = false;

            if (json.ok > 0) {
                if (json.verified == true) {
                    ok = true;
                    SaveToken(json.token);
                    Press(true, json);
                }
            }

            if (ok == false) {
                Press(false);
            }

        } catch (error) {
            Debug(error);
            Press(false);
        }
    };

    const Press = (typ, data) => {
        func(typ, data);
    }


    return (
        <div>
        </div >
    );
}


export const DialogRules = (props) => {
    /*

        <DialogRules typ={0} salon={salon} func={RulesResult.bind(this)} />

    */
    const [language, setLanguage] = useState(0);
    const [isBusy, setBusy] = useState(true);
    const [salon, setSalon] = useState([]);
    const [remoteView, setRemoteView] = useState(false);
    const [text, setText] = useState('');

    var lang = GetLanguage(language);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const dialogWidth = isSmall ? width : 600;
    const dialogHeight = isSmall ? height - 100 : 800;
    const buttonHeight = 50;

    let { func } = props;

    useEffect(() => {
        Debug('RULES');
        db_get_text();
        return () => {
        };

    }, []);

    const db_get_text = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                dbURL() + 'rules', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.salon.id,
                    typ: props.typ
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setText(json.text);
            }
            setBusy(false);
        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    };

    const Close = () => {
        func(false);
    }

    return (
        <Backdrop open={true} style={{ zIndex: 31000 }}>
            <Paper elevation={3} style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight, backgroundColor: global.theme_white, borderRadius: 20 }}>
                <div style={{ ...styles.Block, alignItems: 'flex-end', height: buttonHeight }}>
                    <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_black, marginRight: 20 }}>
                        <p style={{ ...styles.TextTiny, color: global.theme_white, fontWeight: '600' }}>X</p>
                    </IconButton>
                </div>
                <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - buttonHeight, overflowY: 'scroll' }}>
                    <div style={{ ...styles.BlockLeft, width: '96%' }}>
                        <div style={{ ...styles.BlockLeft, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: text }} />
                    </div>
                    {isBusy == false ?
                        <Button onClick={() => Close()} style={{ color: global.theme_black, width: 200, borderColor: global.theme_black, marginTop: 20, marginBottom: 30, borderRadius: 20 }} variant='outlined'>{lang.close}</Button>
                        : null}
                </div>
            </Paper>

            {isBusy == true ?
                <Loading />
                : null}

        </Backdrop>
    );
}