import React, { useEffect, useState } from 'react';
import './react/App.css';
import './react/w3.css';
import Button from '@mui/material/Button';
import { Routes, Route, Link, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import "animate.css/animate.min.css";
import { GetSize, NavigationMenu, Footer, Debug, LoadSalon, SaveSalonSettings, LoadSalonSettings, LoadLogedIn, LoadLanguage, LoadAlias, Verify, SaveLanguage, ChangeLanguage, ChangeLanguageURL, Loading, LoadClient, NeedRedraw, SaveSalon, dbURL, SaveTime, LoadPublic, LoadCardQuantity, SaveCardQuantity, GetPrice, BannerImage, LoadToken, LoadCookies, SavePage, SaveScroll, GetImageLink, CheckToken, BottomMenu } from './Items.js';
import { BannerTop, LoadProductNames, SaveProductNames, GetLanguage } from './Items.js';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';
import { styles } from './styles';

export const Card = (props) => {
    const navigate = useNavigate();
    let params = useParams();

    const [isBusy, setBusy] = useState(true);
    const [isUpdating, setUpdating] = useState(false);
    const [isProductsBusy, setProductsBusy] = useState(false);
    const [salon, setSalon] = useState(false);
    const [settings, setSettings] = useState(false);
    const [language, setLanguage] = useState(ChangeLanguageURL());
    const [alias, setAlias] = useState(params.alias);
    const [client, setClient] = useState(false);
    const [productNames, setProductNames] = useState([]);
    const [logedIn, setLogedIn] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [tradeFree, setTradeFree] = useState(false);
    const [tradeFreePrice, setTradeFreePrice] = useState(false);
    const [banner, setBanner] = useState(false);
    const [showCookies, setShowCookies] = useState(false);
    const [theme, setTheme] = useState(0);
    const [test, setTest] = useState('');

    const [cardQuantity, setCardQuantity] = useState(0);
    const [products, setProducts] = useState([]);
    const [sum, setSum] = useState(0);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const img_bag = require('./react/bag.png');

    var lang = GetLanguage(language);
    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            if (LoadCookies() == null) {
                setShowCookies(true);
            }

            const element = document.getElementById('id-start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }

        return () => {
        };

    }, []);


    const TokenResult = (typ, json) => {
        if (typ == true) {
            setSalon(json.salon);
            setAlias(json.salon.alias);
            setSettings(json.settings);
            setLanguage(json.salon.language);
            setClient(json.client);
            setLogedIn(json.logged);

            db_shopping_card(json.salon.id, json.client == false ? 0 : json.client.id);
        } else {
            navigate('/logout');
        }
    }

    /* *******************************************************************************
        PRODUKTY V NAKUPNOM KOSIKU
    ******************************************************************************* */
    const db_shopping_card = async (salon_id, client_id) => {
        setBusy(true);

        var token = LoadToken();

        try {
            const response = await fetch(
                dbURL() + 'shopping_card_v2', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: salon_id,
                    client_id: client_id,
                    public_key: token,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                var tmp = json.card;
                setProducts(tmp);
                setSum(json.sum);
                setCardQuantity(json.quantity);
                SaveCardQuantity(json.quantity);
                setTradeFreePrice(json.trade_free_price);
                setTradeFree(json.trade_free_enabled == true);
                setBanner(json.banner);
            }

        } catch (error) {
            setBusy(false);
            Debug(error);
        }

    };


    /* *******************************************************************************
        PRODUKTY - UPDATE - zmena poctu / delete
    ******************************************************************************* */
    const db_product_update = async (id, quantity) => {

        try {
            const response = await fetch(
                dbURL() + 'product_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id,
                    quantity: quantity,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                db_shopping_card(salon.id, client == false ? 0 : client.id);
            }

        } catch (error) {
            Debug(error);
        }

    };

    const db_test = async () => {
        setBusy(true);

        var token = LoadToken();

        try {
            const response = await fetch(
                dbURL() + 'checkout_test', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token
                })
            })

            const json = await response.json();
            setBusy(false);
            Debug('CHECKOUT-TEST');
            Debug('token: ' + token);
            Debug(json);
            if (json.ok > 0) {
                setTest(json.text);
            }

        } catch (error) {
            setBusy(false);
            Debug(error);
        }

    };

    const BannerTopPress = (value, token_) => {
        if (value == 1) {
            // PRIHLASENIE SA UZIVATELA -> presmerovanie na jeho salon
            SavePage(0);
            SaveScroll(0);
            //db_token(token_);
            window.location.reload();
        }

        // AKCIA Z BANNERU
        if (value == 2) {
            // ODHLASIT SA
            navigate('/logout');
        }
        if (value == 3) {
            // UCET            
            navigate('/' + alias + '/account');
        }
    }

    const ChangeQuantity = (event, item) => {
        var quantity = event.target.value;
        if (quantity > 0) {

            // Zapis do DB
            db_product_update(item.id, quantity);
        } else {
            DeleteItem(item);
        }
    }

    const DeleteItem = (item) => {
        var products_ = products.filter(x => x.id != item.id);

        var sum_ = 0;
        var card_quantity = 0;
        products_.forEach(element => {
            sum_ = sum_ + parseFloat(element.sum);
            card_quantity += parseInt(element.quantity);
        });
        setProducts(products_);
        setSum(parseFloat(sum_).toFixed(2));
        setCardQuantity(card_quantity);
        SaveCardQuantity(card_quantity);

        // Zapis do DB
        db_product_update(item.id, 0);

        setRedraw(!redraw);
    }

    const Back = () => {
        navigate('/' + alias + '/eshop');
    }

    const Checkout = () => {
        navigate('/' + alias + '/checkout');
    }

    return (
        <div id={'id-start'}>
            {isSmall ? null :
                <BannerTop theme={theme} chooseSalon={true} client={client} logged={client == false ? false : true} alias={alias} salon={salon} logedIn={logedIn} language={language} func={BannerTopPress.bind(this)} />
            }
            <NavigationMenu theme={theme} size={() => GetSize()} client={client} logged={client == false ? false : true} showLanguage={false} language={language} logedin={logedIn} alias={alias} salon={salon} index={3} quantity={cardQuantity} products={productNames} />
            <CheckToken alias={alias} func={TokenResult.bind(this)} />

            {banner != undefined && banner != false ?
                <BannerImage typ={4} language={language} file={banner} />
                : null}

            <div style={{ ...styles.BlockCenter }}>
                <div style={{ ...styles.BlockCenter, maxWidth: isSmall ? '95%' : global.screen_max, minHeight: height }}>


                    <p style={{ fontSize: 30, color: global.theme_black, fontFamily: 'roboto' }}>{lang.card}</p>

                    {cardQuantity > 0 ?
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            {products.map(item => {
                                return (
                                    isSmall ?
                                        <div key={item.id} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-start', width: '100%', paddingTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_gray }}>
                                            <div style={{ ...styles.BlockRow, alignSelf: 'flex-start' }}>
                                                <div style={{ display: 'flex', alignItems: 'flex-end', width: 120, height: 120 }}>
                                                    <img src={GetImageLink(item.image)} style={{ width: 100, height: 100 }} />
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '70%' }}>
                                                    <p style={{ ...styles.TextTiny }}>{lang.ie}</p>
                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                    <div style={{ ...styles.BlockLeft, maxHeight: 40, overflow: 'hidden' }}>
                                                        <p style={{ ...styles.TextTiny, color: global.theme_dark_gray, textAlign: 'left' }}>{item.note_short}</p>
                                                    </div>
                                                    <p style={{ ...styles.TextTiny, marginTop: 10 }}>{item.details}</p>
                                                </div>
                                            </div>
                                            <div style={{ ...styles.BlockRow, marginTop: 10, height: 40 }}>
                                                <div style={{ ...styles.Block, width: 120 }}>
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '30%', height: 40 }}>
                                                    <Select
                                                        id="order-select"
                                                        style={{ textAlign: 'left', color: global.theme_black, width: 70, height: 40, marginRight: 0 }}
                                                        value={item.quantity}
                                                        size='small'
                                                        onChange={(event) => ChangeQuantity(event, item)}
                                                    >
                                                        <MenuItem value={0}>{'0'}</MenuItem>
                                                        <MenuItem value={1}>{'1'}</MenuItem>
                                                        <MenuItem value={2}>{'2'}</MenuItem>
                                                        <MenuItem value={3}>{'3'}</MenuItem>
                                                        <MenuItem value={4}>{'4'}</MenuItem>
                                                        <MenuItem value={5}>{'5'}</MenuItem>
                                                        <MenuItem value={6}>{'6'}</MenuItem>
                                                        <MenuItem value={7}>{'7'}</MenuItem>
                                                        <MenuItem value={8}>{'8'}</MenuItem>
                                                        <MenuItem value={9}>{'9'}</MenuItem>
                                                        <MenuItem value={10}>{'10'}</MenuItem>
                                                    </Select>
                                                </div>

                                                <div style={{ ...styles.Block, width: '50%', height: 40, alignItems: 'flex-end' }}>
                                                    <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{GetPrice(item.sum)} {lang.money}</p>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div key={item.id} style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', width: '100%', paddingTop: 10, paddingBottom: 10, borderTop: '1px solid ' + global.theme_gray }}>
                                            <div style={{ display: 'flex', alignItems: 'flex-end', width: 140, height: 100 }}>
                                                <img src={GetImageLink(item.image)} style={{ width: 100, height: 100 }} />
                                            </div>
                                            <div style={{ ...styles.BlockCenter, alignItems: 'flex-start', width: '50%', paddingRight: 20 }}>
                                                {item.brand_id == 1 ?
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.ie}</p>
                                                    : null}
                                                <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                <div style={{ ...styles.BlockLeft, maxHeight: 40, overflow: 'hidden' }}>
                                                    <p style={{ ...styles.TextTiny, color: global.theme_dark_gray, textAlign: 'left' }}>{item.note_short}</p>
                                                </div>
                                                <p style={{ ...styles.TextTiny, marginTop: 10, textAlign: 'left' }}>{item.details}</p>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start', width: '15%' }}>
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'normal', fontFamily: 'roboto' }}>{GetPrice(item.eur)} {lang.money}</p>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start', width: '15%' }}>
                                                <Select
                                                    id="order-select"
                                                    style={{ textAlign: 'left', color: global.theme_black, width: 70, height: 40, marginRight: 20 }}
                                                    value={item.quantity}
                                                    onChange={(event) => ChangeQuantity(event, item)}
                                                >
                                                    <MenuItem value={1}>{'1'}</MenuItem>
                                                    <MenuItem value={2}>{'2'}</MenuItem>
                                                    <MenuItem value={3}>{'3'}</MenuItem>
                                                    <MenuItem value={4}>{'4'}</MenuItem>
                                                    <MenuItem value={5}>{'5'}</MenuItem>
                                                    <MenuItem value={6}>{'6'}</MenuItem>
                                                    <MenuItem value={7}>{'7'}</MenuItem>
                                                    <MenuItem value={8}>{'8'}</MenuItem>
                                                    <MenuItem value={9}>{'9'}</MenuItem>
                                                    <MenuItem value={10}>{'10'}</MenuItem>
                                                </Select>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start', width: '15%' }}>
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto' }}>{GetPrice(item.sum)} {lang.money}</p>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start', width: '5%' }}>
                                                <IconButton onClick={() => DeleteItem(item)} style={{ height: 35 }}>
                                                    <FontAwesomeIcon style={{ color: global.theme_dark_gray, fontSize: 16 }} icon={faTrashAlt} />
                                                </IconButton>
                                            </div>
                                        </div>
                                )
                            })
                            }
                        </div>
                        :
                        <div style={{ marginTop: 20 }}>
                            {isBusy == false ?
                                <div style={{ ...styles.Block }}>
                                    <img src={img_bag} style={{ width: '100%', height: '100%', maxWidth: 60, maxHeight: 60, objectFit: 'contain' }}></img>
                                    <p style={{ ...styles.TextLarge, fontWeight: '600', marginTop: 20 }}>{lang.empty_basket}</p>
                                    <p style={{ ...styles.TextSmall, marginTop: 5, marginBottom: 50 }}>{lang.empty_basket_text}</p>
                                </div>
                                : null}
                        </div>
                    }
                    {
                        isSmall ?
                            cardQuantity > 0 ?
                                <div style={{ width: '100%' }}>
                                    {(tradeFree == true) ?
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', width: '100%', paddingTop: 20, paddingBottom: 20 }}>
                                            {((parseFloat(tradeFreePrice) - parseFloat(sum)) > 0) ?
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto', textAlign: 'center', marginLeft: 10 }}>{lang.get_trade_free1} {GetPrice(tradeFreePrice - sum)} {lang.money} {lang.get_trade_free2}.</p>
                                                :
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto', textAlign: 'center', marginLeft: 10 }}>{lang.trade_is_free}</p>
                                            }
                                        </div>
                                        : null}

                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', width: '100%', paddingTop: 20, paddingBottom: 50, borderTop: '1px solid ' + global.theme_gray }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ paddingTop: 6 }}>
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 14, fontWeight: 'normal', fontFamily: 'roboto', textAlign: 'left' }}>{lang.card_sum}:</p>
                                            </div>
                                            <p style={{ margin: 0, color: global.theme_black, fontSize: 18, fontWeight: 'bold', fontFamily: 'roboto', textAlign: 'left', marginLeft: 10 }}>{GetPrice(sum)} {lang.money}</p>
                                        </div>
                                    </div>
                                </div>
                                : null
                            :
                            cardQuantity > 0 ?
                                <div style={{ width: '100%' }}>
                                    {(tradeFree == true) ?
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', width: '100%', width: '100%', paddingTop: 20, paddingBottom: 20 }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {((parseFloat(tradeFreePrice) - parseFloat(sum)) > 0) ?
                                                    <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto', textAlign: 'left', marginLeft: 10 }}>{lang.get_trade_free1} {GetPrice(tradeFreePrice - sum)} {lang.money} {lang.get_trade_free2}.</p>
                                                    :
                                                    <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto', textAlign: 'left', marginLeft: 10 }}>{lang.trade_is_free}</p>
                                                }
                                            </div>
                                        </div>
                                        : null}

                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column', width: '100%', width: '100%', paddingTop: 20, paddingBottom: 50, borderTop: '1px solid ' + global.theme_gray }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ paddingTop: 6 }}>
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 18, fontWeight: 'normal', fontFamily: 'roboto', textAlign: 'left' }}>{lang.card_sum}:</p>
                                            </div>
                                            <p style={{ margin: 0, color: global.theme_black, fontSize: 24, fontWeight: 'bold', fontFamily: 'roboto', textAlign: 'left', marginLeft: 10 }}>{GetPrice(sum)} {lang.money}</p>
                                        </div>
                                    </div>
                                </div>
                                : null
                    }

                    {isSmall ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', paddingTop: 20, paddingBottom: 150, borderTop: '1px solid ' + global.theme_gray }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                    {cardQuantity > 0 ?
                                        salon.eshop_ready == true ?
                                            <Button onClick={() => Checkout()} variant="contained" style={{ color: global.theme_white, backgroundColor: global.theme_black, fontSize: 14, width: 200, height: 40, textTransform: 'none', borderRadius: 20 }}>{lang.to_checkout}</Button>
                                            : null
                                        : null}
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 20 }}>
                                    <Button onClick={() => Back()} style={{ backgroundColor: global.theme_white, width: 200, height: 40, textTransform: 'none', color: global.theme_black, borderColor: global.theme_black, borderRadius: 20 }} variant="outlined">{lang.back_to_shop}</Button>
                                </div>
                            </div>
                        </div>
                        :
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', paddingTop: 20, paddingBottom: 150, borderTop: '1px solid ' + global.theme_gray }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '50%' }}>
                                    <Button onClick={() => Back()} style={{ backgroundColor: global.theme_white, width: 200, height: 40, textTransform: 'none', color: global.theme_black, borderColor: global.theme_black, borderRadius: 20 }} variant="outlined">{lang.back_to_shop}</Button>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '50%' }}>
                                    {cardQuantity > 0 ?
                                        salon.eshop_ready == true ?
                                            <Button onClick={() => Checkout()} variant="contained" style={{ color: global.theme_white, backgroundColor: global.theme_black, fontSize: 14, width: 200, height: 40, textTransform: 'none', borderRadius: 20 }}>{lang.to_checkout}</Button>
                                            : null
                                        : null}
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>

            <BottomMenu language={language} salon={salon} />
            <Footer language={language} islogged={logedIn} />

            {isBusy == true ?
                <Loading />
                : null}

        </div>
    )

}
