import React, { useEffect, useState } from 'react';
import './react/App.css';
import './react/w3.css';
import { Routes, Route, Link, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import "animate.css/animate.min.css";

import { GetSize, NavigationMenu, Footer, Debug, LoadSalon, SaveSalonSettings, LoadSalonSettings, LoadLogedIn, LoadLanguage, LoadAlias, Verify, SaveLanguage, ChangeLanguage, ChangeLanguageURL, Loading, LoadClient, NeedRedraw, SaveSalon, dbURL, SaveTime, SaveProductNames, LoadProductNames, GetLanguage, MyLine, LoadCardQuantity, CookiesInfo, LoadCookies, SaveCookies, BannerSliderMyPage, LoadToken, Init, SaveToken, SavePage, SaveScroll, CheckToken, DialogRules } from './Items.js';
import { BannerTop, BannerImage, BottomMenu } from './Items.js';
import { styles } from './styles';


export const MyPage = (props) => {
    const navigate = useNavigate();
    let params = useParams();

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [cardQuantity, setCardQuantity] = useState(0);
    const [isBusy, setBusy] = useState(true);
    const [salon, setSalon] = useState(false);
    const [settings, setSettings] = useState(false);
    const [language, setLanguage] = useState(ChangeLanguageURL());
    const [alias, setAlias] = useState(params.alias);
    const [client, setClient] = useState(false);
    const [productNames, setProductNames] = useState(false);
    const [logedIn, setLogedIn] = useState(false);
    const [showCookies, setShowCookies] = useState(false);
    const [theme, setTheme] = useState(0);

    var lang = GetLanguage(language);

    const img_service1 = require('./react/service1.png');
    const img_service2 = require('./react/service2.png');
    const img_body = require('./react/ie_body.png');
    const img_face = require('./react/ie_face.png');
    const img_hands = require('./react/ie_hands.png');
    const img_eshop = require('./react/eshop.jpg');

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            if (LoadCookies() == null) {
                setShowCookies(true);
            }

            const element = document.getElementById('id-start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }

        return () => {
        };

    }, []);


    const TokenResult = (typ, json) => {
        Debug('TOKEN - RESULT');
        if (typ == true) {
            Debug('TOKEN - RESULT - TYP - TRUE');
            setBusy(false);

            setSalon(json.salon);
            setAlias(json.salon.alias);
            setSettings(json.settings);
            setLanguage(json.salon.language);
            setClient(json.client);
            setLogedIn(json.logged);
        } else {
            navigate('/logout');
        }
    }

    /* *******************************************************************************
        VYTVORENIE TOKENU - PUBLIC
    ******************************************************************************* */
    const db_token_create = async () => {
        setBusy(true);

        var alias = params.alias;

        try {
            const response = await fetch(
                dbURL() + 'token_create', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    alias: alias
                })
            })

            const json = await response.json();
            setBusy(false);
            Debug('db_token_create');;
            Debug(json);
            if (json.ok > 0) {
                if (json.verified == true) {
                    SaveToken(json.token);
                    setSalon(json.salon);
                    setSettings(json.settings);
                    setLanguage(json.salon.language);

                } else {
                    navigate('/logout');
                }
            }

        } catch (error) {
            setBusy(false);
            Debug(error);
        }
    };

    const BannerTopPress = (value, token_) => {
        if (value == 1) {
            SavePage(0);
            SaveScroll(0);
            //db_token(token_);
            window.location.reload();
        }

        // AKCIA Z BANNERU
        if (value == 2) {
            // ODHLASIT SA
            navigate('/logout');
        }
        if (value == 3) {
            // Moj ucet
            navigate('/' + alias + '/account');
        }

    }

    const CookiesInfoPress = (value) => {
        setShowCookies(false);
        SaveCookies(true);
    }

    const GotoFace = () => {
        navigate('/' + alias + '/eshop');
    }

    const GotoBody = () => {
        navigate('/' + alias + '/eshop');
    }

    const GotoSummer = () => {
        navigate('/' + alias + '/eshop');
    }

    const GetBanner = () => {
        if (settings.banner != '') {
            return settings.banner;
        }
        if (settings.banner2 != '') {
            return settings.banner2;
        }
        if (settings.banner3 != '') {
            return settings.banner3;
        }
        return '';
    }

    const GotoEshop = () => {
        navigate('/' + alias + '/eshop');
    }

    return (
        <div id={'id-start'}>
            {isSmall ? null :
                <BannerTop theme={theme} chooseSalon={true} client={client} logged={client == false ? false : true} alias={alias} salon={salon} logedIn={logedIn} language={language} func={BannerTopPress.bind(this)} />
            }
            <NavigationMenu theme={theme} size={() => GetSize()} client={client} logged={client == false ? false : true} showLanguage={false} language={language} logedin={logedIn} alias={alias} salon={salon} index={0} quantity={cardQuantity} products={productNames} logo={true} />
            <CheckToken alias={alias} func={TokenResult.bind(this)} />

            {settings != false ?
                settings.banners != false ?
                    <BannerSliderMyPage banners={settings.banners} />
                    :
                    <BannerImage typ={1} language={language} source={settings == null ? '' : GetBanner()} />
                :
                <BannerImage typ={1} language={language} source={settings == null ? '' : GetBanner()} />
            }

            {salon != false ?
                <div style={{ marginTop: 20, marginBottom: 20, padding: 10 }}>
                    <h2 style={{ margin: 0 }}>{salon.salon_name}</h2>
                    <MyLine />
                    <p style={{ margin: 0, color: global.theme_dark_gray, fontFamily: 'roboto' }}>{lang.ie_text1}</p>
                    <p style={{ margin: 0, fontSize: 20, fontFamily: 'roboto', color: global.theme_black, fontWeight: 'normal' }}>{settings != null ? settings.label : ''}</p>
                </div>
                : null}

            {/* OZNAM */}
            {settings != false ? settings.info.trim() != '' ?
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', padding: 20, maxWidth: global.screen_max, width: '100%', backgroundColor: global.theme_white }}>
                        <p style={{ fontSize: 17, fontFamily: 'roboto', color: global.theme_black, fontWeight: 'bold' }}>{settings != null ? settings.info : ''}</p>
                    </div>
                </div>
                : null : null}

            {/* TEXT */}
            {settings != false ? settings.text.trim() != '' ?
                <div style={{ ...styles.Block, backgroundColor: global.theme_gray }}>
                    <div style={{ ...styles.Block, width: '96%', paddingTop: 20, paddingBottom: 20, maxWidth: global.screen_max }}>
                        <div style={{ ...styles.TextSmall, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: settings != null ? settings.text : '' }} />
                    </div>
                </div>
                : null : null}


            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold', padding: 20, maxWidth: global.screen_max, backgroundColor: global.theme_white }}>
                    <p>{lang.ie_text2}</p>
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: isSmall == true ? 'column' : 'row' }}>
                    <div style={{ marginRight: 10, marginLeft: 10 }}>
                        <a onClick={() => GotoFace()}>
                            <img src={img_face} width={150} style={{ alignSelf: 'center' }} />
                        </a>
                        <p style={{ margin: 0 }}>{lang.service_face}</p>
                    </div>
                    <div style={{ marginRight: 10, marginLeft: 10 }}>
                        <a onClick={() => GotoBody()}>
                            <img src={img_body} width={150} style={{ alignSelf: 'center' }} />
                        </a>
                        <p style={{ margin: 0 }}>{lang.service_body}</p>
                    </div>
                    <div style={{ marginRight: 10, marginLeft: 10 }}>
                        <a onClick={() => GotoSummer()}>
                            <img src={img_hands} width={150} style={{ alignSelf: 'center' }} />
                        </a>
                        <p style={{ margin: 0 }}>{lang.service_hands}</p>
                    </div>

                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', fontWeight: 'normal', padding: 20, maxWidth: global.screen_max, backgroundColor: global.theme_white }}>
                    <p>{lang.ie_text3}</p>
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: isSmall == true ? 'column' : 'row' }}>
                    <img src={img_service1} width={150} style={{ alignSelf: 'center' }} />
                    <div style={{ padding: 20 }} >
                        <ul style={{ display: 'flex', flexDirection: 'column', height: 120, justifyContent: 'center', alignItems: 'flex-start' }}>
                            <li>{lang.service_1}</li>
                            <li>{lang.service_2}</li>
                            <li>{lang.service_3}</li>
                            <li>{lang.service_4}</li>
                            <li>{lang.service_5}</li>
                        </ul>
                    </div>
                </div>
            </div>

            {salon.eshop == true &&
                <div onClick={() => GotoEshop()} style={{ ...styles.Block, backgroundColor: global.theme_gray }}>
                    <img src={img_eshop} width={300} style={{ alignSelf: 'center' }} />
                    <p style={{ ...styles.TextXXLarge, letterSpacing: 1.5, fontWeight: '600' }}>{lang.eshop}</p>
                    <p style={{ ...styles.TextSmall }}>{lang.eshop_text}</p>
                </div>
            }
            <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: global.theme_gray }}>
                <div style={{ display: 'flex', justifyContent: 'center', fontWeight: 'normal', padding: 20, maxWidth: 900 }}>
                    <p>{lang.ie_text4}</p>
                </div>
            </div>

            {showCookies == true ?
                <CookiesInfo lang={lang} salon={salon} func={CookiesInfoPress.bind(this)} />
                : null}
            <BottomMenu language={language} salon={salon} />
            <Footer language={language} islogged={logedIn} />

            {isBusy == true ?
                <Loading></Loading>
                : null}
        </div >
    )

}
