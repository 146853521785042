import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
//import logo from '/react_images/logo.png';
import './react/App.css';
import './react/w3.css';
import { Button, IconButton, Select, Autocomplete } from '@mui/material';
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import "animate.css/animate.min.css";

import { ChangeLanguageURL, LoadAlias, dbURL, SaveAlias, SaveClient, SaveLanguage, SaveLogedIn, SavePublic, SaveSalon, SaveSalonSettings, Verify, Debug, GetSize, BannerBlack, BannerImage, Footer, NeedRedraw, SaveTime, GetLanguage, Loading, MyLine, LoadCookies, CookiesInfo, SaveCookies, LoadLogedIn, GetImageLink, LoadToken, Init, CheckToken } from './Items';
import { BottomMenu, BannerTop } from './Items';
import TextField from '@mui/material/TextField';
import { Alert } from '@mui/material';
import { styles } from './styles';


export const Login = () => {
    const navigate = useNavigate();
    const [language, setLanguage] = useState(ChangeLanguageURL());
    const [alias, setAlias] = useState('');
    const [client, setClient] = useState(false);
    const [town, setTown] = useState('');
    const [distance, setDistance] = useState(30);
    const [salons, setSalons] = useState([]);
    const [towns, setTowns] = useState([]);
    const [townErr, setTownErr] = useState('');
    const [showSalons, setShowSalons] = useState(false);
    const [isBusy, setBusy] = useState(false);
    const [showCookies, setShowCookies] = useState(false);
    const [banner, setBanner] = useState(false);
    const [theme, setTheme] = useState(0);

    const salonsRef = useRef(null)
    var lang = GetLanguage(language);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });


    const logo_ie = require('./react/logo_ie.png');
    const logo_circle = require('./react/logo.png');
    const salon_photo = require('./react/salon_photo_2.jpg');
    const background = require('./react/background.jpg');

    useEffect(() => {

        Debug('PAGE LOGIN STARTED');

        var token = LoadToken();

        if (token == false) {
            db_towns();
        }

        // COOKIES - ak su odsuhlasene - vypnut
        if (LoadCookies() == null) {
            setShowCookies(true);
        }

        return () => {
        };

    }, []);

    const db_towns = async () => {
        setBusy(true);

        // zistenie jazyka z koncovky stránky
        var l = ChangeLanguageURL();
        try {
            const response = await fetch(
                dbURL() + 'towns', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: l,
                })
            })

            const json = await response.json();
            setBusy(false);
            if (json.ok > 0) {
                setTowns(json.towns);
                setBanner(json.banner);
            } else {
            }

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    };

    const db_town = async () => {
        if (isBusy == false) {
            setBusy(true);

            try {
                const response = await fetch(
                    dbURL() + 'town2', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        language: language,
                        town, town,
                        distance: distance
                    })
                })

                const json = await response.json();

                setBusy(false);
                if (json.ok > 0) {
                    if (json.salons != null) {
                        var tmp = json.salons;

                        tmp.sort((a, b) => {
                            if (parseInt(a.rating) > parseInt(b.rating)) {
                                return true;
                            } else {
                                return false;
                            }
                        })

                        tmp.sort((a, b) => {
                            if (a.km > b.km) {
                                return true;
                            } else {
                                return false;
                            }
                        })

                        setSalons(tmp);
                        setShowSalons(true);
                        salonsRef.current.scrollIntoView({ behavior: 'smooth' });
                    } else {
                        setTownErr(lang.town_error);
                    }
                } else {
                }

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    };

    const TokenResult = (typ, json) => {
        if (typ == true) {
            Debug('REDIRECT TO ' + json.salon.alias);
            navigate('/' + json.salon.alias + '/home');
        } else {
            setBusy(false);
        }
    }

    const BannerTopPress = (value, token_) => {
        if (value == 1) {
            // prihlásiť sa ...
            //db_token(token_);
            window.location.reload();
        }
    }

    const Search = () => {
        if (town.trim() != '') {
            setTownErr('');
            db_town();
        }
    }

    const distanceChange = (event) => {
        setDistance(event.target.value);
    };

    const SalonSelect = (alias) => {
        if (alias != null) {
            if (alias.trim() != '') {
                navigate('/' + alias);
            }
        }
    }

    const CookiesInfoPress = (value) => {
        setShowCookies(false);
        SaveCookies(true);
    }

    return (
        <div>
            <BannerTop theme={theme} chooseSalon={false} client={client} logged={client == false ? false : true} salon={-1} language={language} func={BannerTopPress.bind(this)} />
            <BannerBlack theme={theme} language={language} />
            <CheckToken func={TokenResult.bind(this)} />

            <div>
                <div style={{ display: 'flex', backgroundColor: global.theme_blue, padding: 10, justifyContent: 'center' }}>
                    <h5 style={{ color: global.theme_white }}>{lang.choose_salon}</h5>
                </div>

                {banner != false ?
                    <BannerImage typ={0} file={banner} />
                    : null}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: 40, paddingBottom: 40, backgroundImage: `url(${background})`, backgroundSize: 'cover' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <h4>{lang.salon_search_label}</h4>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 10 }}>
                        <img src={logo_ie} width={150} style={{ alignSelf: 'center' }} />
                    </div>
                    <h4>{lang.salon_search_label_2}</h4>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: isLarge == true ? 350 : 300, padding: 30, backgroundColor: global.theme_white, borderRadius: 20, alignItems: 'center', border: '2px solid ' + global.theme_dark }}>
                        <div style={{ marginTop: 0, paddingBottom: 20 }}>
                            <img src={logo_circle} height={80} />
                        </div>
                        <Autocomplete
                            sx={{ width: isLarge == true ? 250 : 200 }}
                            freeSolo
                            disableClearable
                            options={towns.map((option) => option)}
                            onChange={(event, value) => { setTown(value) }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={lang.your_town}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                    /*
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            SearchProccess();
                                        }
                                    }}
                                    */
                                    value={town}
                                    onInput={e => setTown(e.target.value)}
                                />
                            )}
                        />

                        {townErr != '' ?
                            <Alert severity="error">{townErr}</Alert>
                            : null}
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 }}>
                            <p style={{ margin: 0 }}>{lang.distance}</p>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Select
                                    style={{ width: 100 }}
                                    value={distance}
                                    label={lang.distance}
                                    onChange={distanceChange}
                                >
                                    <MenuItem value={30}>30 km</MenuItem>
                                    <MenuItem value={50}>50 km</MenuItem>
                                    <MenuItem value={100}>100 km</MenuItem>
                                </Select>
                            </div>
                        </div>
                        <Button onClick={() => Search()} style={{ backgroundColor: global.theme_black, width: 250 }} variant="contained">{lang.search_salons}</Button>
                    </div>
                </div>
            </div>



            <div ref={salonsRef} style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_white, justifyContent: 'center' }}>
                <div>
                    {showSalons == true ?
                        <div style={{ padding: 20 }}>

                            <h3>{lang.salons_searched}</h3>
                            <MyLine />
                            {salons.length == 0 ?
                                <p style={{ margin: 0, color: global.theme_dark_gray }}>{lang.search_salons_none}</p>
                                :
                                <p style={{ margin: 0, color: global.theme_dark_gray }}>{lang.salons_count}: {salons.length}</p>
                            }
                        </div>
                        : null}
                    <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: GetSize() == 0 ? 'column' : 'row', justifyContent: 'center' }}>
                        {
                            salons.map(item => {
                                return (
                                    <div key={item.id} style={{ display: 'flex', flexDirection: 'column', width: isLarge == true ? 350 : 300, alignSelf: 'center' }}>
                                        <a onClick={() => SalonSelect(item.alias)} style={{ padding: 2 }} >
                                            <div style={{ backgroundColor: global.theme_gray, padding: 10, paddingTop: 20, paddingBottom: 20 }}>
                                                <img src={item.image != '' ? GetImageLink(item.image) : salon_photo} width={300} />
                                                <p style={{ fontSize: 14, fontWeight: 'bold', color: global.theme_black }}>{item.salon_name}</p>
                                                <p style={{ fontSize: 14, fontWeight: 'normal', color: global.theme_black, margin: 0 }}>{item.salon_town}</p>
                                                <p style={{ fontSize: 12, fontWeight: 'normal', color: global.theme_black, margin: 0 }}>{item.salon_street} {item.salon_street_number}</p>
                                            </div>
                                        </a>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {showSalons == true ?
                        <div style={{ height: 30 }}></div>
                        : null}
                </div>
            </div>

            {showCookies == true ?
                <CookiesInfo lang={lang} salon={{id:2}} func={CookiesInfoPress.bind(this)} />
                : null}
            {/*
            <BottomMenu language={language} />
            */}
            <Footer language={language} />

            {isBusy == true ?
                <Loading />
                : null}
        </div>
    )
}
