import React, { useEffect, useState } from 'react';
import './react/App.css';
import './react/w3.css';
import Button from '@mui/material/Button';
import { Routes, Route, Link, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import "animate.css/animate.min.css";
import { GetSize, NavigationMenu, Footer, Debug, LoadSalon, SaveSalonSettings, LoadSalonSettings, LoadLogedIn, LoadLanguage, LoadAlias, Verify, SaveLanguage, ChangeLanguage, ChangeLanguageURL, Loading, LoadClient, NeedRedraw, SaveSalon, dbURL, SaveTime, BottomMenu, BannerImage, BannerSlider, LoadPublic, PaginationBar, ProductCard, Ordering, EshopMenu, SaveProducts, SaveSections, SaveCategories, LoadProducts, LoadSections, LoadCategories, EncodeValue, SaveProductsTime, NeedRedrawProducts, LoadCardQuantity, SaveCardQuantity, LoadCookies, CookiesInfo, SaveCookies, EshopBusy, LoadToken, GetImageLink, RemoveScroll, SavePage, SaveScroll, DialogLogin, CheckToken } from './Items.js';
import { BannerTop, LoadProductNames, SaveProductNames, GetLanguage } from './Items.js';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme2-6.css";
import { styles } from './styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export const Eshop = (props) => {

    const navigate = useNavigate();
    let params = useParams();

    const [isBusy, setBusy] = useState(true);
    const [banners, setBanners] = useState([]);
    const [salon, setSalon] = useState([]);
    const [settings, setSettings] = useState(null);
    const [language, setLanguage] = useState(ChangeLanguageURL());
    const [alias, setAlias] = useState(params.alias);
    const [client, setClient] = useState([]);
    const [productNames, setProductNames] = useState([]);
    const [logedIn, setLogedIn] = useState(false);
    const [screen, setScreen] = useState(0);
    const [showCookies, setShowCookies] = useState(false);

    const [cardQuantity, setCardQuantity] = useState(0);
    const [products, setProducts] = useState([]);
    const [productsFiltered, setProductsFiltered] = useState([]);
    const [productsNew, setProductsNew] = useState(false);
    const [sections, setSections] = useState(false);
    const [category, setCategory] = useState([]);

    const [theme, setTheme] = useState(0);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const img_summer = require('./react/eshop_leto.jpg');
    const logo_black = require('./react/logo_ie.png');

    var lang = GetLanguage(language);
    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            if (LoadCookies() == null) {
                setShowCookies(true);
            }

            const element = document.getElementById('id-start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }

        return () => {
        };

    }, []);

    /* *******************************************************************************
        NAHRATIE PRODUKTOV
    ******************************************************************************* */
    const db_products = async (salon_id, client_id) => {
        setBusy(true);
        var token = LoadToken();
        try {
            const response = await fetch(
                dbURL() + 'products_v2', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: salon_id,
                    client_id: client_id,
                    token: token,                    
                    action: lang.action,
                    others: global.my_products == true ? lang.products_others : false
                })
            })

            const json = await response.json();
            setBusy(false);
            if (json.ok > 0) {
                var tmp = json.products;
                Debug('PRODUCTs', tmp);
                // *************************************************
                // PRODUKTY
                // *************************************************
                setProducts(tmp);
                SaveProducts(tmp);
                setProductsFiltered(tmp);

                tmp = json.products;
                tmp = tmp.filter(x => x.flag_id == 2);
                tmp = tmp.map(function (item, index) {
                    item.index = index;
                    return item;
                });
                if (tmp.length == 0) {
                    setProductsNew(false);
                } else {
                    setProductsNew(tmp);
                }

                // *************************************************
                // SECTIONS
                // *************************************************
                setSections(json.sections);

                // *************************************************
                // CATEGORIES
                // *************************************************
                setCategory(json.categories);

                // *************************************************
                // POCET POLOZIEK V KOSIKU
                // *************************************************
                setCardQuantity(json.quantity);

                // *************************************************
                // BANNERY
                // *************************************************
                setBanners(json.banners);
            }
        } catch (error) {
            setBusy(false);
            Debug(error);
        }

    };

    const TokenResult = (typ, json) => {

        if (typ == true) {
            setSalon(json.salon);
            setAlias(json.salon.alias);
            setSettings(json.settings);
            setLanguage(json.salon.language);
            setClient(json.client);
            setLogedIn(json.logged);

            db_products(json.salon.id, json.client == false ? 0 : json.client.id);
        } else {
            navigate('/logout');
        }
    }

    const BannerTopPress = (value, token_) => {
        if (value == 1) {
            // PRIHLASENIE SA UZIVATELA -> presmerovanie na jeho salon
            SavePage(0);
            SaveScroll(0);
            //db_token(token_);
            window.location.reload();
        }

        // AKCIA Z BANNERU
        if (value == 2) {
            // ODHLASIT SA
            navigate('/logout');
        }
        if (value == 3) {
            // Moj ucet
            navigate('/' + alias + '/account');
        }
    }


    const ChangeSection = (slug) => {
        SaveScroll(0);
        SavePage(0);
        navigate('/' + alias + '/eshop/category/' + slug);
    }

    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: global.theme_white, width: 30, height: 30 }}
                onClick={onClick}
            >
                <FontAwesomeIcon style={{ color: global.theme_black, fontSize: 30 }} icon={faChevronRight} />
            </div>
        );
    }

    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: global.theme_white, width: 30, height: 30 }}
                onClick={onClick}
            >
                <FontAwesomeIcon style={{ color: global.theme_black, fontSize: 30 }} icon={faChevronLeft} />
            </div>
        );
    }

    const ProductCardPress = (item) => {
        SaveScroll(0);
        SavePage(0);
        navigate("/" + alias + '/eshop/' + item.slug);
    }

    const sliderSettings = {
        dots: false,
        infinite: true,
        slidesToShow: isSmall == true ? 1 : isMedium ? 2 : 4,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    }

    const CookiesInfoPress = (value) => {
        setShowCookies(false);
        SaveCookies(true);
    }


    return (
        <div id={'id-start'}>
            {isSmall ? null :
                <BannerTop theme={theme} chooseSalon={true} client={client} logged={client == false ? false : true} alias={alias} salon={salon} logedIn={logedIn} language={language} func={BannerTopPress.bind(this)} />
            }
            <NavigationMenu theme={theme} size={() => GetSize()} client={client} logged={client == false ? false : true} showLanguage={false} language={language} logedin={logedIn} alias={alias} salon={salon} index={1} quantity={cardQuantity} products={productNames} logo={true} />
            <CheckToken alias={alias} func={TokenResult.bind(this)} />

            <div style={{ ...styles.Block, paddingBottom: 20, paddingTop: 20 }}>
                <img src={logo_black} height={56} />
            </div>

            {
                // ************************************************************************
                // HLAVNA OBRAZOVKA - SKUPINY
                // ************************************************************************
            }
            <div style={{ ...styles.BlockCenter, minHeight: height }}>
                {/* UDRZBA ESHOPU */}
                {salon != false ? salon.eshop_ready == false ?
                    <EshopBusy lang={lang} />
                    : null : null}

                {/* HLAVNY BANNER */}
                <BannerSlider language={language} banners={banners} />

                <div style={{ ...styles.Block }}>
                    <p style={{ ...styles.TextXLarge, fontWeight: '600' }}>{lang.eshop}</p>
                    <p style={{ ...styles.TextLarge }}>{lang.category_products}</p>
                </div>

                {/* PRODUKTOVE SKUPINY */}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: isLarge == true ? 'row' : 'column', padding: 20, maxWidth: 320 }}>
                        {sections != false ? sections.map((item, index) => (
                            <div key={item.id} style={{ margin: 10 }}>
                                <Button onClick={() => ChangeSection(item.slug)} style={{ flexDirection: 'column' }}>
                                    <img src={item.image != false ? GetImageLink(item.image) : img_summer} style={{ width: 250, height: 250, borderRadius: 20 }} />
                                    <p style={{...styles.TextTiny}}>{item.label}</p>
                                </Button>
                            </div>
                        )) : null}
                    </div>
                </div>

                {/* NOVE PRODUKTY V ESHOPE */}
                {productsNew != false ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, marginBottom: 30 }}>
                        <div style={{ padding: 10, maxWidth: isSmall == true ? 240 : isMedium ? 500 : global.screen_max }}>
                            <h3>{lang.eshop_new_products}</h3>
                            <Slider  {...sliderSettings}>
                                {productsNew != false ? productsNew.length > 4 ? productsNew.map(item => {
                                    return (
                                        <div key={item.id} >
                                            <ProductCard ready={salon.eshop_ready} size={200} language={language} item={item} func={ProductCardPress.bind(this)} />
                                        </div>
                                    )
                                }) : null : null}

                            </Slider>
                        </div>
                    </div >
                    : null}

                {/* VERNOSTNY SYSTEM */}
                <BannerImage typ={3} language={language} />
            </div>

            {
                showCookies == true ?
                    <CookiesInfo lang={lang} salon={salon} func={CookiesInfoPress.bind(this)} />
                    : null
            }
            <BottomMenu language={language} salon={salon}/>
            <Footer language={language} islogged={logedIn} />

            {isBusy == true ?
                <Loading></Loading>
                : null}
        </div >
    )
}
