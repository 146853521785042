import React, { useEffect, useState } from 'react';
import './react/App.css';
import './react/w3.css';
import Button from '@mui/material/Button';
import { Routes, Route, Link, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import "animate.css/animate.min.css";
import { GetSize, NavigationMenu, Footer, Debug, LoadSalon, SaveSalonSettings, LoadSalonSettings, LoadLogedIn, LoadLanguage, LoadAlias, Verify, SaveLanguage, ChangeLanguage, ChangeLanguageURL, Loading, LoadClient, NeedRedraw, SaveSalon, dbURL, SaveTime, BottomMenu, BannerImage, BannerSlider, LoadPublic, PaginationBar, ProductCard, Ordering, EshopMenu, SaveProducts, SaveSections, SaveCategories, LoadSections, LoadCategories, LoadProducts, EncodeValue, DecodeValue, NeedRedrawProducts, SaveProductsTime, SaveOrderTyp, LoadOrderTyp, LoadCardQuantity, SaveCardQuantity, LoadCookies, CookiesInfo, SaveCookies, EshopBusy, LoadToken, SavePage, LoadPage, SaveScroll, LoadScroll, RemoveScroll, CheckToken } from './Items.js';
import { BannerTop, LoadProductNames, SaveProductNames, GetLanguage } from './Items.js';
import { Backdrop, Breadcrumbs, IconButton, Paper } from '@mui/material';
import { styles } from './styles';
import { faFrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Category = (props) => {
    const navigate = useNavigate();
    let params = useParams();

    const [isBusy, setBusy] = useState(true);
    const [salon, setSalon] = useState(false);
    const [settings, setSettings] = useState(false);
    const [theme, setTheme] = useState(0);
    const [language, setLanguage] = useState(ChangeLanguageURL());
    const [alias, setAlias] = useState(params.alias);
    const [client, setClient] = useState(false);
    const [productNames, setProductNames] = useState([]);
    const [logedIn, setLogedIn] = useState(false);
    const [showCookies, setShowCookies] = useState(false);

    const [cardQuantity, setCardQuantity] = useState(0);
    const [products, setProducts] = useState(false);
    const [productsFiltered, setProductsFiltered] = useState(false);
    const [productsNew, setProductsNew] = useState(false);

    // SEKCIE
    const [sections, setSections] = useState(false);
    const [sectionID, setSectionID] = useState(0);
    const [sectionLabel, setSectionLabel] = useState('');

    //KATEGORIE
    const [categories, setCategories] = useState(false);
    const [categoryID, setCategoryID] = useState(0);
    const [categoryLabel, setCategoryLabel] = useState('');

    const [search, setSearch] = useState('');
    const [orderTyp, setOrderTyp] = useState(0);
    const [page, setPage] = useState(0);
    const [showMore, setShowMore] = useState(false);
    const [productsPage, setProductsPage] = useState(0);
    const [productsMax, setProductsMax] = useState(0);
    const [product, setProduct] = useState([]);

    const [showAdded, setShowAdded] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // Maximálna šírka obrazovky
    const maxWidth = width < global.screen_max ? width : global.screen_max;

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small }) || isMedium;

    const logo_circle = require('./react/logo.png');
    const logo_black = require('./react/logo_ie.png');

    var lang = GetLanguage(language);
    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            var order_typ = LoadOrderTyp();
            if (order_typ != false) {
                setOrderTyp(order_typ);
            }

            if (LoadCookies() == null) {
                setShowCookies(true);
            }
        }

        return () => {
        };

    }, []);


    const TokenResult = (typ, json) => {
        if (typ == true) {
            setSalon(json.salon);
            setAlias(json.salon.alias);
            setSettings(json.settings);
            setLanguage(json.salon.language);
            setClient(json.client);
            setLogedIn(json.logged);

            db_products(json.salon.id, json.client == false ? 0 : json.client.id);
        } else {
            navigate('/logout');
        }
    }

    /* *******************************************************************************
        NAHRATIE PRODUKTOV
    ******************************************************************************* */
    const db_products = async (salon_id, client_id) => {
        var token = LoadToken();

        setBusy(true);
        try {
            const response = await fetch(
                dbURL() + 'products_v2', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: salon_id,
                    client_id: client_id,
                    token: token,                    
                    action: lang.action,
                    others: global.my_products == true ? lang.products_others : false
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var section_slug = params.section;
                var category_slug = false;
                if (params.section != undefined) {
                    category_slug = params.category;
                }

                // *************************************************
                // PRODUKTY
                // *************************************************
                var tmp = json.products;
                var products_ = json.products;
                setProducts(tmp);
                SaveProducts(tmp);
                setProductsFiltered(tmp);

                tmp = json.products;
                tmp = tmp.filter(x => x.flag_id == 2);
                tmp = tmp.map(function (item, index) {
                    item.index = index;
                    return item;
                });

                setProductsNew(tmp);

                // *************************************************
                // SECTIONS
                // *************************************************
                var tmp = json.sections;
                tmp.sort((a, b) => {
                    if (parseInt(a.priority) > parseInt(b.priority)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
                setSections(tmp);

                var section_id = 0;

                var tmp = json.sections.find(item => item.slug == section_slug);
                if (tmp != undefined) {
                    setSectionID(tmp.id);
                    setSectionLabel(tmp.label);

                    section_id = tmp.id;
                }

                // *************************************************
                // CATEGORIES
                // *************************************************
                var tmp = json.categories;
                setCategories(tmp);
                setCategoryLabel('');

                var category_id = 0;

                if (category_slug != false) {
                    var tmp = json.categories.find(item => item.slug == category_slug);

                    if (tmp != undefined) {
                        category_id = tmp.id;
                        setCategoryID(category_id);
                        setCategoryLabel(tmp.label);
                    }
                }

                // *************************************************
                // POCET POLOZIEK V KOSIKU
                // *************************************************
                setCardQuantity(json.quantity);

                // *************************************************
                // ZOBRAZENIE PRODUKTOV
                // *************************************************
                var page_ = LoadPage();
                if (page_ == false) {
                    page_ = 0;
                }
                setPage(page_);

                var order_typ = LoadOrderTyp();
                if (order_typ == false) {
                    order_typ = 0;
                }
                RedrawProducts(page_, section_id, category_id, '', order_typ, products_);

            }
            setBusy(false);

        } catch (error) {
            setBusy(false);
            Debug(error);
        }

    };


    /* *******************************************************************************
        PRIDANIE PRODUKTU DO KOSIKA
    ******************************************************************************* */
    const db_product_buy = async (item) => {
        if (isBusy == false) {
            setBusy(true);

            var token = LoadToken();

            try {
                const response = await fetch(
                    dbURL() + 'product_buy', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        salon_id: salon.id,
                        client_id: client != false ? client.id : 0,
                        public_key: token,                        
                        product_id: item.id,
                        quantity: 1
                    })
                })

                const json = await response.json();
                setBusy(false);
                if (json.ok > 0) {
                    setCardQuantity(json.quantity);
                    SaveCardQuantity(json.quantity);
                    setShowAdded(true);
                }

            } catch (error) {
                setBusy(false);
                Debug(error);
            }
        }
    };


    const BannerTopPress = (value, token_) => {
        if (value == 1) {
            // PRIHLASENIE SA UZIVATELA -> presmerovanie na jeho salon
            SavePage(0);
            SaveScroll(0);
            //db_token(token_);
            window.location.reload();
        }

        // AKCIA Z BANNERU
        if (value == 2) {
            // ODHLASIT SA
            navigate('/logout');
        }
        if (value == 3) {
            // Moj ucet
            navigate('/' + alias + '/account');
        }
    }

    const ChangeScreen = (screen_) => {

        if (screen_ == 0) {
            navigate('/' + alias + '/eshop');
        }
    }

    const ChangeSection = (section_id) => {

        if (section_id == -1) {
            // AKCIA
            setSectionID(-1);
            setSectionLabel(lang.action);

            setCategoryID(0);
            setCategoryLabel('');

            var tmp = products.filter(item => parseInt(item.action) == 1 && item.brand_id == 1);
            setProductsFiltered(tmp);

            navigate('/' + alias + '/eshop/category/action');

        } else {
            // SEKCIE
            var section_ = sections.find(item => item.id == section_id);

            if (section_ != undefined) {
                setSectionID(section_id);
                setSectionLabel(section_.label);

                setCategoryID(0);
                setCategoryLabel('');

                var tmp = products.filter(item => parseInt(item.section_id) == section_id);
                setProductsFiltered(tmp);

                navigate('/' + alias + '/eshop/category/' + section_.slug);
            }
        }


        setPage(0);
        SavePage(0);
        SaveScroll(0);

        RedrawProducts(0, section_id, 0, search, orderTyp, tmp);
    }


    const ChangeCategory = (section_id, category_id) => {
        if (category_id != categoryID) {
            var section_ = sections.find(item => item.id == section_id);
            Debug(section_);
            if (section_ != undefined) {
                setSectionID(section_id);
                setSectionLabel(section_.label);
                
                var categories_ = categories.find(item => item.id == category_id);
                Debug(categories);
                Debug(categories_);
                if (categories_ != undefined) {
                    setCategoryID(category_id);
                    setCategoryLabel(categories_.label);

                    var products_ = products.filter(item => item.category_id == category_id);
                    setProductsFiltered(products_);

                    setPage(0);
                    SavePage(0);
                    SaveScroll(0);
                    RedrawProducts(0, sectionID, category_id, search, orderTyp);

                    navigate('/' + alias + '/eshop/category/' + section_.slug + '/' + categories_.slug);
                }
            }
        }
    }


    const ShowMore = () => {
        var page_ = page;
        if (page_ * global.items_max < (productsMax - global.items_max)) {
            page_ += 1;
        }

        setPage(page_);
        SavePage(page_);
        RemoveScroll();

        RedrawProducts(page_, sectionID, categoryID, search, orderTyp);
    }


    const RedrawProducts = (page_, sectionID_, categoryID_, search_, order_, products_) => {
        var data = products;
        if (products_ != undefined) {
            data = products_;
        }

        if (categoryID_ == 0) {
            // TRIEDENIE PODLA SECTIONS
            if (sectionID_ == -1) {
                data = data.filter(item => item.action == true && item.brand_id == 1);
            } else {
                data = data.filter(item => item.section_id == sectionID_);
            }
            setProductsMax(data.length);
            var maxItems = data.length;
        } else {
            // TRIEDENIE PODLA CATEGORY
            data = data.filter(item => item.category_id == categoryID_);
            setProductsMax(data.length);
            var maxItems = data.length;
        }

        // ZORADENIE PODLA NAZVU
        if (order_ == global.order_by_name) {
            data.sort((a, b) => {
                if (a.label > b.label) {
                    return true;
                } else {
                    return false;
                }
            })
        }

        // ZORADENIE PODLA CENY OD NAJNIZSEJ        
        if (order_ == global.order_by_price_low) {
            data.sort((a, b) => {
                if (parseFloat(a.eur) > parseFloat(b.eur)) {
                    return true;
                } else {
                    return false;
                }
            })
        }

        // ZORADENIE PODLA CENY OD NAJVYSSEJ 
        if (order_ == global.order_by_price_high) {
            data.sort((a, b) => {
                if (parseFloat(a.eur) < parseFloat(b.eur)) {
                    return true;
                } else {
                    return false;
                }
            })
        }

        // reindexing
        data = data.map(function (item, index) {
            item.index = index;
            return item;
        });

        // PAGINATE DATA
        //data = data.slice(global.items_max * page_, (global.items_max * page_) + global.items_max)
        data = data.slice(0, (global.items_max * page_) + global.items_max);
        setProductsFiltered(data);

        if (((page_ * global.items_max) + global.items_max) < maxItems) {
            setShowMore(true);
        } else {
            setShowMore(false);
        }

        GotoProduct();
    }


    const ProductCardPress = (item, value) => {
        //setProduct(item);
        if (value == 0) {
            SaveScroll(item.id);
            navigate("/" + alias + '/eshop/' + item.slug);
        }
        if (value == 1) {
            setProduct(item);
            db_product_buy(item);
        }

    }


    const OrderingPress = (value) => {
        setOrderTyp(value);
        SaveOrderTyp(value);
        RedrawProducts(0, sectionID, categoryID, search, value);
    }


    const EshopMenuPress = (section_id, category_id) => {
        Debug(section_id);
        Debug(category_id);
        if (category_id == 0) {
            // ZMENA LEN SEKCIE
            ChangeSection(section_id);
        } else {
            ChangeCategory(section_id, category_id);
        }
    }

    const CookiesInfoPress = (value) => {
        setShowCookies(false);
        SaveCookies(true);
    }

    const CloseAdded = () => {
        setShowAdded(false);
    }

    const GotoCard = () => {
        navigate("/" + alias + '/card');
    }

    const GotoProduct = () => {
        var product_id = LoadScroll();
        if (product_id != null) {

            setTimeout(() => {
                const element = document.getElementById('id-product-' + product_id);

                if (element != null) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 250);
        }
    }



    return (
        <div id={'id-product-0'}>
            {isSmall ? null :
                <BannerTop theme={theme} chooseSalon={true} client={client} logged={client == false ? false : true} alias={alias} salon={salon} logedIn={logedIn} language={language} func={BannerTopPress.bind(this)} />
            }
            <NavigationMenu theme={theme} size={() => GetSize()} client={client} logged={client == false ? false : true} sectionID={sectionID} categoryID={categoryID} language={language} sections={sections} categories={categories} showLanguage={false} logedin={logedIn} alias={alias} salon={salon} index={10} quantity={cardQuantity} products={productNames} logo={true} func={EshopMenuPress.bind(this)} />
            <CheckToken alias={alias} func={TokenResult.bind(this)} />

            <div style={{ ...styles.Block, paddingBottom: 20, paddingTop: 20 }}>
                <img src={logo_black} height={56} />
            </div>


            {/* UDRZBA ESHOPU */}
            {salon != false ? salon.eshop_ready == false ?
                <EshopBusy lang={lang} />
                : null : null}
            {/* HLAVNY BANNER */}

            {/*
                // ************************************************************************
                // ZOZNAM PRODUKTOV
                // ************************************************************************
            */}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <div style={{ display: 'flex', width: '100%', maxWidth: global.screen_max, justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    {sections != false ?
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', paddingTop: 10, paddingBottom: 0 }}>
                            <Breadcrumbs style={{ height: 30 }}>
                                <IconButton onClick={() => ChangeScreen(0)} component="span">
                                    <img src={logo_circle} width={20} height={20} />
                                </IconButton>
                                <Button onClick={() => ChangeSection(sectionID)} style={{ fontSize: 16, color: global.theme_black, textTransform: 'none', margin: 0, padding: 0 }}>
                                    {sectionLabel}
                                </Button>
                                {categoryLabel != '' ?
                                    <Button style={{ fontSize: 16, color: global.theme_black, textTransform: 'none', margin: 0, padding: 0 }}>
                                        {categoryLabel}
                                    </Button>
                                    : null}

                            </Breadcrumbs>
                            {isSmall ?
                                <div style={{ width: '100%', marginTop: 20 }}>
                                    <EshopMenu isSmall={isSmall} theme={theme} sectionID={sectionID} categoryID={categoryID} language={language} sections={sections} categories={categories} func={EshopMenuPress.bind(this)} />
                                </div>
                                : null}
                        </div>
                        : null}
                </div>

                <div style={{ display: 'flex', width: '100%', maxWidth: isSmall ? 320 : global.screen_max, justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: isSmall ? 'center' : 'flex-start', flexDirection: 'column', paddingTop: 0, paddingBottom: 10 }}>
                        <h2 style={{ fontWeight: 'bold', fontFamily: 'roboto' }}>{sectionLabel}</h2>
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: isSmall ? 'column' : 'row', minHeight: isSmall ? 300 : height, maxWidth: isSmall ? 320 : global.screen_max }}>

                    {/* LAVA STRANA - MENU */}
                    {isSmall == false ?
                        <div style={{ width: global.menu_width }}>
                            <EshopMenu isSmall={isSmall} theme={theme} sectionID={sectionID} categoryID={categoryID} language={language} sections={sections} categories={categories} func={EshopMenuPress.bind(this)} />
                        </div>
                        : null}

                    {/* PRAVA STRANA - PRODUKTY */}
                    <div style={{ ...styles.BlockCenter, width: isSmall ? '100% ' : maxWidth - global.menu_width }}>
                        {productsFiltered.length > 0 ?
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                <Ordering language={language} typ={orderTyp} func={OrderingPress.bind(this)} />
                            </div>
                            : null}

                        <div style={{ display: 'flex', padding: isSmall ? 0 : 10, flexDirection: 'column', marginTop: isSmall ? 10 : 0 }}>
                            < div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
                                {productsFiltered != false ?
                                    productsFiltered.length > 0 ?
                                        productsFiltered.map(item => {
                                            return (
                                                <div key={item.id} id={'id-product-' + item.id}>
                                                    <ProductCard buy={true} ready={salon.eshop_ready} size={isSmall ? 150 : 220} language={language} item={item} func={ProductCardPress.bind(this)} />
                                                </div>
                                            )
                                        })
                                        :
                                        <div style={{ ...styles.Block }}>
                                            {isBusy == false ?
                                                <div style={{ ...styles.Block }}>
                                                    <FontAwesomeIcon style={{ fontSize: 36, color: global.theme_dark_red }} icon={faFrown} />
                                                    <p>{lang.section_no_products}</p>
                                                </div>
                                                : null}
                                        </div>
                                    :
                                    <div style={{ ...styles.Block }}>
                                        {isBusy == false ?
                                            <div style={{ ...styles.Block }}>
                                                <FontAwesomeIcon style={{ fontSize: 36, color: global.theme_dark_red }} icon={faFrown} />
                                                <p>{lang.section_no_products}</p>
                                            </div>
                                            : null}
                                    </div>
                                }
                            </div>

                            {showMore == true ?
                                <div style={{ ...styles.Block }}>
                                    <Button onClick={() => ShowMore()} variant="outlined" style={{ margin: 20, width: 200, color: global.theme_black, borderColor: global.theme_black, borderRadius: 20 }}>{lang.show_more}</Button>
                                </div>
                                : null}
                        </div>

                    </div>
                </div>
            </div>

            {showCookies == true ?
                <CookiesInfo lang={lang} salon={salon} func={CookiesInfoPress.bind(this)} />
                : null}

            <BottomMenu language={language} salon={salon}/>
            <Footer language={language} />


            {/* =============================================================================
                                PRIDANÉ DO KOSIKA
            ============================================================================= */}
            <Backdrop open={showAdded} >
                <Paper style={{ display: 'flex', flexDirection: 'column', width: isSmall ? 300 : 600, borderRadius: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ ...styles.Block, width: 60 }}></div>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', flexDirection: 'column', paddingTop: 10, paddingLeft: 25 }}>
                            <p style={{ ...styles.TextXXLarge }}>{lang.added_to_card}</p>
                        </div>
                        <div style={{ ...styles.Block, width: 60 }}>
                            <IconButton onClick={() => CloseAdded()} style={{ width: 36, height: 36, backgroundColor: global.theme_black }}>
                                <p style={{ ...styles.TextSmall, color: global.theme_white }}>X</p>
                            </IconButton>
                        </div>
                    </div>
                    <div style={{ display: 'flex', padding: 20, flexDirection: 'column', justifyItems: 'center', paddingBottom: 30 }}>
                        <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                            <div style={{ ...styles.BlockCenter }}>
                                <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{product.label}</p>
                                <p style={{ ...styles.TextTiny }}>{product.details}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 10, fontWeight: '600' }}>{'1'} {lang.pieces}</p>
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '100%', padding: 0, justifyContent: 'center', flexDirection: isSmall ? 'column' : 'row', marginTop: 40 }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: isSmall ? '100%' : '50%' }}>
                                <Button onClick={() => CloseAdded()} style={{ backgroundColor: global.theme_white, width: 200, height: 40, textTransform: 'none', color: global.theme_black, borderColor: global.theme_black, borderRadius: 20 }} variant="outlined">{lang.continue_to_shop}</Button>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                                <Button onClick={() => GotoCard()} variant="contained" style={{ color: global.theme_white, backgroundColor: global.theme_black, fontSize: 14, width: 200, height: 40, textTransform: 'none', borderRadius: 20 }}>{lang.continue_to_card}</Button>
                            </div>
                        </div>
                    </div>
                </Paper>
            </Backdrop>

            {isBusy == true ?
                <Loading />
                : null}

        </div>
    )

}
