import React, { useEffect, useState } from 'react';
import './react/App.css';
import './react/w3.css';
import Button from '@mui/material/Button';
import { Routes, Route, Link, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import "animate.css/animate.min.css";
import { styles } from './styles';
import { GetSize, NavigationMenu, Footer, Debug, LoadSalon, SaveSalonSettings, LoadSalonSettings, LoadLogedIn, LoadLanguage, LoadAlias, Verify, SaveLanguage, ChangeLanguage, ChangeLanguageURL, Loading, LoadClient, NeedRedraw, SaveSalon, dbURL, SaveTime, MyLine, LoadCardQuantity, LoadCookies, CookiesInfo, SaveCookies, BottomMenu, LoadToken, SavePage, SaveScroll, CheckToken } from './Items.js';
import { BannerTop, LoadProductNames, SaveProductNames, GetLanguage } from './Items.js';

export const MyServices = (props) => {
    const navigate = useNavigate();
    let params = useParams();

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [isBusy, setBusy] = useState(true);
    const [salon, setSalon] = useState(false);
    const [settings, setSettings] = useState(false);
    const [language, setLanguage] = useState(ChangeLanguageURL());
    const [alias, setAlias] = useState(params.alias);
    const [client, setClient] = useState([]);
    const [productNames, setProductNames] = useState([]);
    const [logedIn, setLogedIn] = useState(false);
    const [showCookies, setShowCookies] = useState(false);
    const [theme, setTheme] = useState(0);

    const [cardQuantity, setCardQuantity] = useState(0);
    const [services, setServices] = useState([]);
    const [servicesFiltered, setServicesFiltered] = useState([]);
    const [menu, setMenu] = useState(0);
    const [showDetails, setShowDetails] = useState(false);
    const [service, setService] = useState([]);


    const img_service = require('./react/service.png');
    const logo_black = require('./react/logo_ie.png'); // 610 x 225, 152 x 56

    var lang = GetLanguage(language);
    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            if (LoadCookies() == null) {
                setShowCookies(true);
            }

            const element = document.getElementById('id-start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }

        return () => {
        };

    }, []);


    const TokenResult = (typ, json) => {
        if (typ == true) {
            setSalon(json.salon);
            setAlias(json.salon.alias);
            setSettings(json.settings);
            setLanguage(json.salon.language);
            setClient(json.client);
            setLogedIn(json.logged);

            db_services(json.salon.id);
        } else {
            navigate('/logout');
        }
    }

    /* *******************************************************************************
            PONUKA SLUZIEB
    ******************************************************************************* */
    const db_services = async (salon_id) => {
        setBusy(true);

        try {
            const response = await fetch(
                dbURL() + 'services', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: salon_id,
                })
            })

            const json = await response.json();
            setBusy(false);
            Debug('SERVICES', json);
            if (json.ok > 0) {
                var tmp = json.services;
                setServices(tmp);

                tmp = tmp.filter(x => x.group_id == 1);

                tmp.sort((a, b) => {
                    if (a.label > b.label) {
                        return true;
                    } else {
                        return false;
                    }
                })

                tmp = tmp.map(function (item, index) {
                    item.index = index;
                    return item;
                });

                setServicesFiltered(tmp);
                setMenu(0);
            }
        } catch (error) {
            setBusy(false);
            Debug(error);
        }

    };


    const BannerTopPress = (value, token_) => {
        if (value == 1) {
            // PRIHLASENIE SA UZIVATELA -> presmerovanie na jeho salon
            SavePage(0);
            SaveScroll(0);
            //db_token(token_);
            window.location.reload();
        }

        // AKCIA Z BANNERU
        if (value == 2) {
            // ODHLASIT SA
            navigate('/logout');
        }
        if (value == 3) {
            // UCET
            navigate('/' + alias + '/account');
        }
    }

    const ServiceSelect = (index) => {
        setService(servicesFiltered[index]);
        setShowDetails(true);
    }

    const Change = (index) => {
        var tmp = services;
        var n = index == 0 ? 1 : 2;
        tmp = tmp.filter(x => x.group_id == (n));

        tmp.sort((a, b) => {
            if (a.label > b.label) {
                return true;
            } else {
                return false;
            }
        })

        tmp = tmp.map(function (item, index) {
            item.index = index;
            return item;
        });

        setServicesFiltered(tmp);
        setMenu(index);
    }


    const CookiesInfoPress = (value) => {
        setShowCookies(false);
        SaveCookies(true);
    }

    return (
        <div id={'id-start'}>
            {isSmall ? null :
                <BannerTop theme={theme} chooseSalon={true} client={client} logged={client == false ? false : true} alias={alias} salon={salon} logedIn={logedIn} language={language} func={BannerTopPress.bind(this)} />
            }
            <NavigationMenu theme={theme} size={() => GetSize()} client={client} logged={client == false ? false : true} showLanguage={false} language={language} logedin={logedIn} alias={alias} salon={salon} index={6} quantity={cardQuantity} products={productNames} />
            <CheckToken alias={alias} func={TokenResult.bind(this)} />

            {showDetails == false ?
                // ZOZNAM SLUZIEB
                <div>
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                        <h2 style={{ margin: 0 }}>{lang.services_offer}</h2>
                        <MyLine />
                        <p style={{ margin: 0, color: global.theme_dark_gray }}>{lang.services_offer_text}</p>
                    </div>

                    {isLarge == true ?
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 20, paddingBottom: 40 }}>
                            <Button onClick={() => Change(0)} style={{ backgroundColor: menu == 0 ? global.theme_lighter : '#00000000', height: 40, width: 250, borderRadius: 20, color: global.theme_dark, textTransform: 'none', fontSize: 16, border: '2px solid ' + global.theme_dark, marginRight: 10 }}>
                                {lang.services_ie}
                            </Button>

                            <Button onClick={() => Change(1)} style={{ backgroundColor: menu == 1 ? global.theme_lighter : '#00000000', height: 40, width: 250, borderRadius: 20, color: global.theme_dark, textTransform: 'none', fontSize: 16, border: '2px solid ' + global.theme_dark, marginLeft: 10 }}>
                                {lang.services_my}
                            </Button>
                        </div>
                        :
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 20, paddingBottom: 40, paddingLeft: 10, paddingRight: 10 }}>
                            <Button onClick={() => Change(0)} style={{ backgroundColor: menu == 0 ? global.theme_lighter : '#00000000', height: 40, width: 250, borderRadius: 20, color: global.theme_dark, textTransform: 'none', fontSize: 14, border: '2px solid ' + global.theme_dark, marginBottom: 10 }}>
                                {lang.services_ie}
                            </Button>

                            <Button onClick={() => Change(1)} style={{ backgroundColor: menu == 1 ? global.theme_lighter : '#00000000', height: 40, width: 250, borderRadius: 20, color: global.theme_dark, textTransform: 'none', fontSize: 14, border: '2px solid ' + global.theme_dark }}>
                                {lang.services_my}
                            </Button>
                        </div>
                    }

                    {menu == 0 ?
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: 20, paddingBottom: 40, paddingLeft: 10, paddingRight: 10, alignItems: 'center' }}>
                            <img src={logo_black} height={56} width={152} />
                            <p>{lang.ie_motto}</p>
                        </div>
                        : null}



                    {salon.public_services == 1 && (isLarge == true || isMedium == true) ?
                        < div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            {
                                servicesFiltered.map(item => {
                                    return (
                                        <div key={item.id} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', padding: 10, width: isLarge == true ? 900 : 600 }}>
                                            {item.group_id == 1 ?
                                                // INSTITUT ESTHEDERM
                                                <a onClick={() => ServiceSelect(item.index)} style={{ padding: 0, display: 'flex', flexDirection: 'row', backgroundColor: global.theme_gray, height: 120, borderRadius: 60 }} >

                                                    <div style={{ display: 'flex', justifyContent: 'center', width: 120, height: 120, padding: 10 }}>
                                                        <img src={img_service} width={100} style={{ width: 100, height: 100, borderRadius: 50 }} />
                                                    </div>

                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: isLarge == true ? 20 : 0, paddingRight: isLarge == true ? 20 : 15, justifyContent: 'center' }}>
                                                        <p style={{ fontSize: 11, fontWeight: 'normal', color: global.theme_dark_gray, margin: 0 }}>{lang.ie}</p>
                                                        <p style={{ fontSize: isLarge == true ? 16 : 14, fontWeight: 'bold', color: global.theme_black, margin: 0, marginBottom: 5 }}>{item.label}</p>
                                                        <p style={{ fontSize: isLarge == true ? 14 : 12, fontWeight: 'normal', color: global.theme_dark_gray, margin: 0, textAlign: 'left' }}>{item.description}</p>
                                                    </div>
                                                </a>
                                                :
                                                // MOJE OSLUZBY
                                                <a onClick={() => ServiceSelect(item.index)} style={{ paddingLeft: 20, paddingRight: 20, display: 'flex', flexDirection: 'row', backgroundColor: global.theme_gray, height: 120, borderRadius: 60 }} >

                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: isLarge == true ? 20 : 0, paddingRight: isLarge == true ? 20 : 15, justifyContent: 'center', width: '100%' }}>
                                                        <p style={{ fontSize: isLarge == true ? 16 : 14, fontWeight: 'bold', color: global.theme_black, margin: 0, marginBottom: 5 }}>{item.label}</p>
                                                        <p style={{ fontSize: isLarge == true ? 14 : 12, fontWeight: 'normal', color: global.theme_dark_gray, margin: 0, textAlign: 'left' }}>{item.description}</p>
                                                    </div>
                                                </a>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        : null}

                    {salon.public_services == 1 && isSmall == true ?
                        < div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            {
                                servicesFiltered.map(item => {
                                    return (
                                        <div key={item.id} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', padding: 10, paddingBottom: 10, width: '100%' }}>
                                            {item.group_id == 1 ?
                                                // INSTITUT ESTHEDERM
                                                <a onClick={() => ServiceSelect(item.index)} style={{ padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: global.theme_gray }} >

                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 120, height: 120, padding: 10 }}>
                                                        <img src={img_service} width={100} style={{ width: 100, height: 100, borderRadius: 50 }} />
                                                    </div>

                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                        <p style={{ fontSize: 11, fontWeight: 'normal', color: global.theme_dark_gray, margin: 0 }}>{lang.ie}</p>
                                                        <p style={{ fontSize: isLarge == true ? 16 : 14, fontWeight: 'bold', color: global.theme_black, margin: 0, marginBottom: 5 }}>{item.label}</p>
                                                        <p style={{ fontSize: isLarge == true ? 14 : 12, fontWeight: 'normal', color: global.theme_dark_gray, margin: 2, textAlign: 'center' }}>{item.description}</p>
                                                    </div>
                                                </a>
                                                :
                                                // MOJE OSLUZBY
                                                <a onClick={() => ServiceSelect(item.index)} style={{ paddingLeft: 20, paddingRight: 20, display: 'flex', flexDirection: 'row', backgroundColor: global.theme_gray, height: 120, borderRadius: 60 }} >

                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: isLarge == true ? 20 : 0, paddingRight: isLarge == true ? 20 : 15, justifyContent: 'center', width: '100%' }}>
                                                        <p style={{ fontSize: isLarge == true ? 16 : 14, fontWeight: 'bold', color: global.theme_black, margin: 0, marginBottom: 5 }}>{item.label}</p>
                                                        <p style={{ fontSize: isLarge == true ? 14 : 12, fontWeight: 'normal', color: global.theme_dark_gray, margin: 0, textAlign: 'left' }}>{item.description}</p>
                                                    </div>
                                                </a>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        : null}

                    <div style={{ height: 20 }}></div>
                </div>
                :
                // DETAIL SLUZBY
                <div>

                    <div>
                        <div style={{ marginTop: 20, marginBottom: 20 }}>
                            <h2 style={{ margin: 10 }}>{service.label}</h2>
                            {service.group_id == 1 ?
                                <div style={{ marginBottom: 10 }}>
                                    <img src={img_service} width={100} style={{ width: 100, height: 100 }} />
                                    <p style={{ margin: 0, color: global.theme_dark_gray }}>{lang.ie}</p>
                                </div>
                                :
                                <p style={{ margin: 0, color: global.theme_dark_gray }}>{lang.services_my}</p>
                            }
                        </div>
                    </div>

                    {service.description.trim() != '' ?
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 20, maxWidth: 900, width: '100%', backgroundColor: global.theme_gray }}>
                                <p style={{ margin: 0, color: global.theme_dark_gray, fontSize: 14 }}>{lang.service_description}:</p>
                                <p>{service.description}</p>
                            </div>
                        </div>
                        : null}


                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 20, maxWidth: 900 }}>
                            {service.time != 0 ?
                                <p>{lang.service_time}: {service.time} {lang.minutes}</p>
                                : null}
                            {
                                salon.public_prices == true ?
                                    service.eur != '' ?
                                        <p>{lang.price}: {service.eur} {lang.money}</p>
                                        : null
                                    : null
                            }
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 20, paddingBottom: 40 }}>
                        <Button onClick={() => setShowDetails(false)} style={{ backgroundColor: global.theme_lighter, height: 40, width: 250, borderRadius: 20, color: global.theme_dark, textTransform: 'none', fontSize: 16, border: '2px solid ' + global.theme_dark, marginRight: 10 }}>
                            {lang.back}
                        </Button>
                    </div>

                </div>
            }

            {showCookies == true ?
                <CookiesInfo lang={lang} salon={salon} func={CookiesInfoPress.bind(this)} />
                : null}

            <BottomMenu language={language} salon={salon}/>
            <Footer language={language} islogged={logedIn} />
        </div >
    )

}
