import React, { useEffect, useState, useRef } from 'react';
import './react/App.css';
import './react/w3.css';
import Button from '@mui/material/Button';
import { Routes, Route, Link, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import "animate.css/animate.min.css";

import { GetSize, NavigationMenu, Footer, Debug, LoadSalon, SaveSalonSettings, LoadSalonSettings, LoadLogedIn, LoadLanguage, LoadAlias, Verify, SaveLanguage, ChangeLanguage, ChangeLanguageURL, Loading, LoadClient, NeedRedraw, SaveSalon, dbURL, SaveTime, LoadProducts, EncodeValue, BottomMenu, LoadCategories, SaveProducts, SaveProductsTime, LoadPublic, SaveSections, SaveCategories, NeedRedrawProducts, ProductCard, SaveCardQuantity, LoadCardQuantity, LoadCookies, CookiesInfo, SaveCookies, EshopBusy, LoadToken, GetImageLink, SavePage, SaveScroll, CheckToken } from './Items.js';
import { BannerTop, LoadProductNames, SaveProductNames, GetLanguage } from './Items.js';
import { Backdrop, Breadcrumbs, IconButton, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faChevronLeft, faChevronRight, faFrown } from '@fortawesome/free-solid-svg-icons';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme2-6.css";
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import { styles } from './styles';


export const Product = (props) => {
    const navigate = useNavigate();
    let params = useParams();

    const [isBusy, setBusy] = useState(true);
    const [salon, setSalon] = useState(false);
    const [settings, setSettings] = useState(false);
    const [language, setLanguage] = useState(ChangeLanguageURL());
    const [alias, setAlias] = useState(params.alias);
    const [client, setClient] = useState(false);
    const [productNames, setProductNames] = useState([]);
    const [logedIn, setLogedIn] = useState(false);
    const [theme, setTheme] = useState(0);

    const [product, setProduct] = useState(false);

    // SEKCIE
    const [section, setSection] = useState(false);
    const [sections, setSections] = useState(false);
    const [sectionID, setSectionID] = useState(0);
    const [sectionLabel, setSectionLabel] = useState('');

    //KATEGORIE
    const [category, setCategory] = useState(false);
    const [categories, setCategories] = useState(false);
    const [categoryID, setCategoryID] = useState(0);
    const [categoryLabel, setCategoryLabel] = useState('');

    const [productsInterest, setProductsInterest] = useState(false);
    const [description, setDescription] = useState(false);
    const [showAdded, setShowAdded] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [cardQuantity, setCardQuantity] = useState(0);
    const [showCookies, setShowCookies] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const logo_circle = require('./react/logo.png');

    var lang = GetLanguage(language);
    const productRef = useRef(null)
    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            if (LoadCookies() == null) {
                setShowCookies(true);
            }

            const element = document.getElementById('id-start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }

        return () => {
        };

    }, []);


    const TokenResult = (typ, json) => {
        if (typ == true) {
            setSalon(json.salon);
            setAlias(json.salon.alias);
            setSettings(json.settings);
            setLanguage(json.salon.language);
            setClient(json.client);
            setLogedIn(json.logged);

            db_product(json.salon.id, json.client == false ? 0 : json.client.id);
        } else {
            navigate('/logout');
        }
    }

    /* *******************************************************************************
        NAHRATIE PRODUKTOV
    ******************************************************************************* */
    const db_product = async (salon_id, client_id) => {
        setBusy(true);
        var token = LoadToken();
        var slug = params.product;

        try {
            const response = await fetch(
                dbURL() + 'product', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: salon_id,
                    client_id: client_id,
                    token: token,
                    slug: slug,
                    action: lang.action,
                    others: global.my_products == true ? lang.products_others : false
                })
            })

            const json = await response.json();
            Debug('PRODUCT');
            Debug(json);
            if (json.ok > 0) {
                // *************************************************
                // PRODUKT
                // *************************************************
                setProduct(json.product);

                if (json.product != false) {
                    if (json.product.brand_id == 0) {
                        setDescription(json.product.note_long);
                    } else {
                        setDescription(json.product.note_html);
                    }
                }

                // *************************************************
                // SECTIONS
                // *************************************************
                setSection(json.section);
                setSections(json.sections);

                // *************************************************
                // CATEGORIES
                // *************************************************
                setCategory(json.category);
                setCategories(json.categories);

                // *************************************************
                // POCET POLOZIEK V KOSIKU
                // *************************************************
                setCardQuantity(json.quantity);
            }

            setBusy(false);
        } catch (error) {
            setBusy(false);
            Debug(error);
        }

    };


    /* *******************************************************************************
        PRIDANIE PRODUKTU DO KOSIKA
    ******************************************************************************* */
    const db_product_buy = async () => {
        if (isBusy == false) {
            setBusy(true);
            var token = LoadToken();

            try {
                const response = await fetch(
                    dbURL() + 'product_buy', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        salon_id: salon.id,
                        client_id: client != false ? client.id : 0,
                        public_key: token,
                        product_id: product.id,
                        quantity: quantity
                    })
                })

                const json = await response.json();
                //Debug('SHOPPING CARD', json);
                setBusy(false);
                if (json.ok > 0) {
                    setCardQuantity(json.quantity);
                    SaveCardQuantity(json.quantity);
                    setShowAdded(true);
                }

            } catch (error) {
                setBusy(false);
                Debug(error);
            }
        }
    };


    const BannerTopPress = (value, token_) => {
        if (value == 1) {
            // PRIHLASENIE SA UZIVATELA -> presmerovanie na jeho salon
            SavePage(0);
            SaveScroll(0);
            //db_token(token_);
            window.location.reload();
        }

        // AKCIA Z BANNERU
        if (value == 2) {
            // ODHLASIT SA
            navigate('/logout');
        }
        if (value == 3) {
            // Moj ucet
            navigate('/' + alias + '/account');
        }
    }

    const ChangeScreen = () => {
        navigate('/' + alias + '/eshop');
    }

    const ChangeSection = (section_slug) => {
        navigate('/' + alias + '/eshop/category/' + section_slug);
    }


    const ChangeCategory = (section_slug, category_slug) => {
        navigate('/' + alias + '/eshop/category/' + section_slug + '/' + category_slug);
    }


    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: global.theme_white, width: 30, height: 30 }}
                onClick={onClick}
            >
                <FontAwesomeIcon style={{ color: global.theme_black, fontSize: 30 }} icon={faChevronRight} />
            </div>
        );
    }


    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: global.theme_white, width: 30, height: 30 }}
                onClick={onClick}
            >
                <FontAwesomeIcon style={{ color: global.theme_black, fontSize: 30 }} icon={faChevronLeft} />
            </div>
        );
    }

    const sliderSettings = {
        dots: false,
        infinite: true,
        slidesToShow: isSmall == true ? 1 : isMedium ? 2 : 4,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    }

    const ProductCardPress = (item) => {

    }

    const BuyProduct = () => {
        db_product_buy();
    }

    const CloseAdded = () => {
        setShowAdded(false);
    }

    const ChangeQuantity = (event) => {
        setQuantity(event.target.value);
    }

    const GotoCard = () => {
        navigate("/" + alias + '/card');
    }

    const CookiesInfoPress = (value) => {
        setShowCookies(false);
        SaveCookies(true);
    }

    const EshopMenuPress = (section_id, category_id) => {
        Debug('ESHOP-MENU-PRESS');
        Debug(sections);
        var section_slug = false;
        var category_slug = false;
        var link = '';
        var alias = params.alias;

        var tmp = sections.find(x => x.id == section_id);
        if (tmp != undefined) {
            section_slug = tmp.slug;
            if (tmp.categories != false && category_id > 0) {
                var tmp = tmp.categories.find(x => x.id == category_id);
                if (tmp != undefined) {
                    category_slug = tmp.slug;
                }
            }
        }

        if (section_slug != false) {
            link = section_slug;
            if (category_slug != false) {
                link = link + '/' + category_slug;
            }

            navigate('/' + alias + '/eshop/category/' + link);
        }
    }

    const GoBack = () => {
        navigate(-1);
    }

    return (
        <div id={'id-start'}>
            <div ref={productRef}>
                {isSmall ? null :
                    <BannerTop theme={theme} chooseSalon={true} client={client} logged={client == false ? false : true} alias={alias} salon={salon} logedIn={logedIn} language={language} func={BannerTopPress.bind(this)} />
                }
                <NavigationMenu theme={theme} size={() => GetSize()} client={client} logged={client == false ? false : true} sectionID={sectionID} categoryID={categoryID} language={language} sections={sections} showLanguage={false} logedin={logedIn} alias={alias} salon={salon} index={10} quantity={cardQuantity} products={productNames} logo={true} func={EshopMenuPress.bind(this)} />
                <CheckToken alias={alias} func={TokenResult.bind(this)} />

                {/* UDRZBA ESHOPU */}
                {salon != false ? salon.eshop_ready == false ?
                    <EshopBusy lang={lang} />
                    : null : null}
                {/* HLAVNY BANNER */}

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', minHeight: isSmall ? height : 700 }}>
                    {product != false ?
                        <div style={{ display: 'flex', flexDirection: isLarge ? 'row' : 'column', width: '100%', maxWidth: isSmall ? 320 : isMedium ? 600 : global.screen_max, justifyContent: isLarge ? 'flex-start' : 'center', alignItems: isLarge ? 'flex-start' : 'center' }}>

                            {/* LAVA STRANA - MENU */}
                            <div style={{ width: 320, paddingTop: 30 }}>
                                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={GetImageLink(product.image)} style={{ width: 320, height: 320 }} />
                                </div>
                            </div>

                            {/* PRAVA STRANA - PRODUKTY */}
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', width: '100%', paddingLeft: isSmall ? 0 : 50 }}>
                                {section != false ?
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', paddingTop: 10, paddingBottom: 0 }}>
                                        <Breadcrumbs style={{ height: 30 }}>
                                            <IconButton onClick={() => ChangeScreen(0)} component="span">
                                                <img src={logo_circle} width={20} height={20} />
                                            </IconButton>
                                            <Button onClick={() => ChangeSection(section.slug)} style={{ fontSize: 16, color: global.theme_black, textTransform: 'none', margin: 0, padding: 0 }}>
                                                {section.label}
                                            </Button>
                                            {category != false ? product.category != '' ?
                                                <Button onClick={() => ChangeCategory(section.slug, category.slug)} style={{ fontSize: 16, color: global.theme_black, textTransform: 'none', margin: 0, padding: 0 }}>
                                                    {category.label}
                                                </Button>
                                                : null : null}
                                        </Breadcrumbs>
                                    </div>
                                    : null}

                                {/* NAZOV */}
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', paddingTop: 30, paddingBottom: 0 }}>
                                    <p style={{ fontSize: 14, fontWeight: 'normal', margin: 1, color: global.theme_dark_gray, fontFamily: 'roboto' }}>{lang.ie}</p>
                                    <h2 style={{ fontSize: 28, fontWeight: 'bold', margin: 1, fontFamily: 'roboto' }}>{product.label}</h2>
                                    <p style={{ ...styles.TextSmall, textAlign: 'left' }}>{product.note_short}</p>
                                </div>

                                {/* DETAILS */}
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'row', paddingTop: 30, paddingBottom: 0, borderBottom: '1px solid ' + global.theme_medium_gray }}>
                                    <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-start', alignItems: 'flex-start', paddingTop: 0, paddingBottom: 0, paddingTop: 10 }}>
                                        <p style={{ margin: 0, fontSize: 16, fontWeight: 'normal', fontFamily: 'roboto' }}>{product.details}</p>
                                    </div>
                                    <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-end', alignItems: 'flex-end', paddingTop: 0, paddingBottom: 0 }}>
                                        {product.eur != null ?
                                            salon.eshop_ready == true ?
                                                <p style={{ margin: 0, fontSize: 26, fontWeight: 'bold', fontFamily: 'roboto' }}>{product.eur.toString().replace('.', ',')} {lang.money}</p>
                                                : null
                                            : null
                                        }
                                    </div>

                                </div>

                                {/* SKLADOM */}
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', paddingTop: 20, paddingBottom: 0 }}>
                                    {product.instock > 0 ?
                                        <p style={{ margin: 0, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto', color: global.theme_darker_green }}>{lang.instock}: {product.instock} {lang.pieces}</p>
                                        :
                                        product.available == 0 ?
                                            <p style={{ margin: 0, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto', color: global.theme_red }}>{lang.stack_question}</p>
                                            :
                                            <p style={{ margin: 0, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto', color: global.theme_orange }}>{lang.nostock}</p>
                                    }
                                    <p style={{ margin: 0, fontSize: 12, fontWeight: 'normal', fontFamily: 'roboto', color: global.theme_dark_gray }}>{lang.code}: {product.code}</p>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        {salon.eshop_ready == true ?
                                            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', flexDirection: 'row', alignItems: 'flex-end', paddingTop: 20, paddingBottom: 0 }}>
                                                <Select
                                                    id="order-select"
                                                    style={{ textAlign: 'left', color: global.theme_black, width: 70, height: 40, marginRight: 20 }}
                                                    value={quantity}
                                                    onChange={ChangeQuantity}
                                                >
                                                    <MenuItem value={1}>{'1'}</MenuItem>
                                                    <MenuItem value={2}>{'2'}</MenuItem>
                                                    <MenuItem value={3}>{'3'}</MenuItem>
                                                    <MenuItem value={4}>{'4'}</MenuItem>
                                                    <MenuItem value={5}>{'5'}</MenuItem>
                                                    <MenuItem value={6}>{'6'}</MenuItem>
                                                    <MenuItem value={7}>{'7'}</MenuItem>
                                                    <MenuItem value={8}>{'8'}</MenuItem>
                                                    <MenuItem value={9}>{'9'}</MenuItem>
                                                    <MenuItem value={10}>{'10'}</MenuItem>
                                                </Select>
                                                <Button onClick={() => BuyProduct()} variant="contained" style={{ color: global.theme_white, backgroundColor: global.theme_black, fontSize: 14, width: 150, height: 40 }}>{lang.buy}</Button>
                                            </div>
                                            : null}
                                    </div>
                                </div>

                            </div>

                        </div>
                        : null}

                    {/* DOWN - POPIS PRODUKTY*/}
                    {product != false ?
                        <div style={{ display: 'flex', width: '90%', maxWidth: isSmall ? width : isMedium ? width : global.screen_max, justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20 }}>
                            <p style={{ margin: 2, fontFamily: 'roboto', fontSize: 16, fontWeight: 'bold' }}>{lang.description}</p>
                        </div>
                        : null}
                    {product != false ?
                        <div style={{ display: 'flex', width: '90%', maxWidth: isSmall ? width : isMedium ? width : global.screen_max, justifyContent: 'flex-start', alignItems: 'flex-start', borderTop: '1px solid ' + global.theme_gray }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 20, width: '100%' }}>
                                {description != false ?
                                    <div style={{ ...styles.TextSmall, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: description }} />
                                    : null
                                }
                            </div>
                        </div>
                        :
                        isBusy == false ?
                            <div style={{ ...styles.Block }}>
                                <FontAwesomeIcon style={{ fontSize: 30, color: global.theme_dark_red }} icon={faFrown} />
                                <p style={{ ...styles.TextNormal, marginTop: 20 }}>{lang.product_not_found}</p>
                                <Button onClick={() => GoBack()} style={{ color: global.theme_black, width: 200, borderColor: global.theme_black, marginTop: 20, borderRadius: 20, marginTop: 50 }} variant='outlined'>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginRight: 10 }} icon={faAngleLeft} />
                                    {lang.back}
                                </Button>
                            </div>
                            : null}

                    {product != false ?
                        <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 40 }}>
                            <Button onClick={() => GoBack()} style={{ color: global.theme_black, width: 200, borderColor: global.theme_black, marginTop: 20, borderRadius: 20 }} variant='outlined'>
                                <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginRight: 10 }} icon={faAngleLeft} />
                                {lang.back}
                            </Button>
                        </div>
                        : null}

                    {/* NOVE PRODUKTY V ESHOPE */}
                    {productsInterest != false ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, marginBottom: 30 }}>
                            <div style={{ padding: 10, maxWidth: isSmall == true ? 240 : isMedium ? 500 : 900 }}>
                                <h3>{lang.interest_products}</h3>
                                <Slider  {...sliderSettings}>
                                    {productsInterest.map(item => {
                                        return (
                                            <div key={item.id} >
                                                <ProductCard ready={salon.eshop_ready} size={200} language={language} item={item} func={ProductCardPress.bind(this)} />
                                            </div>
                                        )
                                    })}
                                </Slider>
                            </div>
                        </div >
                        : null}
                </div>

                <BottomMenu language={language} salon={salon} />
                <Footer language={language} islogged={logedIn} />

                {/* =============================================================================
                                PRIDANÉ DO KOSIKA
                ============================================================================= */}
                <Backdrop open={showAdded} >
                    <Paper style={{ display: 'flex', flexDirection: 'column', width: isSmall ? 300 : 600, borderRadius: 20 }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ ...styles.Block, width: 60 }}></div>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', flexDirection: 'column', paddingTop: 10, paddingLeft: 25 }}>
                                <p style={{ ...styles.TextXXLarge }}>{lang.added_to_card}</p>
                            </div>
                            <div style={{ ...styles.Block, width: 60 }}>
                                <IconButton onClick={() => CloseAdded()} style={{ width: 36, height: 36, backgroundColor: global.theme_black }}>
                                    <p style={{ ...styles.TextSmall, color: global.theme_white }}>X</p>
                                </IconButton>
                            </div>
                        </div>
                        <div style={{ display: 'flex', padding: 20, flexDirection: 'column', justifyItems: 'center', paddingBottom: 30 }}>
                            <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                                <div style={{ ...styles.BlockCenter }}>
                                    <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{product.label}</p>
                                    <p style={{ ...styles.TextTiny }}>{product.details}</p>
                                    <p style={{ ...styles.TextTiny, marginTop: 10, fontWeight: '600' }}>{'1'} {lang.pieces}</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', padding: 0, justifyContent: 'center', flexDirection: isSmall ? 'column' : 'row', marginTop: 40 }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: isSmall ? '100%' : '50%' }}>
                                    <Button onClick={() => CloseAdded()} style={{ backgroundColor: global.theme_white, width: 200, height: 40, textTransform: 'none', color: global.theme_black, borderColor: global.theme_black, borderRadius: 20 }} variant="outlined">{lang.continue_to_shop}</Button>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                                    <Button onClick={() => GotoCard()} variant="contained" style={{ color: global.theme_white, backgroundColor: global.theme_black, fontSize: 14, width: 200, height: 40, textTransform: 'none', borderRadius: 20 }}>{lang.continue_to_card}</Button>
                                </div>
                            </div>
                        </div>
                    </Paper>
                </Backdrop>

                {isBusy == true ?
                    <Loading />
                    : null}
            </div>

            {
                showCookies == true ?
                    <CookiesInfo lang={lang} salon={salon} func={CookiesInfoPress.bind(this)} />
                    : null
            }

        </div >
    )

}
