/*
*
* =================================================================================================================
* RULES -> VSEOBECNE OBCH. PODMIENKY, GDPR, COOKIES
* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';

//import logo from '/react_images/logo.png';
import './react/App.css';
import './react/w3.css';
import Button from '@mui/material/Button';
import { Routes, Route, Link, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import "animate.css/animate.min.css";
import { sk, cz } from './globals.js';

import { dbURL, Debug, Loading, LoadLanguage, LoadSalon } from './Items';
import { styles } from './styles.js';

export const Rules = (props) => {
    const navigate = useNavigate();

    const [language, setLanguage] = useState(0);
    const [isBusy, setBusy] = useState(true);
    const [salon, setSalon] = useState([]);
    const [remoteView, setRemoteView] = useState(false);
    const [text, setText] = useState('');

    var lang = language == 0 ? sk : cz;

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const pageRef = useRef(null)
    let params = useParams();

    useEffect(() => {
        document.body.style.zoom = "100%";
        setLanguage(LoadLanguage());

        var tmp = LoadSalon();

        if (tmp != null) {
            setSalon(tmp);
            db_get_text(tmp);
        } else {
            if (params.alias != undefined) {
                var alias = params.alias;
                setRemoteView(true);
                db_salon_alias();
            }
        }

        // SPAT NA VRCH STRANKY
        const timer = setTimeout(() => {
            if (pageRef != null) {
                Debug('TIMER');
                pageRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);

        return () => {
        };

    }, []);


    const db_salon_alias = async () => {

        try {
            const response = await fetch(
                dbURL() + 'salons_alias', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    alias: params.alias,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.salon != null) {
                    setSalon(json.salon);
                    setLanguage(json.salon.language);

                    db_get_text(json.salon);
                }
            }
        } catch (error) {

            Debug(error);
        }
    };

    const db_get_text = async (salon) => {
        setBusy(true);

        try {
            const response = await fetch(
                dbURL() + 'rules', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: salon.id,
                    typ: props.typ
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setText(json.text);
            }
            setBusy(false);
        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    };

    const GoBack = () => {
        navigate(-1);
    }


    return (
        <div ref={pageRef} style={{ ...styles.Block, padding: 20 }}>
            <div style={{ ...styles.BlockLeft, maxWidth: global.screen_max }}>
                <div style={{ ...styles.BlockLeft, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: text }} />
            </div>
            {remoteView == false && isBusy == false ?
                <Button onClick={() => GoBack()} style={{ color: global.theme_black, width: 200, borderColor: global.theme_black, marginTop: 20, borderRadius: 20 }} variant='outlined'>{lang.back}</Button>
                : null}

            {isBusy == true ?
                <Loading />
                : null}
        </div>
    );
}
