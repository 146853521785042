import React, { useEffect, useState } from 'react';

import './react/App.css';
import './react/w3.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import "animate.css/animate.min.css";
import { Init, NavigationMenu, Footer, LoadLanguage, SavePublic, SaveAlias, ChangeLanguageURL, dbURL, SaveTime, NeedRedraw, SaveProductNames, SaveCardQuantity, SaveToken, LoadToken } from './Items.js';
import { Helmet } from "react-helmet";
import { Loading, SaveSalon, SaveSalonSettings, Debug, SaveLanguage, LoadPublic, SaveClient, LoadClient, LoadLogedIn, SaveLogedIn } from './Items.js';
import { Product } from './product';
import { Login } from './login.js';
import { MyPage } from './mypage.js';
import { Eshop } from './eshop.js';
import { Contact } from './contact.js';
import { Account } from './account.js';
import { Category } from './category.js';
import { MyServices } from './services.js';
import { Card } from './card.js';
import { Checkout } from './checkout.js';
import { Search } from './search.js';
import { Rules } from './rules.js';
import { Bonus } from './bonus.js';
import { Orders } from './orders.js';


const App = () => {

    const [language, setLanguage] = useState(ChangeLanguageURL());
    const navigate = useNavigate()


    useEffect(() => {
        Debug('####################################')
        Debug('INIT APP');
        Debug('====================================')
        Debug('URL: ', window.location.href);
        Debug('Base URL: ', window.location.origin);
        Debug('####################################')
        Debug(global.testing == true ? 'TESTING' : 'LIVE');

        //var lang = LoadLanguage();
        var token = LoadToken();
        if (token == false) {
            ChangeLanguageURL();
            Debug('NEEXISTUJUCI TOKEN');
        } else {
            // UZ EXISTUJE TOKEN -> PRESMEROVANIE NA SALON
            db_token(token);
        }
    }, [])

    const db_token = async (token) => {

        try {
            const response = await fetch(
                dbURL() + 'token', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token,
                    language: ChangeLanguageURL()
                })
            })

            const json = await response.json();
            var ok = false;
            if (json.ok > 0) {
                if (json.verified == true) {
                    ok = true;
                    // TOKEN JE OVERENY
                    if (json.salon != false) {
                        var alias = json.salon.alias;
                        if (alias != '') {
                            var url = window.location.pathname;
                            if (url == '/') {
                                navigate('/' + alias);
                            }
                        }
                    }
                } else {
                    // NEEXISTUJUCI TOKEN
                }
            }
            if (ok == false) {
                navigate('/logout');
            }

        } catch (error) {
            Debug(error);
        }

    };

    return (
        <div className="App" style={{ backgroundColor: global.theme_white, fontFamily: 'roboto', WebkitUserSelect: 'none', msUserSelect: 'none', MozUserSelect: 'none', userSelect: 'none' }}>
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
            />

            <Helmet>
                <title>{global.title}</title>
                <meta name="description" content="Institut Esthederm salon" />
            </Helmet>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/:alias" element={<MyPage />} />
                <Route path="/:alias/home" element={<MyPage />} />
                <Route path="/:alias/services" element={<MyServices />} />
                <Route path="/:alias/eshop" element={<Eshop />} />
                <Route path="/:alias/contact" element={<Contact />} />
                <Route path="/:alias/account" element={<Account />} />
                <Route path="/:alias/eshop/category/:section" element={<Category />} />
                <Route path="/:alias/eshop/category/:section/:category" element={<Category />} />
                <Route path="/:alias/eshop/:product" element={<Product />} />
                <Route path="/:alias/search/:search" element={<SearchString />} />
                <Route path="/:alias/eshop/search/:search" element={<Search />} />
                <Route path="/:alias/card" element={<Card />} />
                <Route path="/:alias/checkout" element={<Checkout />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/gdpr" element={<Rules typ={global.rules_gdpr} />} />
                <Route path="/:alias/gdpr" element={<Rules typ={global.rules_gdpr} />} />
                <Route path="/rules" element={<Rules typ={global.rules_vop} />} />
                <Route path="/:alias/rules" element={<Rules typ={global.rules_vop} />} />
                <Route path="/cookies" element={<Rules typ={global.rules_cookies} />} />

                {/*
                <Route path="/gdpr" element={<GDPR />} />
                <Route path="/:alias/gdpr" element={<GDPR />} />
                <Route path="/rules" element={<Rules />} />
                <Route path="/:alias/rules" element={<Rules />} />
                <Route path="/cookies" element={<Cookies />} />
                */}
                <Route path="/bonus" element={<Bonus />} />
                <Route path="/orders/:salon_id/:id" element={<Orders />} />
                <Route path="*" element={<Logout />} />
            </Routes>
        </div>
    );
}

// ************************************************************************************************
//
//  LOGOUT
//
// ************************************************************************************************
const Logout = (props) => {
    // ODHLASIT SA

    useEffect(() => {
        Init();
    }, [])


    return (
        <Navigate replace to="/login" />
    )
}

const SearchString = () => {
    let params = useParams();

    useEffect(() => {
    }, [])

    return (
        <Navigate replace to={"/" + params.alias + '/eshop/search/' + params.search} />
    )
}

export default App;
