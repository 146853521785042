import { combineReducers } from 'redux';

const SET_INIT = 'SET_INIT';
const SET_LANGUAGE = 'SET_LANGUAGE';

export function setInit(item) {
    return {
        type: SET_INIT,
        item,
    }
}

export function setLanguage(item) {
    return {
        type: SET_LANGUAGE,
        item,
    }
}

const defaultItems =
{
    init: false,
    language: 0,
};

// state = array, kde sú uložené všetky data
function items(state = defaultItems, action) {

    switch (action.type) {

        case SET_INIT:
            // zmena jazyka
            var tmp = state;
            tmp.init = action.item;
            return tmp;

        case SET_LANGUAGE:
            // zmena jazyka
            var tmp = state;
            tmp.language = action.item;
            return tmp;

        default:
            return state;
    }
}

const itemsApp = combineReducers({
    items
});

export default itemsApp;