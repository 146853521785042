import React, { useEffect, useState } from 'react';
import './react/App.css';
import './react/w3.css';
import Button from '@mui/material/Button';
import { Routes, Route, Link, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import "animate.css/animate.min.css";
import { GetSize, NavigationMenu, Footer, Debug, LoadSalon, LoadSalonSettings, LoadLanguage, ChangeLanguageURL, Loading, LoadClient, dbURL, LoadPublic, LoadCardQuantity, GetPrice, BonusInfo, SaveCardQuantity, OrderError, OrderRegisterError, LoadToken, LoadCookies, IsEmpty, nonEmail, GetImageLink, CheckToken, DialogRules } from './Items.js';
import { BannerTop, GetLanguage } from './Items.js';
import { Stepper } from '@mui/material';
import { Step } from '@mui/material';
import { RadioGroup } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { Radio } from '@mui/material';
import { TextField } from '@mui/material';
import { Checkbox } from '@mui/material';
import { styles } from './styles.js';


export const Checkout = (props) => {
    const navigate = useNavigate();
    let params = useParams();

    const [isBusy, setBusy] = useState(true);
    const [isFinishing, setFinishing] = useState(false);
    const [isChecking, setChecking] = useState(false);
    const [isProductsBusy, setProductsBusy] = useState(false);
    const [salon, setSalon] = useState(false);
    const [settings, setSettings] = useState(false);
    const [language, setLanguage] = useState(ChangeLanguageURL());
    const [alias, setAlias] = useState(params.alias);
    const [client, setClient] = useState([]);
    const [productNames, setProductNames] = useState([]);
    const [logedIn, setLogedIn] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showRegisterError, setShowRegisterError] = useState(false);
    const [theme, setTheme] = useState(0);

    const [cardQuantity, setCardQuantity] = useState(0);
    const [products, setProducts] = useState([]);
    const [trade, setTrade] = useState([]);
    const [tradeSelected, setTradeSelected] = useState([]);
    const [tradeID, setTradeID] = useState(0);
    const [tradeSum, setTradeSum] = useState(0);
    const [payment, setPayment] = useState([]);
    const [paymentSum, setPaymentSum] = useState(0);
    const [paymentID, setPaymentID] = useState(0);
    const [paymentFilter, setPaymentFilter] = useState([]);
    const [paymentSelected, setPaymentSelected] = useState(null);
    const [paymentPrice, setPaymentPrice] = useState(0); // poplatok za dobierku
    const [tradeEnabled, setTradeEnabled] = useState(false);
    const [tradePrice, setTradePrice] = useState(0); // poplatok za dodanie kurierom
    const [tradeFree, setTradeFree] = useState(false); // postovne zdarma ?
    const [tradeFreePrice, setTradeFreePrice] = useState(false); // postovne zdarma od sumy
    const [sum, setSum] = useState(0); // suma za produkty    
    const [sumCard, setSumCard] = useState(0); // suma za produkty + postovne + doprava   
    const [isTradeFree, setIsTradeFree] = useState(false); // je doprava zdarma - po prekrocení min sumy
    const [addNote, setAddNote] = useState(false); // pridat poznamku
    const [orderNote, setOrderNote] = useState('');

    const [showOrderError, setShowOrderError] = useState(false);
    const [register, setRegister] = useState(false);

    const [activeStep, setActiveStep] = useState(0);

    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState(false);
    const [surname, setSurname] = useState('');
    const [surnameErr, setSurnameErr] = useState(false);
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState(false);
    const [mobil, setMobil] = useState('');
    const [mobilErr, setMobilErr] = useState(false);
    const [street, setStreet] = useState('');
    const [streetErr, setStreetErr] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [psc, setPSC] = useState('');
    const [pscErr, setPSCErr] = useState('');
    const [town, setTown] = useState('');
    const [townErr, setTownErr] = useState('');
    const [needAddress, setNeedAddress] = useState(false);
    const [gdpr, setGDPR] = useState(false);
    const [orderNumber, setOrderNumber] = useState('');
    const [bonus, setBonus] = useState('0');
    const [showBonus, setShowBonus] = useState(false);
    const [needCheckEmail, setNeedCheckEmail] = useState(true);
    const [showCookies, setShowCookies] = useState(false);

    // GDPR
    const [showRulesVOP, setShowRulesVOP] = useState(false);
    const [showRulesGDPR, setShowRulesGDPR] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });


    var lang = GetLanguage(language);

    const steps = [lang.checkout_step_1, lang.checkout_step_2, lang.checkout_step_3];
    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            if (LoadCookies() == null) {
                setShowCookies(true);
            }

            const element = document.getElementById('id-start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }

        return () => {
        };

    }, []);


    const TokenResult = (typ, json) => {
        if (typ == true) {
            setSalon(json.salon);
            setAlias(json.salon.alias);
            setSettings(json.settings);
            setLanguage(json.salon.language);
            setClient(json.client);
            setLogedIn(json.logged);

            if (json.client != false) {
                // Prihlásený užívateľ
                var user = json.client;
                setName(user.name);
                setSurname(user.surname);
                setEmail(user.email);
                setMobil(user.mobil);
                setStreet(user.street != null ? user.street : '');
                setStreetNumber(user.street_number != null ? user.street_number : '');
                setPSC(user.psc != null ? user.psc : '');
                setTown(user.town != null ? user.town : '');
            }

            db_shopping_card(json.salon.id, json.client.id);
        } else {
            navigate('/logout');
        }
    }

    /* *******************************************************************************
        PRODUKTY V NAKUPNOM KOSIKU
    ******************************************************************************* */
    const db_shopping_card = async (salon_id, client_id) => {
        setProductsBusy(true);

        var token = LoadToken();
        Debug(token);
        Debug(salon_id);
        Debug(client_id);
        try {
            const response = await fetch(
                dbURL() + 'shopping_card_v2', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: salon_id,
                    client_id: client_id,
                    public_key: token,
                })
            })

            const json = await response.json();
            setProductsBusy(false);

            Debug('SHOPPING CARD', json);

            if (json.ok > 0) {
                var tmp = json.card;
                if (tmp.length > 0) {
                    // ZOZNAM PRODUKTOV
                    setProducts(tmp);

                    // SUMA ZA PRODUKTY
                    setSum(json.sum);

                    // DOPRAVA
                    setTradeEnabled(json.trade_enabled);
                    setTrade(json.trade);
                    setTradeFree(json.trade_free_enabled);
                    setTradeFreePrice(json.trade_free_price);
                    setTradePrice(json.trade_price);

                    // PLATBA
                    setPayment(json.payment);
                    setPaymentPrice(json.payment_price);

                    // DOPRAVA ZADARMO
                    if (json.trade_free_enabled == true) {
                        if (parseFloat(json.sum) >= parseFloat(json.trade_free_price)) {
                            setIsTradeFree(true);
                        } else {
                            setIsTradeFree(false);
                        }
                    }
                } else {
                    // nie je nič v košíku
                    navigate('/' + alias);
                }
            }

        } catch (error) {
            setProductsBusy(false);
            Debug(error);
        }

    };


    /* *******************************************************************************
        UKONCENIE NAKUPU
    ******************************************************************************* */
    const db_email_check = async () => {

        if (isChecking == false) {
            setChecking(true);

            try {
                const response = await fetch(
                    dbURL() + 'email_check', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email,
                    })
                })

                const json = await response.json();
                setChecking(false);
                if (json.ok > 0) {
                    if (json.count == 0) {
                        setNeedCheckEmail(false);
                        Continue();
                    } else {
                        // email je uz registrovaný
                        if (register == true) {
                            // neda sa znovu registrovat
                            setShowRegisterError(true);
                        } else {
                            // chcete sa prihlasit

                        }

                    }
                } else {
                    // nastala chyba
                }

            } catch (error) {
                setChecking(false);
                Debug(error);
            }
        }
    };

    /* *******************************************************************************
        UKONCENIE NAKUPU
    ******************************************************************************* */
    const db_checkout = async (id, quantity) => {
        if (isFinishing == false) {
            setFinishing(true);

            var token = LoadToken();

            try {
                const response = await fetch(
                    dbURL() + 'checkout_v2', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        language: language,
                        salon_id: salon.id,
                        client_id: client != false ? client.id : 0,
                        card_key: token,
                        trade_id: tradeID,
                        payment_id: paymentID,
                        name: name,
                        surname: surname,
                        mobil: mobil,
                        email: email,
                        street: street,
                        street_number: streetNumber,
                        psc: psc,
                        town: town,
                        is_payment_free: isTradeFree,
                        is_trade_free: isTradeFree,
                        sum: sum,
                        register: register,
                        note: orderNote.trim()
                    })
                })

                const json = await response.json();
                setFinishing(false);
                Debug(json);
                if (json.ok > 0) {
                    setShowOrderError(false);
                    setOrderNumber(json.order_number);
                    setBonus(json.bonus);
                    setActiveStep(3);
                    setCardQuantity(0);
                    SaveCardQuantity(0);
                } else {
                    // nastala chyba
                    setShowOrderError(true);
                }

            } catch (error) {
                setFinishing(false);
                Debug(error);
            }
        }
    };



    const BannerTopPress = (value, data) => {
        if (value == 1) {
            // PRIHLASENIE SA UZIVATELA -> presmerovanie na jeho salon
            setSalon(LoadSalon());
            setSettings(LoadSalonSettings());
            setLanguage(LoadLanguage());
            var client_ = LoadClient()
            setClient(client_);
            setCardQuantity(LoadCardQuantity());
            setLogedIn(true);
            if (client_ != null) {
                setName(client_.name);
                setSurname(client_.surname);
                setEmail(client_.email);
                setMobil(client_.mobil);
                setStreet(client_.street);
                setStreetNumber(client_.street_number);
                setPSC(client_.psc);
                setTown(client_.town);
            }
            setBusy(false);
        }

        // AKCIA Z BANNERU
        if (value == 2) {
            // ODHLASIT SA
            navigate('/logout');
        }
        if (value == 3) {
            // UCET            
            navigate('/' + alias + '/account');
        }

        if (value == 4) {
            if (data == false) {
                setShowLogin(false);
            } else {

            }
        }
    }

    const tradeChange = (event) => {
        var tmp = trade.filter(x => x.id == event.target.value);
        if (tmp.length > 0) {
            setTradeSelected(tmp[0]);
            setTradeID(tmp[0].id);
            setNeedAddress(tmp[0].need_adress);
            if (isTradeFree == true) {
                setTradeSum(0);
            } else {
                setTradeSum(tmp[0].price);
            }

            var payment_ = payment.filter(x => x.cash == tmp[0].cash);
            setPaymentFilter(payment_);

            setPaymentSelected(null);
            setPaymentID(0);
        }
    }

    const paymentChange = (event) => {
        var tmp = payment.filter(x => x.id == event.target.value);
        Debug('PAYMENT', tmp);
        if (tmp.length > 0) {
            setPaymentSelected(tmp[0]);
            setPaymentID(tmp[0].id);
            if (isTradeFree == true) {
                setPaymentSum(0);
            } else {
                setPaymentSum(tmp[0].price);
            }
        }
    }

    const Card = () => {
        navigate('/' + alias + '/card');
    }

    const Back = () => {
        setActiveStep(activeStep - 1);
    }

    const Continue = () => {
        setActiveStep(activeStep + 1);
    }

    const FinishOrder = () => {
        // ODOSLAT OBJEDNAVKU
        db_checkout();
    }

    const Check = () => {
        var error = false

        setNameErr('');
        setSurnameErr('');
        setMobilErr('');
        setEmailErr('');
        setStreetErr('');
        setPSCErr('');
        setTownErr('');

        if (IsEmpty(name.trim())) {
            setNameErr(lang.required);
            error = true;
        }
        if (IsEmpty(surname.trim())) {
            setSurnameErr(lang.required);
            error = true;
        }

        if (IsEmpty(email.trim())) {
            setEmailErr(lang.required);
            error = true;
        } else if (nonEmail(email.trim())) {
            setEmailErr(lang.send_err_email);
            error = true;
        }

        if (IsEmpty(mobil.trim())) {
            setMobilErr(lang.required);
            error = true;
        }

        if (needAddress == true) {
            if (IsEmpty(street.trim())) {
                setStreetErr(lang.required);
                error = true;
            }
            if (IsEmpty(psc.trim())) {
                setPSCErr(lang.required);
                error = true;
            }
            if (IsEmpty(town.trim())) {
                setTownErr(lang.required);
                error = true;
            }

        }
        if (error == false) {
            // kontrola, ci je email zaregistrovany
            if (needCheckEmail == true && logedIn == false && register == true) {
                db_email_check();
            } else {
                Continue();
            }
        }
    }

    const CardSum = () => {
        var tmp = parseFloat(sum) + parseFloat(tradeSum) + parseFloat(paymentSum);
        tmp = GetPrice(tmp);
        return tmp;
    }

    const ChangeGDPR = (event) => {
        setGDPR(event.target.checked);
    }

    const ChangeRegister = (event) => {
        setRegister(event.target.checked);
    }

    const ChangeAddNote = (event) => {
        setAddNote(event.target.checked);
        setOrderNote('');
    }

    const GotoEshop = () => {
        navigate('/' + alias + '/eshop');
    }

    const BonusInfoPress = () => {
        setShowBonus(false);
    }

    const OrderErrorPress = (value) => {
        setShowOrderError(false);
    }

    const inputMobil = (text) => {
        // input only numbers
        if (/^\d*(?:[+]\d*)?$/.test(text)) {
            setMobil(text);
        }
        if (text == '') {
            setMobil(text);
        }
    }

    const inputEmail = (text) => {
        setEmail(text);
        setNeedCheckEmail(true);
    }

    const OrderRegisterErrorPress = (value) => {
        setShowRegisterError(false);
    }

    const OrderNote = (value) => {
        if (orderNote.length < 10) {
            setOrderNote(value);
        }
    }


    return (
        <div id={'id-start'}>
            <NavigationMenu theme={theme} size={() => GetSize()} client={client} logged={client == false ? false : true} showLanguage={false} language={language} logedin={logedIn} alias={alias} salon={salon} index={3} quantity={cardQuantity} products={productNames} />
            <CheckToken alias={alias} func={TokenResult.bind(this)} />

            {
                isSmall == false ?
                    activeStep < 3 ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', maxWidth: global.screen_max, width: '100%', justifyContent: 'center', alignItems: 'center' }}>

                                <div style={{ width: '100%', paddingTop: 25, paddingBottom: 25 }}>
                                    <Stepper activeStep={activeStep} style={{ width: '100%' }}>
                                        {steps.map((label, index) => {
                                            const stepProps = {};
                                            const labelProps = { color: 'red' };

                                            return (
                                                <Step key={index} {...stepProps}>
                                                    <div style={{ display: 'flex', backgroundColor: index == activeStep ? global.theme_black : global.theme_white, width: 40, padding: 0, alignItems: 'center', justifyContent: 'center', height: 40, borderRadius: '50%', border: '1px solid ' + global.theme_black }}>
                                                        <p style={{ margin: 0, fontSize: 16, fontFamily: 'roboto', color: index == activeStep ? global.theme_white : global.theme_black }}>{index + 1}</p>
                                                    </div>
                                                    <p style={{ margin: 0, color: global.theme_black, size: 16, fontFamily: 'roboto' }}>{label}</p>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                </div>
                            </div>
                        </div>
                        : null
                    : null}


            {activeStep == 0 ?
                /* *****************************************************************************************************************************
                    DOPRAVA A PLATBA
                ***************************************************************************************************************************** */
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: isSmall ? 320 : global.screen_max, width: '100%', justifyContent: 'center', alignItems: 'center' }}>

                        {/* DOPRAVA */}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column', width: '100%', width: '100%', paddingTop: 20, paddingBottom: 50 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: isSmall ? 'center' : 'flex-start', alignItems: isSmall ? 'center' : 'flex-start' }}>
                                <p style={{ fontSize: 26, margin: 0, color: global.theme_black, fontFamily: 'roboto', fontWeight: 'bold', marginBottom: 10 }}>{lang.trade}</p>
                                <RadioGroup onChange={tradeChange} defaultValue={0} value={tradeID} name="radio-buttons-group" style={{ width: '100%' }}>
                                    {
                                        trade.map(item => {
                                            return (
                                                <div key={item.id} style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start', marginBottom: 15, paddingLeft: 10, paddingRight: 10 }}>
                                                    <div style={{ display: 'flex', width: '75%', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                                        <FormControlLabel value={item.id} control={<Radio />} label={<p style={{ fontWeight: 'bold', margin: 0, textAlign: 'left' }}>{item.label}</p>} />
                                                        <p style={{ margin: 0, marginLeft: 30, color: global.theme_black, fontFamily: 'roboto', textAlign: 'left' }}>{item.note}</p>
                                                    </div>
                                                    <div style={{ display: 'flex', width: '25%', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                                        {item.free == true && isTradeFree == true ?
                                                            <p style={{ margin: 0, color: global.theme_darker_green, fontFamily: 'roboto', textAlign: 'left' }}>{lang.free}</p>
                                                            :
                                                            parseFloat(item.price) > '0.00' ?
                                                                <p style={{ margin: 0, color: global.theme_black, fontFamily: 'roboto', textAlign: 'left' }}>{GetPrice(item.price)} {lang.money}</p>
                                                                :
                                                                <p style={{ margin: 0, color: global.theme_darker_green, fontFamily: 'roboto', textAlign: 'left' }}>{lang.free}</p>
                                                        }
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }
                                </RadioGroup>
                            </div>
                        </div>

                        {/* PLATBA */}
                        {paymentFilter.length > 0 ?
                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column', width: '100%', width: '100%', paddingTop: 20, paddingBottom: 50 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: isSmall ? 'center' : 'flex-start', alignItems: isSmall ? 'center' : 'flex-start' }}>
                                    <p style={{ fontSize: 26, margin: 0, color: global.theme_black, fontFamily: 'roboto', fontWeight: 'bold', marginBottom: 10 }}>{lang.payment}</p>
                                    <RadioGroup onChange={paymentChange} defaultValue={0} value={paymentID} name="radio-buttons-group" style={{ width: '100%' }}>
                                        {
                                            paymentFilter.map(item => {
                                                return (
                                                    <div key={item.id} style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start', marginBottom: 15, paddingLeft: 10, paddingRight: 10 }}>
                                                        <div style={{ display: 'flex', width: '75%', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                                            <FormControlLabel value={item.id} control={<Radio />} label={<p style={{ fontWeight: 'bold', margin: 0, textAlign: 'left' }}>{item.label}</p>} />
                                                            <p style={{ margin: 0, marginLeft: 30, color: global.theme_black, fontFamily: 'roboto', textAlign: 'left' }}>{item.note}</p>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '25%', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                                            {
                                                                item.free == true && isTradeFree == true ?
                                                                    <p style={{ margin: 0, color: global.theme_darker_green, fontFamily: 'roboto', textAlign: 'left' }}>{lang.free}</p>
                                                                    :
                                                                    parseFloat(item.price) > 0.00 ?
                                                                        <p style={{ margin: 0, color: global.theme_black, fontFamily: 'roboto', textAlign: 'left' }}>{GetPrice(item.price)} {lang.money}</p>
                                                                        :
                                                                        <p style={{ margin: 0, color: global.theme_darker_green, fontFamily: 'roboto', textAlign: 'left' }}>{lang.free}</p>

                                                            }
                                                        </div>

                                                    </div>
                                                )
                                            })
                                        }
                                    </RadioGroup>
                                </div>
                            </div>
                            : null}

                        {/* POKRACOVAT */}
                        {isSmall ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', paddingTop: 20, paddingBottom: 150, borderTop: '1px solid ' + global.theme_gray }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                    {paymentSelected != null ?
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                            <Button onClick={() => Continue()} variant="contained" style={{ color: global.theme_white, backgroundColor: global.theme_black, fontSize: 14, width: 200, height: 40, textTransform: 'none', borderRadius: 20 }}>{lang.continue}</Button>
                                        </div>
                                        : null}
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 20 }}>
                                        <Button onClick={() => Card()} style={{ backgroundColor: global.theme_white, width: 200, height: 40, textTransform: 'none', color: global.theme_black, borderColor: global.theme_black, borderRadius: 20 }} variant="outlined">{lang.card}</Button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', paddingTop: 20, paddingBottom: 150, borderTop: '1px solid ' + global.theme_gray }}>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '50%' }}>
                                        <Button onClick={() => Card()} style={{ backgroundColor: global.theme_white, width: 200, height: 40, textTransform: 'none', color: global.theme_black, borderColor: global.theme_black, borderRadius: 20 }} variant="outlined">{lang.card}</Button>
                                    </div>
                                    {paymentSelected != null ?
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '50%' }}>
                                            <Button onClick={() => Continue()} variant="contained" style={{ color: global.theme_white, backgroundColor: global.theme_black, fontSize: 14, width: 200, height: 40, textTransform: 'none', borderRadius: 20 }}>{lang.continue}</Button>
                                        </div>
                                        : null}
                                </div>
                            </div>
                        }

                    </div>
                </div>

                : null}


            {activeStep == 1 ?
                /* *****************************************************************************************************************************
                    ADRESA
                ***************************************************************************************************************************** */
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: global.screen_max, width: '100%', justifyContent: 'center', alignItems: 'center' }}>

                        {/* INFORMACIE O ZAKAZNIKOVI */}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column', width: '100%', width: '100%', paddingTop: 20, paddingBottom: 20 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: isSmall ? 'center' : 'flex-start', alignItems: isSmall ? 'center' : 'flex-start' }}>
                                <p style={{ fontSize: 26, margin: 0, color: global.theme_black, fontFamily: 'roboto', fontWeight: 'bold', marginBottom: 0 }}>{lang.client_info}</p>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column', width: '100%', width: '100%', paddingTop: 20, paddingBottom: 30 }}>
                            <div style={{ display: 'flex', flexDirection: isSmall ? 'column' : 'row', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>

                                {/* UDAJE */}
                                <div style={{ display: 'flex', flexDirection: 'column', width: isSmall ? '100%' : '50%', justifyContent: 'center', alignItems: 'center', padding: 0, paddingLeft: 10, paddingRight: 10 }}>

                                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                        <p style={{ margin: 0, fontSize: 20, color: global.theme_black, fontWeight: 'bold', fontFamily: 'roboto' }}>{lang.client_contact}</p>
                                    </div>
                                    <TextField error={nameErr == '' ? false : true} helperText={nameErr} style={{ marginTop: 0, backgroundColor: global.theme_white, width: '100%' }} label={lang.name} variant="outlined" value={name} onInput={e => setName(e.target.value)} />
                                    <TextField error={surnameErr == '' ? false : true} helperText={surnameErr} style={{ marginTop: 10, backgroundColor: global.theme_white, width: '100%' }} label={lang.surname} variant="outlined" value={surname} onInput={e => setSurname(e.target.value)} />
                                    <TextField error={mobilErr == '' ? false : true} helperText={mobilErr} style={{ marginTop: 10, backgroundColor: global.theme_white, width: '100%' }} label={lang.mobil} variant="outlined" value={mobil} onInput={e => inputMobil(e.target.value)} />
                                    <TextField error={emailErr == '' ? false : true} helperText={emailErr} style={{ marginTop: 10, backgroundColor: global.theme_white, width: '100%' }} label={lang.email} variant="outlined" value={email} onInput={e => inputEmail(e.target.value)} />

                                    {needAddress == true ?
                                        <div style={{ width: '100%' }}>
                                            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', marginTop: 30, marginBottom: 10 }}>
                                                <p style={{ margin: 0, fontSize: 20, color: global.theme_black, fontWeight: 'bold', fontFamily: 'roboto' }}>{lang.client_address}</p>
                                            </div>

                                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                                <div style={{ width: '70%' }}>
                                                    <TextField error={streetErr == '' ? false : true} helperText={streetErr} style={{ marginTop: 0, backgroundColor: global.theme_white, width: '100%' }} label={lang.street} variant="outlined" value={street} onInput={e => setStreet(e.target.value)} />
                                                </div>
                                                <div style={{ width: '30%', paddingLeft: 10 }}>
                                                    <TextField style={{ marginTop: 0, backgroundColor: global.theme_white, width: '100%' }} label={lang.number} variant="outlined" value={streetNumber} onInput={e => setStreetNumber(e.target.value)} />
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, width: '100%', paddingRight: 10 }}>
                                                <div style={{ width: '30%' }}>
                                                    <TextField error={pscErr == '' ? false : true} helperText={pscErr} style={{ marginTop: 0, backgroundColor: global.theme_white, width: '100%' }} label={lang.psc} variant="outlined" value={psc} onInput={e => setPSC(e.target.value)} />
                                                </div>
                                                <div style={{ width: '70%' }}>
                                                    <TextField error={townErr == '' ? false : true} helperText={townErr} style={{ marginTop: 0, backgroundColor: global.theme_white, width: '100%', marginLeft: 10 }} label={lang.town} variant="outlined" value={town} onInput={e => setTown(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        : null}

                                    {/* CHCEM SA ZAREGISTROVAT */}
                                    {client == false ?
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column', width: '100%', width: '100%', paddingTop: 20, paddingBottom: 10 }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                                <FormControlLabel control={<Checkbox checked={register} onChange={ChangeRegister} style={{ color: global.theme_black }} />} label={lang.register} />
                                            </div>
                                        </div>
                                        : null}

                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column', width: '100%', width: '100%', paddingTop: 10, paddingBottom: 10 }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                            <FormControlLabel control={<Checkbox checked={addNote} onChange={ChangeAddNote} style={{ color: global.theme_black }} />} label={lang.add_note} />
                                            {addNote == true ?
                                                <TextField style={{ marginTop: 0, backgroundColor: global.theme_white, width: '100%', marginLeft: 10 }} inputProps={{ maxLength: 255 }} label={lang.note} variant="outlined" value={orderNote} multiline={true} maxRows={4} onInput={e => setOrderNote(e.target.value)} />
                                                : null}
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', width: isSmall ? '100%' : '50%', justifyContent: 'center', alignItems: 'center', marginTop: isSmall ? 50 : 0 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: 400, justifyContent: 'flex-end', alignItems: 'flex-end', padding: 20 }}>

                                        {/* ZHRNUTIE - PRODUKT */}
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', marginBottom: 20 }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '50%' }}>
                                                <p style={{ margin: 0, fontSize: 16, color: global.theme_black, fontFamily: 'roboto' }}>{lang.products_price}</p>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end', width: '50%' }}>
                                                <p style={{ margin: 0, fontSize: 16, color: global.theme_black, fontFamily: 'roboto' }}>{GetPrice(sum)} {lang.money}</p>
                                            </div>
                                        </div>

                                        {/* ZHRNUTIE - DOPRAVA */}
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', marginBottom: 20 }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '50%' }}>
                                                <p style={{ margin: 0, fontSize: 16, color: global.theme_black, fontFamily: 'roboto' }}>{lang.card_trade}</p>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end', width: '50%' }}>
                                                <p style={{ margin: 0, fontSize: 16, color: global.theme_black, fontFamily: 'roboto' }}>{tradeSum == 0 ? lang.free : GetPrice(tradeSum) + ' ' + lang.money}</p>
                                            </div>
                                        </div>

                                        {/* ZHRNUTIE - PLATBA */}
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', marginBottom: 20 }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '50%' }}>
                                                <p style={{ margin: 0, fontSize: 16, color: global.theme_black, fontFamily: 'roboto' }}>{lang.card_payment}</p>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end', width: '50%' }}>
                                                <p style={{ margin: 0, fontSize: 16, color: global.theme_black, fontFamily: 'roboto' }}>{paymentSum == 0 ? lang.free : GetPrice(paymentSum) + ' ' + lang.money}</p>
                                            </div>
                                        </div>

                                        {/* SUMA */}
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', borderTop: '1px solid ' + global.theme_gray, paddingTop: 20 }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '50%' }}>
                                                <p style={{ margin: 0, fontSize: 16, color: global.theme_black, fontFamily: 'roboto', fontWeight: 'bold' }}>{lang.sum_with_dph}</p>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end', width: '50%' }}>
                                                <p style={{ margin: 0, fontSize: 16, color: global.theme_black, fontFamily: 'roboto', fontWeight: 'bold' }}>{CardSum()} {lang.money}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* POKRACOVAT */}
                        {isSmall ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', paddingTop: 20, paddingBottom: 150, borderTop: '1px solid ' + global.theme_gray }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                        <Button onClick={() => Check()} variant="contained" style={{ color: global.theme_white, backgroundColor: global.theme_black, fontSize: 14, width: 200, height: 40, textTransform: 'none', borderRadius: 20 }}>{lang.continue}</Button>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 20 }}>
                                        <Button onClick={() => Back()} style={{ backgroundColor: global.theme_white, width: 200, height: 40, textTransform: 'none', color: global.theme_black, borderColor: global.theme_black, borderRadius: 20 }} variant="outlined">{lang.back_to_trade}</Button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', paddingTop: 20, paddingBottom: 150, borderTop: '1px solid ' + global.theme_gray }}>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '50%' }}>
                                        <Button onClick={() => Back()} style={{ backgroundColor: global.theme_white, width: 200, height: 40, textTransform: 'none', color: global.theme_black, borderColor: global.theme_black, borderRadius: 20 }} variant="outlined">{lang.back_to_trade}</Button>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '50%' }}>
                                        <Button onClick={() => Check()} variant="contained" style={{ color: global.theme_white, backgroundColor: global.theme_black, fontSize: 14, width: 200, height: 40, textTransform: 'none', borderRadius: 20 }}>{lang.continue}</Button>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>

                : null}


            {activeStep == 2 ?
                /*
                    ZHRNUTIE
                */
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: global.screen_max, width: '100%', justifyContent: 'center', alignItems: 'center' }}>

                        {/* ZHRNUTIE - LABEL */}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column', width: '100%', width: '100%', paddingTop: 20, paddingBottom: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: isSmall ? 'center' : 'flex-start', alignItems: isSmall ? 'center' : 'flex-start' }}>
                                <p style={{ fontSize: 26, margin: 0, color: global.theme_black, fontFamily: 'roboto', fontWeight: 'bold', marginBottom: 10 }}>{lang.sumar}</p>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column', width: '100%', width: '100%', paddingTop: 20, paddingBottom: 50 }}>
                            <div style={{ display: 'flex', flexDirection: isSmall ? 'column' : 'row', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', paddingLeft: 20, paddingRight: 20 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: isSmall ? '100%' : '50%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                    <p style={{ fontSize: 16, margin: 0, color: global.theme_black, fontFamily: 'roboto', fontWeight: 'bold', marginBottom: 0 }}>{lang.client_contact}:</p>
                                    <p style={{ fontSize: 16, margin: 0, color: global.theme_black, fontFamily: 'roboto', fontWeight: 'normal', marginBottom: 0 }}>{name} {surname}</p>
                                    <p style={{ fontSize: 16, margin: 0, color: global.theme_black, fontFamily: 'roboto', fontWeight: 'normal', marginBottom: 0 }}>{lang.email} {email}</p>
                                    <p style={{ fontSize: 16, margin: 0, color: global.theme_black, fontFamily: 'roboto', fontWeight: 'normal', marginBottom: 0 }}>{lang.mobil} {mobil}</p>
                                </div>
                                {needAddress == true ?
                                    <div style={{ display: 'flex', flexDirection: 'column', width: isSmall ? '100%' : '50%', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: isSmall ? 30 : 0 }}>
                                        <p style={{ fontSize: 16, margin: 0, color: global.theme_black, fontFamily: 'roboto', fontWeight: 'bold', marginBottom: 0 }}>{lang.client_address}:</p>
                                        <p style={{ fontSize: 16, margin: 0, color: global.theme_black, fontFamily: 'roboto', fontWeight: 'normal', marginBottom: 0 }}>{street} {streetNumber}</p>
                                        <p style={{ fontSize: 16, margin: 0, color: global.theme_black, fontFamily: 'roboto', fontWeight: 'normal', marginBottom: 0 }}>{psc} {town}</p>
                                    </div>
                                    : null}
                            </div>
                        </div>


                        {/* ZOZNAM PRODUKTOV */}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column', width: '100%', width: '100%', paddingTop: 20, paddingBottom: 50 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>

                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', width: '100%' }}>
                                    {products.map(item => {
                                        return (
                                            isSmall ?
                                                <div key={item.id} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-start', width: '100%', paddingTop: 10, paddingBottom: 10, paddingLeft: 10, paddingRight: 10, borderTop: '1px solid ' + global.theme_gray }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', width: '100%', }}>
                                                        <div style={{ display: 'flex', alignItems: 'flex-end', width: 100, height: 100 }}>
                                                            <img src={GetImageLink(item.image)} style={{ width: 100, height: 100 }} />
                                                        </div>
                                                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start', width: '50%', paddingRight: 20 }}>
                                                            <p style={{ margin: 0, color: global.theme_dark_gray, fontSize: 12, fontWeight: 'normal', fontFamily: 'roboto', textAlign: 'left' }}>{lang.ie}</p>
                                                            <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto', textAlign: 'left', textAlign: 'left' }}>{item.label}</p>
                                                            <p style={{ margin: 0, color: global.theme_black, fontSize: 14, fontWeight: 'normal', fontFamily: 'roboto', textAlign: 'left', textAlign: 'left' }}>{item.note_short}</p>
                                                            <p style={{ margin: 0, color: global.theme_black, fontSize: 13, fontWeight: 'normal', fontFamily: 'roboto', textAlign: 'left', textAlign: 'left' }}>{item.details}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                        <div style={{ display: 'flex', alignItems: 'flex-end', width: 100 }}>
                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, width: '25%' }}>
                                                            <p style={{ ...styles.TextSmall, fontWeight: 'bold' }}>{item.quantity} {lang.pieces}</p>
                                                        </div>
                                                        <div style={{ ...styles.BlockRight, width: '50%' }}>
                                                            <p style={{ ...styles.TextSmall, fontWeight: 'bold' }}>{GetPrice(item.sum)} {lang.money}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div key={item.id} style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', width: '100%', paddingTop: 10, paddingBottom: 10, borderTop: '1px solid ' + global.theme_gray }}>
                                                    <div style={{ display: 'flex', alignItems: 'flex-end', width: 140, height: 100 }}>
                                                        <img src={GetImageLink(item.image)} style={{ width: 100, height: 100 }} />
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start', width: '50%', paddingRight: 20 }}>
                                                        <p style={{ margin: 0, color: global.theme_dark_gray, fontSize: 12, fontWeight: 'normal', fontFamily: 'roboto', textAlign: 'left' }}>{lang.ie}</p>
                                                        <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto', textAlign: 'left', textAlign: 'left' }}>{item.label}</p>
                                                        <p style={{ margin: 0, color: global.theme_black, fontSize: 14, fontWeight: 'normal', fontFamily: 'roboto', textAlign: 'left', textAlign: 'left' }}>{item.note_short}</p>
                                                        <p style={{ margin: 0, color: global.theme_black, fontSize: 13, fontWeight: 'normal', fontFamily: 'roboto', textAlign: 'left', textAlign: 'left' }}>{item.details}</p>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start', width: '15%' }}>
                                                        <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'normal', fontFamily: 'roboto' }}>{item.quantity} {lang.pieces}</p>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-end', width: '15%' }}>
                                                        <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto' }}>{GetPrice(item.sum)} {lang.money}</p>
                                                    </div>
                                                </div>

                                        )
                                    })
                                    }

                                    {/* DOPRAVA */}
                                    {isSmall ?
                                        < div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', width: '100%', paddingTop: 10, paddingBottom: 10, borderTop: '1px solid ' + global.theme_gray, paddingLeft: 10, paddingRight: 10 }}>
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start', width: '50%', paddingRight: 20 }}>
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto', textAlign: 'left' }}>{lang.card_trade}</p>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{tradeSelected.label}</p>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', alignItems: 'flex-end', width: '50%' }}>
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto' }}>{tradeSum == 0 ? lang.free : GetPrice(tradeSum) + ' ' + lang.money}</p>
                                            </div>
                                        </div>
                                        :

                                        < div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', width: '100%', paddingTop: 10, paddingBottom: 10, borderTop: '1px solid ' + global.theme_gray }}>
                                            <div style={{ display: 'flex', alignItems: 'flex-end', width: 140, height: 50 }}>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start', width: '50%', paddingRight: 20 }}>
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto', textAlign: 'left' }}>{lang.card_trade}</p>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{tradeSelected.label}</p>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start', width: '15%' }}>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-end', width: '15%' }}>
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto' }}>{tradeSum == 0 ? lang.free : GetPrice(tradeSum) + ' ' + lang.money}</p>
                                            </div>
                                        </div>
                                    }
                                    {/* PLATBA */}
                                    {isSmall ?
                                        < div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', width: '100%', paddingTop: 10, paddingBottom: 10, borderTop: '1px solid ' + global.theme_gray, paddingLeft: 10, paddingRight: 10 }}>
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start', width: '50%', paddingRight: 20 }}>
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto', textAlign: 'left' }}>{lang.card_payment}</p>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{paymentSelected.label}</p>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', alignItems: 'flex-end', width: '50%' }}>
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto' }}>{paymentSum == 0 ? lang.free : GetPrice(paymentSum) + ' ' + lang.money}</p>
                                            </div>
                                        </div>
                                        :
                                        <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', width: '100%', paddingTop: 10, paddingBottom: 10, borderTop: '1px solid ' + global.theme_gray }}>
                                            <div style={{ display: 'flex', alignItems: 'flex-end', width: 140, height: 50 }}>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start', width: '50%', paddingRight: 20 }}>
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto', textAlign: 'left' }}>{lang.card_payment}</p>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{paymentSelected.label}</p>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start', width: '15%' }}>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-end', width: '15%' }}>
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto' }}>{paymentSum == 0 ? lang.free : GetPrice(paymentSum) + ' ' + lang.money}</p>
                                            </div>
                                        </div>
                                    }

                                    {/* SUMA */}
                                    {isSmall ?
                                        < div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', width: '100%', paddingTop: 10, paddingBottom: 10, borderTop: '1px solid ' + global.theme_gray, paddingLeft: 10, paddingRight: 10 }}>
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start', width: '50%', paddingRight: 20 }}>
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 14, fontWeight: 'normal', fontFamily: 'roboto', textAlign: 'left' }}>{lang.card_sum}</p>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', alignItems: 'flex-end', width: '50%' }}>
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto' }}>{CardSum()} {lang.money}</p>
                                            </div>
                                        </div>
                                        :
                                        <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', width: '100%', paddingTop: 10, paddingBottom: 10, borderTop: '1px solid ' + global.theme_gray, backgroundColor: global.theme_gray }}>
                                            <div style={{ display: 'flex', alignItems: 'flex-end', width: 140, height: 50 }}>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start', width: '50%', paddingRight: 20 }}>
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'bold', fontFamily: 'roboto', textAlign: 'left' }}>{lang.card_sum}</p>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start', width: '15%' }}>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-end', width: '15%' }}>
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 20, fontWeight: 'bold', fontFamily: 'roboto' }}>{CardSum()} {lang.money}</p>
                                            </div>
                                        </div>
                                    }

                                </div>

                            </div>
                        </div>

                        {/* POZNAMKA K NAKUPU */}
                        {orderNote.trim() != '' ?
                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column', width: '100%', width: '100%', paddingTop: 20, paddingBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', paddingLeft: 20, paddingRight: 20 }}>
                                    <p style={{ margin: 0, color: global.theme_black, fontFamily: 'roboto', fontSize: 16, fontWeight: '600' }}>{lang.order_note}:</p>
                                    <p style={{ margin: 0, color: global.theme_black, fontFamily: 'roboto', fontSize: 16, fontWeight: '400' }}>{orderNote}</p>
                                </div>
                            </div>
                            : null}

                        {/* SUHLAS GDPR */}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column', width: '100%', width: '100%', paddingTop: 20, paddingBottom: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', paddingLeft: 20, paddingRight: 20 }}>
                                <FormControlLabel control={<Checkbox checked={gdpr} onChange={ChangeGDPR} style={{ color: global.theme_black, textAlign: 'left' }} />} label={<p style={{ fontWeight: 'normal', margin: 0, textAlign: 'left' }}>{lang.gdpr_agree}</p>} />
                            </div>
                        </div>

                        <div style={{ ...isSmall ? styles.Block : styles.BlockRowRaw }}>
                            <Button onClick={() => setShowRulesGDPR(true)} style={{ ...styles.ButtonLink, color: global.theme_dark_gray, width: 250 }}><u>{lang.gdpr}</u></Button>
                            <Button onClick={() => setShowRulesVOP(true)} style={{ ...styles.ButtonLink, color: global.theme_dark_gray, width: 250, marginTop: isSmall ? 10 : 0 }}><u>{lang.sell_docs}</u></Button>
                        </div>

                        {/* DODACIA DOBA */}
                        <div style={{ ...styles.BlockLeft, paddingTop: 30, paddingBottom: 10 }}>
                            <p style={{ ...styles.TextTiny, marginLeft: 50 }}>{lang.order_days}</p>
                        </div>

                        {/* POKRACOVAT */}
                        {isSmall ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', marginTop: 20, paddingTop: 30, paddingBottom: 150, borderTop: '1px solid ' + global.theme_gray }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                        <Button disabled={gdpr == true ? false : true} onClick={() => FinishOrder()} variant="contained" style={{ color: global.theme_white, backgroundColor: gdpr == false ? global.theme_light_gray : global.theme_black, fontSize: 14, width: 300, height: 40, textTransform: 'none', borderRadius: 20 }}>{lang.send_order}</Button>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 20 }}>
                                        <Button onClick={() => Back()} style={{ backgroundColor: global.theme_white, width: 200, height: 40, textTransform: 'none', color: global.theme_black, borderColor: global.theme_black, borderRadius: 20 }} variant="outlined">{lang.back_to_address}</Button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', marginTop: 20, paddingTop: 30, paddingBottom: 150, borderTop: '1px solid ' + global.theme_gray }}>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '50%' }}>
                                        <Button onClick={() => Back()} style={{ backgroundColor: global.theme_white, width: 200, height: 40, textTransform: 'none', color: global.theme_black, borderColor: global.theme_black, borderRadius: 20 }} variant="outlined">{lang.back_to_address}</Button>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '50%' }}>
                                        <Button disabled={gdpr == true ? false : true} onClick={() => FinishOrder()} variant="contained" style={{ color: global.theme_white, backgroundColor: gdpr == false ? global.theme_light_gray : global.theme_black, fontSize: 14, width: 300, height: 40, textTransform: 'none', borderRadius: 20 }}>{lang.send_order}</Button>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>

                : null}


            {
                activeStep == 3 ?
                    /*
                        ODOSLANE
                    */
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', maxWidth: global.screen_max, width: '100%', justifyContent: 'center', alignItems: 'center' }}>

                            {/* ZHRNUTIE - LABEL */}
                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column', width: '100%', width: '100%', paddingTop: 20, paddingBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                    <p style={{ fontSize: 26, margin: 0, color: global.theme_black, fontFamily: 'roboto', fontWeight: 'bold', marginBottom: 0 }}>{lang.order_sended_ok}</p>
                                    <p style={{ fontSize: 16, margin: 0, color: global.theme_black, fontFamily: 'roboto', fontWeight: 'normal', marginBottom: 10 }}>{lang.order_number}: {orderNumber}</p>
                                    <p style={{ fontSize: 16, margin: 0, marginTop: 20, color: global.theme_black, fontFamily: 'roboto', fontWeight: 'bold', marginBottom: 0 }}>{lang.order_sended_ok_text}: {email}</p>
                                    <p style={{ fontSize: 16, margin: 0, marginTop: 0, color: global.theme_black, fontFamily: 'roboto', fontWeight: 'normal', marginBottom: 10 }}>{lang.order_info}.</p>
                                </div>
                            </div>

                            {/* VERNOSTNE BODY - PRIHLASENEMU ZAKAZNIKOVI */}
                            {logedIn == true || register == true ?
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column', width: '100%', width: '100%', paddingTop: 20, paddingBottom: 10 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                        <p style={{ fontSize: 16, margin: 0, marginTop: 20, color: global.theme_black, fontFamily: 'roboto', fontWeight: 'bold', marginBottom: 0 }}>{lang.order_bonus}: {bonus} {lang.order_bonus_2}</p>
                                        <div style={{ marginTop: 20 }}>
                                            <Button onClick={() => setShowBonus(true)} style={{ margin: 0, padding: 0, color: global.theme_blue, fontSize: 12 }}>{lang.bonus_about}</Button>
                                        </div>
                                    </div>
                                </div>
                                : null}

                            {/* POKRACOVAT */}
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', paddingTop: 40, paddingBottom: 150 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                                    <Button onClick={() => GotoEshop()} variant="contained" style={{ color: global.theme_white, backgroundColor: global.theme_black, fontSize: 14, width: 200, height: 40, textTransform: 'none' }}>{lang.back_to_main}</Button>

                                </div>
                            </div>


                        </div>
                    </div>

                    : null
            }

            {showBonus == true ?
                <BonusInfo lang={lang} language={language} func={BonusInfoPress.bind(this)} />
                : null}

            {isChecking == true ?
                <Loading />
                : null}

            {isFinishing == true ?
                <Loading />
                : null}

            {showLogin == true ?
                <BannerTop typ={4} alias={alias} language={language} func={BannerTopPress.bind(this)} />
                : null}

            {showOrderError == true ?
                <OrderError lang={lang} language={language} func={OrderErrorPress.bind(this)} />
                : null}

            {showRegisterError == true ?
                <OrderRegisterError lang={lang} language={language} func={OrderRegisterErrorPress.bind(this)} />
                : null}

            {showRulesVOP == true ?
                <DialogRules typ={0} salon={salon} func={() => setShowRulesVOP(false)} />
                : null}

            {showRulesGDPR == true ?
                <DialogRules typ={1} salon={salon} func={() => setShowRulesGDPR(false)} />
                : null}

            <Footer language={language} islogged={logedIn} />
        </div >
    )

}
