/*

    OBJEDNAVKA PRODUKTOV NA SKLAD
    OBSOLETE

    e-mail odkaz na objednávku
*/

import React, { useEffect, useState, useRef } from 'react';
import './react/App.css';
import './react/w3.css';
import { Routes, Route, Link, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import "animate.css/animate.min.css";
import { GetSize, NavigationMenu, Footer, Debug, LoadSalon, SaveSalonSettings, LoadSalonSettings, LoadLogedIn, LoadLanguage, LoadAlias, Verify, SaveLanguage, ChangeLanguage, ChangeLanguageURL, Loading, LoadClient, NeedRedraw, SaveSalon, dbURL, SaveTime, LoadProducts, EncodeValue, BottomMenu, LoadCategories, SaveProducts, SaveProductsTime, LoadPublic, SaveSections, SaveCategories, NeedRedrawProducts, ProductCard, SaveCardQuantity, LoadCardQuantity, LoadCookies, CookiesInfo, SaveCookies, EshopBusy } from './Items.js';
import { GetLanguage } from './Items.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme2-6.css";
import { styles, Styles } from './styles.js';


export const Orders = (props) => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(true);
    const [language, setLanguage] = useState(ChangeLanguageURL());
    const [theme, setTheme] = useState(0);

    const [salon, setSalon] = useState(null);
    const [agent, setAgent] = useState(null);
    const [code, setCode] = useState(null);
    const [note, setNote] = useState(null);
    const [date, setDate] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const [link, setLink] = useState('');
    const [products, setProducts] = useState(null);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const logo_circle = require('./react/logo_black_circle.png');

    var lang = GetLanguage(language);
    let params = useParams();

    useEffect(() => {

        db_order();

        const element = document.getElementById('id-start');

        if (element != null) {
            element.scrollIntoView({ behavior: 'smooth' });
        }

        return () => {
        };

    }, []);

    /* *******************************************************************************
                ZISKANIE UDAJOV OBJEDNAVKY
        ******************************************************************************* */
    const db_order = async () => {

        setBusy(true);

        try {
            const response = await fetch(
                dbURL() + 'orders', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: params.id,
                    salon_id: params.salon_id,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setSalon(json.salon);
                setAgent(json.agent);
                setCode(json.code);
                setNote(json.note);
                setDate(json.date);
                setProducts(json.products);
                setQuantity(json.quantity);
                setLink(json.link);

                var l = json.language;
                if (l == 0) {
                    setLanguage(0);
                } else {
                    setLanguage(1);
                }

            }
            setBusy(false);

        } catch (error) {
            setBusy(false);
            Debug(error);
        }

    };

    return (
        <div id={'id-start'} style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }} >
            {isBusy == false ?
                <div style={{ ...styles.BlockMaxLarge }}>
                    <div style={{ ...styles.BlockRow, backgroundColor: global.theme_gray, padding: 20 }}>
                        <div style={{ ...styles.BlockLeft }}>
                            <img src={'https://www.kozmeticke-salony.sk/images/app/email_logo.png'} style={{ width: 200, height: 200 }} ></img>
                        </div>
                        <div style={{ ...styles.BlockRight }}>
                            <p style={{ ...styles.TextLarge, fontWeight: 'bold' }}>{lang.booking}</p>
                            <p>{lang.order_number}: {code}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.BlockLeft, padding: 20 }}>
                        <p style={{ ...styles.TextTiny, fontWeight: 'bold' }}>{lang.orderer}:</p>
                        <p style={{ ...styles.TextTiny }}>{salon.salon_name}</p>
                        <p style={{ ...styles.TextTiny }}>{salon.salon_street} {salon.salon_street_number}</p>
                        <p style={{ ...styles.TextTiny }}>{salon.salon_psc} {salon.salon_town}</p>
                        <p style={{ ...styles.TextTiny, marginTop: 10 }}>{lang.email} {salon.email}</p>
                        <p style={{ ...styles.TextTiny }}>{lang.mobil} {salon.mobil}</p>
                    </div>

                    {/* POZNAMKA */}
                    {note != '' ?
                        <div style={{ ...styles.BlockLeft, padding: 20 }}>
                            <p style={{ ...styles.TextTiny, fontWeight: 'bold', textAlign: 'left' }}>{lang.note}:</p>
                            <div style={{ ...styles.BlockLeft, padding: 10 }}>
                                <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{note}</p>
                                <p style={{ ...styles.TextTiny, textAlign: 'left', marginTop: 20 }}>{salon.name} {salon.surname}</p>
                            </div>
                        </div>
                        : null}

                    {/* ZOZNAM PRODUKTOV */}
                    <div style={{ ...styles.BlockLeft, padding: 20, border: '1px solid ' + global.theme_light_gray }}>
                        <div style={{ ...styles.BlockLeft, flexDirection: 'row', height: 60, alignItems: 'center', justifyContent: 'flex-start', backgroundColor: global.theme_dark_gray, paddingLeft: 10, paddingRight: 10 }} >
                            <div style={{ ...styles.Block, width: 50 }}>
                                <p style={{ ...styles.TextXXTiny, color: global.theme_white, fontWeight: 'bold' }}>{'p.č.'}</p>
                            </div>
                            <div style={{ ...styles.Block, width: 100 }}>

                            </div>
                            <div style={{ ...styles.BlockLeft, width: 120 }}>
                                <p style={{ ...styles.TextXXTiny, color: global.theme_white, fontWeight: 'bold' }}>{'kód'}</p>
                            </div>

                            <div style={{ ...styles.BlockLeft }}>
                                <p style={{ ...styles.TextXXTiny, color: global.theme_white, fontWeight: 'bold' }}>{'produkt'}</p>
                            </div>
                            <div style={{ ...styles.Block, width: 80 }}>
                                <p style={{ ...styles.TextXXTiny, color: global.theme_white, fontWeight: 'bold' }}>{lang.amount}</p>
                            </div>
                        </div>
                        {products.map(item => {
                            return (
                                <div key={item.id} style={{ ...styles.BlockLeft, flexDirection: 'row', borderBottom: '1px solid ' + global.theme_light_gray, height: 60, alignItems: 'center', justifyContent: 'flex-start', backgroundColor: item.number % 2 == 0 ? global.theme_gray : global.theme_white, paddingLeft: 10, paddingRight: 10 }} >
                                    <div style={{ ...styles.Block, width: 50 }}>
                                        <p style={{ ...styles.TextTiny }}>{item.number}.</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 100 }}>
                                        <img src={global.images_url + '/' + item.image} style={{ width: 40, height: 40 }} />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 120 }}>
                                        <p style={{ ...styles.TextTiny }}>{item.code}</p>
                                    </div>

                                    <div style={{ ...styles.BlockLeft }}>
                                        <p style={{ ...styles.TextTiny }}>{item.label}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 80 }}>
                                        <p style={{ ...styles.TextTiny }}>{item.quantity}</p>
                                    </div>
                                </div>
                            )
                        })}
                        <div style={{ ...styles.BlockLeft, flexDirection: 'row', height: 50, alignItems: 'center' }} >
                            <div style={{ ...styles.BlockRight, paddingRight: 20 }}>
                                <p style={{ ...styles.TextTiny, fontWeight: 'bold' }}>{lang.booking_all}</p>
                            </div>
                            <div style={{ ...styles.Block, width: 80 }}>
                                <p style={{ ...styles.TextTiny, fontWeight: 'bold' }}>{quantity}</p>
                            </div>
                        </div>
                    </div>

                    {/* FOOTER */}
                    <div style={{ ...styles.BlockRow, marginTop: 40, backgroundColor: '#222222', padding: 20, paddingBottom: 50 }}>
                        <div style={{ ...styles.Block, paddingRight: 20 }}>
                            <img src={'https://www.kozmeticke-salony.sk/images/app/email_logo_white.png'} style={{ maxWidth: 150, maxHeight: 100 }} />
                        </div>
                        <div style={{ ...styles.BlockLeft }}>
                            <p style={{ ...styles.TextSmall, color: global.theme_white }}>{salon.salon_name}</p>
                            <p style={{ ...styles.TextTiny, color: global.theme_white, marginTop: 20 }}>{lang.email}: {salon.email}</p>
                            <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.mobil}: {salon.mobil}</p>
                        </div>

                    </div>
                    <div style={{ ...styles.BlockRow, backgroundColor: '#222222', padding: 20, paddingBottom: 50 }}>
                        <p style={{ ...styles.TextTiny, color: global.theme_white }}>{link}</p>
                    </div>
                </div>
                : null}
        </div >
    )

}
