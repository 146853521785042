import React, { useEffect, useState, useRef } from 'react';

//import logo from '/react_images/logo.png';
import './react/App.css';
import './react/w3.css';
import Button from '@mui/material/Button';
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { animated, useSpring } from '@react-spring/web'
import { useMediaQuery } from 'react-responsive'
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { sk, cz } from './globals.js';

import { useDispatch, useSelector } from 'react-redux';
import { removeItem, addItem, updateItem, ChangeId } from './store/data.js';
import { ChangeLanguageURL, LoadAlias, dbURL, SaveAlias, SaveClient, SaveLanguage, SaveLogedIn, SavePublic, SaveSalon, SaveSalonSettings, Verify, Debug, GetSize, BannerBlack, BannerImage, Footer, LoadLanguage, BonusInfo } from './Items';
import TextField from '@mui/material/TextField';


export const Bonus = () => {
    const navigate = useNavigate();
    const [language, setLanguage] = useState(0);
    const [isBusy, setBusy] = useState(false);

    var lang = language == 0 ? sk : cz;

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const pageRef = useRef(null)

    useEffect(() => {
        document.body.style.zoom = "100%";
        setLanguage(LoadLanguage());

        // SPAT NA VRCH STRANKY
        const timer = setTimeout(() => {
            if (pageRef != null) {
                Debug('TIMER');
                pageRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);

        return () => {
        };

    }, []);


    const GoBack = () => {
        navigate(-1);
    }

    const BonusInfoPress = () => {

    }

    return (
        <div ref={pageRef} style={{ paddingTop: 20, paddingBottom: 30 }}>
            <BonusInfo typ={1} lang={lang} language={language} func={BonusInfoPress.bind(this)} />
        </div>
    )
}
