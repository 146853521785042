import React, { useEffect, useState } from 'react';
import './react/App.css';
import './react/w3.css';
import Button from '@mui/material/Button';
import { Routes, Route, Link, useNavigate, useParams } from "react-router-dom";
import { animated, useSpring } from '@react-spring/web'
import { useMediaQuery } from 'react-responsive'
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { sk, cz } from './globals.js';

import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from './store/data.js';
import { GetSize, NavigationMenu, Footer, Debug, LoadSalon, SaveSalonSettings, LoadSalonSettings, LoadLogedIn, LoadLanguage, LoadAlias, Verify, SaveLanguage, ChangeLanguage, ChangeLanguageURL, Loading, LoadClient, NeedRedraw, SaveSalon, dbURL, SaveTime, BottomMenu, BannerImage, BannerSlider, LoadPublic, PaginationBar, ProductCard, Ordering, EshopMenu, SaveProducts, SaveSections, SaveCategories, LoadSections, LoadCategories, LoadProducts, EncodeValue, DecodeValue, NeedRedrawProducts, SaveProductsTime, SaveOrderTyp, LoadOrderTyp, LoadCardQuantity, LoadCookies, CookiesInfo, SaveCookies, LoadToken, CheckToken } from './Items.js';
import { BannerTop, LoadProductNames, SaveProductNames, GetLanguage } from './Items.js';
import { Breadcrumbs, IconButton, Typography } from '@mui/material';

export const Search = (props) => {
    const navigate = useNavigate();
    let params = useParams();

    const [theme, setTheme] = useState(0);
    const [isBusy, setBusy] = useState(false);
    const [isBusyProducts, setBusyProducts] = useState(false);
    const [salon, setSalon] = useState(false);
    const [settings, setSettings] = useState(false);
    const [language, setLanguage] = useState(0);
    const [alias, setAlias] = useState(params.alias);
    const [client, setClient] = useState([]);
    const [productNames, setProductNames] = useState([]);
    const [logedIn, setLogedIn] = useState(false);
    const [showCookies, setShowCookies] = useState(false);

    // SEKCIE
    const [sections, setSections] = useState(false);

    //KATEGORIE
    const [categories, setCategories] = useState(false);

    const [cardQuantity, setCardQuantity] = useState(0);
    const [products, setProducts] = useState([]);
    const [productsFiltered, setProductsFiltered] = useState([]);
    const [productsNew, setProductsNew] = useState([]);
    const [section, setSection] = useState([]);
    const [sectionID, setSectionID] = useState(0);
    const [sectionLabel, setSectionLabel] = useState('');
    const [category, setCategory] = useState([]);
    const [categoryID, setCategoryID] = useState(0);
    const [categoryLabel, setCategoryLabel] = useState('');


    const [search, setSearch] = useState([]);
    const [searchLabel, setSearchLabel] = useState('');
    const [orderTyp, setOrderTyp] = useState('');
    const [page, setPage] = useState(0);
    const [showMore, setShowMore] = useState(false);
    const [productsPage, setProductsPage] = useState(0);
    const [productsMax, setProductsMax] = useState(0);
    const [product, setProduct] = useState([]);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const logo_circle = require('./react/logo_black_circle.png');


    var lang = GetLanguage(language);
    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            if (LoadCookies() == null) {
                setShowCookies(true);
            }
        }

        return () => {
        };

    }, []);


    /* *******************************************************************************
        ZISKANIE UDAJOV Z TOKENU
    ******************************************************************************* */
   /*
    const db_token = async (token) => {
        setBusy(true);

        try {
            const response = await fetch(
                dbURL() + 'token', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token
                })
            })

            const json = await response.json();
            var ok = false;
            if (json.ok > 0) {
                if (json.verified == true) {
                    ok = true;
                    setSalon(json.salon);
                    setAlias(json.salon.alias);
                    setSettings(json.settings);
                    setLanguage(json.salon.language);
                    setClient(json.client);

                    setSearchLabel(params.search);
                    db_products_search(json.salon.id, params.search);
                }
            }

            if (ok == false) {
                setBusy(false);
                navigate('/logout');
            }

        } catch (error) {
            setBusy(false);
            Debug(error);
        }
    };
    */

    const TokenResult = (typ, json) => {

        if (typ == true) {
            setSalon(json.salon);
            setAlias(json.salon.alias);
            setSettings(json.settings);
            setLanguage(json.salon.language);
            setClient(json.client);
            setLogedIn(json.logged);

            setSearchLabel(params.search);
            db_products_search(json.salon.id, params.search);
        } else {
            navigate('/logout');
        }
    }

    const GetSearch = (txt) => {
        // SEARCH RETAZEC        
        if (txt.trim() != '') {
            var search_ = txt.split('+');
            setSearch(search_);
            var tmp = '';
            search_.forEach(element => {
                if (tmp != '') {
                    tmp = tmp + ' ';
                }
                tmp = tmp + element;
            });
            setSearchLabel(tmp);
            db_products_search(salon.id, txt);
        }
    }


    /* *******************************************************************************
        NAHRATIE PRODUKTOV
    ******************************************************************************* */
    const db_products_search = async (salon_id, search_) => {

        setBusy(true);

        try {
            const response = await fetch(
                dbURL() + 'products_search_v2', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: salon_id,                    
                    search: search_
                })
            })

            const json = await response.json();
            Debug('JSON-PRODUCTS', json.products);
            if (json.ok > 0) {
                setProducts(json.products);
                setProductsFiltered(json.products);

                RedrawProducts();
            }
            setBusy(false);

        } catch (error) {
            setBusy(false);
            Debug(error);
        }

    };

    const BannerTopPress = (value, data) => {
        if (value == 1) {
            // PRIHLASENIE SA UZIVATELA -> presmerovanie na jeho salon
            setSalon(LoadSalon());
            setSettings(LoadSalonSettings());
            setLanguage(LoadLanguage());
            setClient(LoadClient());
            setCardQuantity(LoadCardQuantity());
            setLogedIn(true);
            setBusy(false);
        }

        // AKCIA Z BANNERU
        if (value == 2) {
            // ODHLASIT SA
            navigate('/logout');
        }
        if (value == 3) {
            // Moj ucet
            navigate('/' + alias + '/account');
        }
    }



    const ShowMore = () => {
        var page_ = page;
        if (page_ * global.items_max < (productsMax - global.items_max)) {
            page_ += 1;
        }
        setPage(page_);
        RedrawProducts(page_, sectionID, categoryID, search, orderTyp);
        var tmp = EncodeValue(sectionID, categoryID, parseInt(page_));
        navigate('/' + alias + '/eshop/category/' + tmp);
        //navigate('/' + alias + '/eshop/' + sectionID + '/' + categoryID + '/' + parseInt(page_));
    }


    const RedrawProducts = () => {

    }


    const ProductCardPress = (item) => {
        //setProduct(item);
        navigate("/" + alias + '/eshop/' + item.slug);
    }


    const OrderingPress = (value) => {
        setOrderTyp(value);
        SaveOrderTyp(value);
        RedrawProducts(0, sectionID, categoryID, search, value);
    }


    const EshopMenuPress = (section_, category_) => {
        if (category_ == 0) {
            // ZMENA LEN SEKCIE
            //ChangeSection(section_);
        } else {
            //ChangeCategory(section_, category_);
        }
    }


    const SearchRedraw = (search_) => {
        GetSearch(search_);
        navigate("/" + props.alias + '/eshop/search/' + search_);
    }


    const CookiesInfoPress = (value) => {
        setShowCookies(false);
        SaveCookies(true);
    }

    return (
        <div id={'id-product-0'}>
            {isSmall ? null :
                <BannerTop theme={theme} chooseSalon={true} client={client} logged={client == false ? false : true} alias={alias} salon={salon} logedIn={logedIn} language={language} func={BannerTopPress.bind(this)} />
            }
            <NavigationMenu theme={theme} size={() => GetSize()} client={client} logged={client == false ? false : true} sectionID={sectionID} categoryID={categoryID} language={language} sections={sections} categories={categories} showLanguage={false} logedin={logedIn} alias={alias} salon={salon} index={-1} quantity={cardQuantity} products={productNames} logo={true} func={EshopMenuPress.bind(this)} />
            <CheckToken alias={alias} func={TokenResult.bind(this)} />

            <p style={{ margin: 0, fontSize: 24, fontFamily: 'roboto', fontWeight: 'normal' }}>{lang.search_result}</p>
            <p style={{ margin: 0, fontSize: 30, fontFamily: 'roboto', fontWeight: 'bold', textAlign: 'center' }}>"{searchLabel}"</p>

            {/*
                // ************************************************************************
                // ZOZNAM PRODUKTOV
                // ************************************************************************
                */}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

                <div style={{ display: 'flex', width: '100%', maxWidth: global.screen_max, justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: 0, paddingBottom: 10 }}>
                        <h2 style={{ fontWeight: 'bold', fontFamily: 'roboto' }}>{sectionLabel}</h2>
                        {productsFiltered.length > 0 ?
                            <p>{lang.products_finded}: {productsFiltered.length}</p>
                            :
                            <p>{lang.products_finded_none}</p>
                        }
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', maxWidth: 950 }}>

                    {/* LAVA STRANA - MENU */}
                    {/*
                        <div style={{ width: 300 }}>
                        </div>
                        */}

                    {/* PRAVA STRANA - PRODUKTY */}
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 5 }}>

                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>

                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20, flexDirection: 'column' }}>
                                    < div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center' }}>
                                        {
                                            productsFiltered.map(item => {
                                                return (
                                                    <div key={item.id} >
                                                        <ProductCard ready={salon.eshop_ready} size={isSmall ? 150 : 240} language={language} item={item} func={ProductCardPress.bind(this)} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    {showMore == true ?
                                        <Button onClick={() => ShowMore()} variant="outlined" style={{ color: global.theme_black }}>{lang.show_more}</Button>
                                        : null}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showCookies == true ?
                <CookiesInfo lang={lang} salon={salon} func={CookiesInfoPress.bind(this)} />
                : null}

            <BottomMenu language={language} salon={salon}/>
            <Footer language={language} />
        </div>
    )

}
