// **********************************************************************
// DEBUG SETTINGS + VERSION
// **********************************************************************
global.version = '1.02'; // Zmena vynúti načítanie nových obrázkov
global.testing = process.env.REACT_APP_ESTHEDERM_ESHOP_TEST == 0 ? false : true;
// **********************************************************************


global.debugging = global.testing;
global.redraw_time = 15; // dobaplatnosti údajov 1 v minutach
global.items_max = 10; // pagination - max.items na stránke

global.menu_width = 240;

// **********************************************************************
// DATABAZA
// **********************************************************************
global.db_url = global.testing == true ? 'https://clients.datadream-test.sk/app_web/' : 'https://www.esthedermsalon.sk/app_web/';
global.title = 'Esthederm salon'
global.images_url = 'https://kozmeticke-salony.sk/public';


// POVOLENIE PREDAT MOJE PRODUKTY
global.my_products = true;

// **********************************************************************
// DISPLAY SIZE
// **********************************************************************
global.device_small = 600;
global.device_medium = 992;
global.screen_max = 950;

// **********************************************************************
// ORDER BY
// **********************************************************************
global.order_by_name = 0;
global.order_by_price_low = 1;
global.order_by_price_high = 2;


// **********************************************************************
// RULES - GDPR, COOKIES, VOP
// **********************************************************************
global.rules_vop = 0;
global.rules_gdpr = 1;
global.rules_cookies = 2;

// **********************************************************************
// COLORS
// **********************************************************************
global.theme_back = '#FAFAFA';
global.theme_transparent = '#00000000';

global.theme_white = '#ffffff';
global.theme_black = '#000000';
global.theme_black_opacity = '#000000bb';

global.theme_lighteen = '#D7EEFF';
global.theme_lighter = '#BBD6FD';
global.theme_light = '#95B2DC';
global.theme_medium = '#5F7AA1';
global.theme_dark = '#3D5273';
global.theme_darker = '#29364A';

global.theme_lighteen_blue = '#E2ECFF';
global.theme_lighter_blue = '#92BBEC';
global.theme_light_blue = '#2A8EFF';
global.theme_blue = '#205CCA';
global.theme_dark_blue = '#003BA4';
global.theme_darker_blue = '#041A47';

global.theme_lighteen_red = '#FFE4E2';
global.theme_lighter_red = '#F99891';
global.theme_light_red = '#F77268';
global.theme_red = '#F44336';
global.theme_dark_red = '#C3170B';

global.theme_lighteen_green = '#E2FFE3';
global.theme_light_green = '#A6FA9D';
global.theme_green = '#46F436';
global.theme_dark_green = '#18BB18';
global.theme_darker_green = '#15A115';
global.theme_darkest_green = '#107010';

global.theme_yellow = '#F4B549';
global.theme_badge = '#A12E28';
global.theme_placeholder = '#cccccc';
global.theme_light_violet = '#FFAAFF';
global.theme_none = '#00000000';

global.theme_gray = '#eeeeee';
global.theme_grayer = '#f7f7f7';
global.theme_xxgray = '#CCCCCC';
global.theme_dark_gray = '#666666';
global.theme_darker_gray = '#252525';
global.theme_medium_gray = '#888888';
global.theme_light_gray = '#dedede';

global.theme_selected = '#4D617B';
global.theme_orange = 'orange';
global.theme_lighteen_violet = '#E9E2FF';
global.theme_light_cyan = '#80A0FF';
global.theme_violet = '#BB00BB';
global.theme_brown = 'brown';
global.theme_logo_background = '#D0DDEF';

// IKONY
global.icon_user = 'user';
global.icon_www = 'globe';
global.icon_email = 'envelope';
global.icon_forum = 'comments';
global.icon_send = 'paper-plane';
global.icon_point = 'circle';
global.icon_close = 'times-circle';
global.icon_cancel = 'times-circle';
global.icon_mobil = 'mobile';
global.icon_right = 'angle-right';
global.icon_left = 'angle-left';
global.icon_first = 'angle-double-left';
global.icon_last = 'angle-double-right';
global.icon_edit = 'edit';
global.icon_ok = 'check-circle';
global.icon_error = 'times';
global.icon_plus = 'plus';
global.icon_shop = 'shopping-cart';
global.icon_settings = 'cog';
global.icon_stats = 'calculator';
global.icon_pdf = 'file';
global.icon_file = 'file';
global.icon_image = 'image';
global.icon_birthday = 'birthday-cake';
global.icon_edit = 'edit';
global.icon_list = 'list';
global.icon_car = 'car';
global.icon_wait = 'hourglass-half';
global.icon_todo = 'pencil';
global.icon_delete = 'trash';

// VELKOST OBRAZOVKY
global.size_small = 0;
global.size_medium = 1;
global.size_large = 2;

global.appstore = 'https://apps.apple.com/sk/app/kozmetick%C3%A9-sal%C3%B3ny-klient/id1596728029?l=sk';
global.googleplay = 'https://play.google.com/store/apps/details?id=com.salon_klient_app';

// PISMO
global.font_xxtiny = 12;
global.font_tiny = 14;
global.font_small = 16;
global.font_normal = 18;
global.font_large = 20;
global.font_xlarge = 24;
global.font_xxlarge = 28;
global.font_label = 28;


// ************************************************************************************
// SLOVENCINA
// ************************************************************************************
const sk = {
    title: 'Kozmetické salóny',
    www: 'www.esthedermsalon.sk',
    esthederm_salon: 'Esthederm Salon',
    face_id: 1,
    body_id: 2,
    summer_id: 3,
    voucher_value: 400,
    copyright: 'Copyright',
    country: 'Slovensko',
    home: 'Domov',
    eshop: 'E-shop',
    eshop_text: 'Začať nakupovať',
    my_account: 'Môj účet',
    logout: 'Odhlásiť sa',
    salon_choose: 'Vybrať salón',
    contact: 'Kontakt',
    ie_salons: 'Kozmetické salóny Institut Esthederm',
    salons: 'Kozmetické salóny',
    ie: 'Institut Esthederm',
    choose_salon: 'Vyberte si e-shop kozmetických salónov',
    gdpr: 'Ochrana osobných údajov',
    cookies_rules: 'Zásady používania súborov cookies',
    sell_docs: 'Všeobecné obchodné podmienky',
    services: 'Služby',
    eshops: 'Vybrať salon',

    login: 'Prihlásenie',
    login_email: 'E-mailová adresa',
    loginname: 'Prihlasovacie meno (e-mail)',
    loginname_error: 'Prihlasovanie meno musí byť e-mailová adresa',
    login_data: 'Prihlasovacie údaje',
    email_error: 'Nesprávny formát e-mailovej adresy',
    username: 'Prihlasovacie meno',
    password: 'Heslo',
    password_old: 'Pôvodné heslo',
    password_old_err: 'Pôvodné heslo je nesprávne',
    password_: 'Heslo (minimálna dĺžka 8 znakov)',
    password_new: 'Nové heslo (minimálne 8 znakov)',
    password_new_again: 'Zopakujte nové heslo',
    passwords_not_equal: 'Zadané heslá sa nezhodujú',
    password_ok: 'Heslo bolo úspešne zmenené',
    password_error: 'Pri zmene hesla nastala chyba. Skúste požiadavku opakovať.',
    password_error_len: 'Minimálna dĺžka hesla musí byť 8 znakov',
    password_change: 'Zmeniť heslo',
    password_change_: 'Zmena hesla',
    login_button: 'Prihlásiť sa',
    lost_password_button: 'Zabudli ste heslo?',
    login_error: 'Nesprávne prihlasovacie údaje!',
    lost_password: 'Zabudnuté heslo',
    lost_login_error: 'Zadaná e-mailová adresa sa nenachádza v systéme',
    login_pin_code_error: 'Nesprávny overovací kód',
    login_pin_code: 'Zadajte kód z e-mailu',
    login_email_send: 'Na Vašu e-mailovú adresu sme zaslali overovací kód. Skontrolujte aj spamový adresár.',
    password_changed: 'Zmena hesla prebehla úspešne',

    email_adress: 'E-mailová adresa',
    email: 'E-mail',
    send: 'Poslať',
    logout_question: 'Chcete sa odhlásiť',
    cancel: 'Zrušiť',
    call: 'Volajte',
    basket: 'Košík',
    ie: 'Institut Esthederm',
    search: 'Hľadať',
    search_text: 'Hľadať produkt',
    search_label: 'Hľadať produkt v e-shope',
    distance: 'Vzdialenosť hľadania',
    salon_search_label: 'Hľadanie najbližších kozmetických salónov',
    salon_search_label_2: 'podľa Vášho bydliska',
    your_town: 'Miesto bydliska',
    search_result: 'Výsledok hľadania',
    salons_searched: 'Nájdené kozmetické salóny',
    salons_count: 'Počet salónov',
    search_salons: 'Hľadať salóny',
    search_salons_none: 'Žiadne salóny v zadanom okolí',
    login_error: 'Nesprávne prihlasovacie údaje',
    services_ie: 'Ošetrenia Institut Esthederm',
    ie_text1: 'Kozmetický salón',
    ie_text2: 'Ako sa všetko okolo nás mení a svet sa pohybuje rýchlejšie, ošetrenie v salóne INSTITUT ESTHEDERM, Vám umožňuje zatlačiť tlačidlo pauzy a ponoriť sa do maximálnej relaxácie.',
    ie_text3: 'Objavíte škálu vnemov, účinné rituály a pochopíte silu dotyku, vďaka jemným rukám.',
    ie_text4: 'Naše unikátne patenty a techniky ošetrení sú navrhnuté v spolupráci s odborníkmi v starostlivosti o pleť, k návratu krásy a mladistvosti každej pokožky a to dlhodobo.',
    service_1: 'Čistenie',
    service_2: 'Základné prevýchovné ošetrenia',
    service_3: 'Intenzívne ošetrenia',
    service_4: 'Sezónne ošetrenia',
    service_5: 'Regeneračné ošetrenia',
    service_hands: 'Ruky',
    service_body: 'Telo',
    service_face: 'Tvár',
    contact_us: 'Kontaktujte nás',
    mobil: 'Mobil',
    write_us: 'Chcete sa na niečo opýtať? Napíšte nám.',
    opening_hours: 'Otváracie hodiny',
    day_1: 'Pondelok',
    day_2: 'Utorok',
    day_3: 'Streda',
    day_4: 'Štvrtok',
    day_5: 'Piatok',
    day_6: 'Sobota',
    day_7: 'Nedeľa',
    day_1_place: '8:00 - 12:00   13:00 - 16-00',
    day_2_place: '8:00 - 12:00',
    day_3_place: 'na objednávku',
    day_4_place: 'zatvorené',
    name: 'Meno',
    surname: 'Priezvisko',
    name_surname: 'Vaše meno a priezvisko',
    email_answer: 'E-mail',
    email_text: 'Text správy',
    send_msg: 'Odoslať správu',
    send_msg_ok: 'Správa bola úspešne odoslaná',
    send_msg_err: 'Pri odosielaní správy nastala chyba',
    send_err_name: 'Chýba meno a priezvisko',
    send_err_email: 'Nesprávny formát e-mailu',
    send_err_text: 'Chýba text správy',
    services_offer: 'Ponuka služieb a ošetrení',
    services_offer_text: 'kozmetického salóna',
    services_ie: 'Ošetrenia Institut Esthederm',
    services_my: 'Ostatné služby',
    ie_motto: 'INSTITUT ESTHEDERM - EKOBIOLÓGIA V SLUŽBÁCH ESTETIKY',
    close: 'Zavrieť',
    back: 'Späť',
    service_time: 'Dĺžka ošetrenia',
    minutes: 'minút',
    price: 'Cena',
    money: '€',
    description: 'Popis',
    service_description: 'Popis služby',
    history: 'História',
    histrory_services: 'Absolvované ošetrenia',
    histrory_products: 'Zakúpené produkty',
    settings: 'Nastavenia',
    info: 'Informácie',
    bonus_system: 'Vernostný systém',
    bonus: 'Vernostné body',
    bonus_count: 'Počet vernostných bodov',
    user_info: 'Osobné údaje',
    user_post: 'Poštová adresa',
    vouchers: 'Darčekové poukážky',
    voucher_none: 'Žiadne darčekové poukážky',
    pieces: 'ks',
    bonus_sum: 'Celkový počet vernostných bodov',
    voucher_get: 'Za vernostné body získavate',
    vouchers_all: 'Celkový počet darčekových poukážok',
    vouchers_use: 'Darčekové poukážky môžete použiť na zľavu ošetrenia INSTITUT ESTHEDERM v našom kozmetickom salóne',
    bonus_about: 'Viac o vernostnom systéme',
    date: 'Dátum',
    category: 'Kategória',
    category_choose: 'Výber kategórie',
    category_products: 'Kategórie produktov',
    details: 'Detaily',
    code: 'Obj.kód',
    orders: 'História nákupov',
    orders_products: 'Objednávky produktov z e-shopu',
    card_trade: 'Doprava',
    card_payment: 'Platba',
    sume: 'Suma',

    order_status_text: 'Po zmenení stavu objednávky, systém automaticky odošle e-mail zákazníkovi',
    order_created: 'Vytvorená',
    order_in_proccess: 'V príprave',
    order_ready: 'Pripravená na vydaj',
    order_sended: 'Odoslaná',
    order_finished: 'Vybavená',
    order_canceled: 'Zrušená',
    order_cancel_request: 'Žiadosť o zrušenie objednávky',
    order_cancel_request_sended: 'Odoslaná požiadavka o zrušenie objednávky',
    order_cancel: 'Zrušiť objednávku',
    order_cancel_question: 'Zrušit objednávku produktov na sklad',
    order_cancel_question_text: 'Žiadosť o zrušenie objednávky bude odoslaná obchodnému zástupcovi',
    order: 'Objednávka',
    order_kurier: 'Doručovaná kuriérom',
    order_waiting: 'Čaká na spracovanie',

    eur_piece: 'Cena za 1 kus',
    order_quantity: 'Počet zakúpených kusov',
    edit: 'Upraviť',
    save: 'Uložiť',
    number: 'Číslo',
    street: 'Ulica',
    town: 'Mesto',
    psc: 'psč',
    email_error: 'Zadanú E-mailovú adresu už používa iný účet',
    update_error: 'Pri ukladaní zmien nastala chyba. Skúste požiadavku opakovať.',
    account_update_error: 'Pri ukladaní zmien nastala chyba.',
    required: 'Povinný údaj',
    product_in_order: 'Zakúpené produkty',

    verification_email_error: 'Nesprávna e-mailová adresa',
    verification_code: 'Overovací kód',
    verification_code_enter: 'Overovací kód sme odoslali na vášu e-mailovú adresu. Zadajte kód z vášho e-mailu',
    verification_code_error: 'Nesprávny kód',
    verification_code_resend: 'Nedostali ste overovací kód? Odoslať kód.',
    password_change: 'Zmena hesla',
    password_new: 'Zadajte nové heslo (minimálne 8 znakov)',
    password_new_again: 'Zopakujte nové heslo',
    passwords_not_equal: 'Zadané heslá sa nezhodujú',
    password_ok: 'Heslo bolo úspešne zmenené',
    password_error: 'Pri zmene hesla nastala chyba. Skúste požiadavku opakovať.',
    password_min_len: 'Minimálna dĺžka hesla je 8 znakov',
    verify: 'Overiť',
    town_error: 'Zadané mesto sa nenašlo',

    eshop_hands: 'Starostivosť o ruky',
    eshop_body: 'Starostivosť o telo',
    eshop_face: 'Starostivosť o tvár',
    eshop_summer: 'Opaľovanie',
    eshop_new_products: 'Nové produkty v e-shope',
    ordering: 'Zoradiť podľa',
    ordering_name: 'Názvu',
    ordering_price_low: 'Od najnižšej ceny',
    ordering_price_high: 'Od najvyššej ceny',
    show_more: 'Zobraziť ďalšie',
    buy: 'Do košíka',
    instock: 'Skladom',
    nostock: 'Do troch dní',
    interest_products: 'Mohlo by vás zaujímať',
    search_result: 'Výsledok vyhľadávania výrazu',
    search_min: 'Zadajte aspoň dva znaky',
    products_finded: 'Nájdených produktov',
    products_finded_none: 'Hľadaný výraz nemôžeme v našom katalógu nájsť. Skúste, prosím, použiť iný výraz.',
    added_to_card: 'Pridané do košíka',
    continue_to_shop: 'Pokračovať v nákupe',
    continue_to_card: 'Pokračovať do košíka',
    card: 'Nákupný košík',
    card_sum: 'Celková cena vrátane DPH',
    back_to_shop: 'Späť do obchodu',
    to_checkout: 'K pokladni',
    checkout_step_1: 'Doprava a platba',
    checkout_step_2: 'Adresa dodania',
    checkout_step_3: 'Zhrnutie objednávky',
    trade: 'Spôsob dopravy',
    payment: 'Spôsob platby',
    free: 'zadarmo',
    continue: 'Pokračovať',
    send_order: 'Objednať s povinnosťou platby',
    client_info: 'Informácie o Vás',
    client_address: 'Adresa doručenia',
    client_contact: 'Kontaktné údaje',
    back_to_trade: 'Späť na výber dopravy',
    back_to_address: 'Zpäť na údaje',
    products_price: 'Cena produktu',
    sum_with_dph: 'Celkom vrátane DPH',
    sumar: 'Zhrnutie objednávky',
    gdpr_agree: 'Súhlasím so všeobecnými obchodnými podmienkami a spracovaním osobných údajov.',
    order_sended_ok: 'Ďakujeme za objednávku.',
    order_sended_ok_text: 'Potvrdenie sme Vám zaslali na e-mail',
    back_to_main: 'Späť na hlavnú stránku',
    order_number: 'Číslo objednávky',
    order_info: 'Informácie o stave objednávky Vám budeme priebežne posielať na Váš e-mail',
    order_bonus: 'Nákupom ste získali',
    order_bonus_2: 'vernostných bodov',
    empty_basket: 'Váš košík je prázdny',
    empty_basket_text: 'Nájdite produkt a pridajte ho do košíka',
    order_error: 'Chyba',
    order_error_text: 'Pri odosielaní objednávky nastala chyba. Skúste požiadavku opakovať.',
    get_trade_free1: 'Nakúpte ešte za',
    get_trade_free2: 'a získate dopravu zadarmo',
    trade_is_free: 'Doprava zadarmo',
    register: 'Chcem sa zaregistrovať',
    buy_in_salon: 'Produkty zakúpené v salóne',
    register_error: 'Registrácia',
    email_already_registered: 'E-mail, ktorý ste zadali, je už registrovaný v našej databáze.',
    login_text: 'Po prihlásení budete presmerovaný na kozmetický salón, v ktorom ste zaregistrovaný',
    buy_without_login: 'Zostať nakupovať tu',
    warning: 'Upozornenie',
    trade_free_ads: 'Doprava zadarmo pri kúpe produktov za min. sumu',
    delete_from_card: 'Odstrániť',
    cookies: 'Na poskytovanie služieb využívame len nevyhnutné cookies. Prechádzaním webu vyjadrujete súhlas s ich používaním.',
    cookies_info: 'Viac informácii',
    eshop_not_ready: 'Prebieha údržba e-shopu. Počas údržby nie je možné nakupovať.',
    agree: 'Súhlasím',
    add_note: 'Pridať poznámku k nákupu',
    note: 'Poznámka',
    order_note: 'Poznámka k  nákupu',
    ico: 'IČO',
    dic: 'DIČ',
    iban: 'IBAN',
    stack_question: 'Na vyžiadanie',
    change_password: 'Zmeniť heslo',
    captcha_error: 'Chýba overenie, že nie ste robot',
    app_appstore: 'Aplikácia pre zariadenia Apple',
    app_googleplaystore: 'Aplikácia pre zariadenia Android',
    action: 'Darčekové balenia',
    products_others: 'Ostatné produkty',
    section_no_products: 'V danej skupine sa nenachádzajú žiadne produkty',

    section_action: 'Darčekové balenia',
    section_face: 'Starostlivosť o tvár',
    section_face_id: 1,
    section_body: 'Starostlivosť o telo',
    section_body_id: 2,
    section_summer: 'Opaľovanie',
    section_summer_id: 3,

    category_prevychova: 'Prevýchova',
    category_prevychova_id: 1,
    category_antiage: 'Antiage',
    category_antiage_id: 2,
    category_intensive: 'Intensive',
    category_intensive_id: 3,
    category_leto: 'Leto',
    category_leto_id: 4,
    category_telo: 'Telo',
    category_telo_id: 5,
    category_cistenie: 'Čistenie',
    category_cistenie_id: 6,

    booking: 'Objednávka produktov pre salón',
    booking_all: 'Celkový počet objednaných produktov',
    orderer: 'Odberateľ',
    amount: 'Množstvo',
    order_days: 'Dodacia doba do 7 pracovných dní',
    app_download: 'Stiahnite si zadarmo mobilnú aplikáciu Esthederm Salon',
    product_not_found: 'Žiaľ požadovaný produkt sa nenašiel. Je možné, že produkt sa už nenachádza v našej ponuke.',
}



// ************************************************************************************
// CESTINA
// ************************************************************************************
const cz = {
    title: 'Kosmetické salony',
    www: 'www.esthedermsalon.cz',
    esthederm_salon: 'Esthederm Salon',
    face_id: 4,
    body_id: 6,
    summer_id: 7,
    voucher_value: 12500,
    copyright: 'Copyright',
    country: 'Česko',
    home: 'Domů',
    eshop: 'E-shop',
    eshop_text: 'Začít nakupovat',
    my_account: 'Můj účet',
    logout: 'Odhlásit se',
    salon_choose: 'Vybrat salon',
    contact: 'Kontakt',
    ie_salons: 'Kosmetické salony Institut Esthederm',
    salons: 'Kosmetické salony',
    ie: 'Institut Esthederm',
    choose_salon: 'Vyberte si e-shop kosmetických salonů',
    gdpr: 'Ochrana osobních údajů',
    cookies_rules: 'Zásady používání souborů cookies',
    sell_docs: 'Všeobecné obchodní podmínky',
    services: 'Služby',
    eshops: 'Vybrat salon',

    login: 'Přihlášení',
    login_email: 'E-mailová adresa',
    loginname: 'Přihlašovací jméno (e-mail)',
    loginname_error: 'Přihlašování jméno musí být e-mailová adresa',
    login_data: 'Přihlašovací údaje',
    username: 'Přihlašovací jméno',
    email_error: 'Nesprávný formát e-mailové adresy',
    password: 'Heslo',
    password_old: 'Původní heslo',
    password_old_err: 'Původní heslo je nesprávné',
    password_: 'Heslo (minimální délka 8 znaků)',
    password_new: 'Nové heslo (minimálně 8 znaků)',
    password_new_again: 'Zopakujte nové heslo',
    passwords_not_equal: 'Zadaná hesla se neshodují',
    password_ok: 'Heslo bylo úspěšně změněno',
    password_error: 'Při změně hesla nastala chyba. Zkuste požadavek opakovat.',
    password_error_len: 'Minimální délka hesla musí být 8 znaků',
    password_change: 'Změnit heslo',
    password_change_: 'Změna hesla',
    login_button: 'Přihlásit se',
    lost_password_button: 'Zapomněli jste heslo?',
    login_error: 'Nesprávné přihlašovací údaje!',
    lost_password: 'Zapomenuté heslo',
    lost_login_error: 'Zadaná e-mailová adresa se nenachází v systému',
    login_pin_code: 'Zadejte kód z e-mailu',
    login_pin_code_error: 'Nesprávný ověřovací kód',
    login_email_send: 'Na Vaši e-mailovou adresu jsme zaslali ověřovací kód. Zkontrolujte také spamový adresář.',
    password_changed: 'Změna hesla proběhla úspěšně',

    email_adress: 'E-mailová adresa',
    email: 'E-mail',
    send: 'Poslat',
    logout_question: 'Chcete se odhlásit',
    cancel: 'Zrušit',
    call: 'Volejte',
    basket: 'Košík',
    ie: 'Institut Esthederm',
    search: 'Hledat',
    search_text: 'Hledat produkt',
    search_label: 'Hledat produkt v e-shopu',
    distance: 'Vzdálenost hledání',
    salon_search_label: 'Hledání nejbližších kosmetických salonů',
    salon_search_label_2: 'dle Vašeho bydliště',
    your_town: 'Místo bydliště',
    search_result: 'Výsledek hledání',
    salons_searched: 'Nalezené kosmetické salony',
    salons_count: 'Počet salonů',
    search_salons: 'Hledat salony',
    search_salons_none: 'Žádné salony v zadaném okolí',
    login_error: 'Nesprávné přihlašovací údaje',
    services_ie: 'Ošetření Institut Esthederm',
    ie_text1: 'Kosmetický salon',
    ie_text2: 'Jak se vše kolem nás mění a svět se pohybuje rychleji, ošetření v salonu INSTITUT ESTHEDERM, Vám umožňuje zatlačit tlačítko pauzy a ponořit se do maximální relaxace.',
    ie_text3: 'Objevíte škálu vjemů, účinné rituály a pochopíte sílu doteku, díky jemným rukám.',
    ie_text4: 'Naše unikátní patenty a techniky ošetření jsou navrženy ve spolupráci s odborníky v péči o pleť, k návratu krásy a mladistvosti každé pokožky a to dlouhodobě.',
    service_1: 'Čištění',
    service_2: 'Základní převýchovná ošetření',
    service_3: 'Intenzivní ošetření',
    service_4: 'Sezónní ošetření',
    service_5: 'Regenerační ošetření',
    service_hands: 'Ruce',
    service_body: 'Tělo',
    service_face: 'Tvář',
    contact_us: 'Kontaktujte nás',
    mobil: 'Mobil',
    write_us: 'Chcete se na něco zeptat? Napište nám.',
    opening_hours: 'Otevírací hodiny',
    day_1: 'Pondělí',
    day_2: 'Úterý',
    day_3: 'Středa',
    day_4: 'Čtvrtek',
    day_5: 'Pátek',
    day_6: 'Sobota',
    day_7: 'Neděle',
    day_1_place: '8:00 - 12:00   13:00 - 16-00',
    day_2_place: '8:00 - 12:00',
    day_3_place: 'na objednávku',
    day_4_place: 'zavřeno',
    name: 'Jméno',
    surname: 'Příjmení',
    name_surname: 'Vaše jméno a příjmení',
    email_answer: 'E-mailová adresa na odpověď',
    email_text: 'Text zprávy',
    send_msg: 'Odeslat zprávu',
    send_msg_ok: 'Zpráva byla úspěšně odeslána',
    send_msg_err: 'Při odesílání zprávy nastala chyba',
    send_err_name: 'Chybí jméno a příjmení',
    send_err_email: 'Nesprávný formát e-mailu',
    send_err_text: 'Chybí text zprávy',
    services_offer: 'Nabídka služeb a ošetření',
    services_offer_text: 'kosmetického salonu',
    services_ie: 'Ošetření Institut Esthederm',
    services_my: 'Ostatní služby',
    ie_motto: 'INSTITUT ESTHEDERM - EKOBOLOGIE VE SLUŽBÁCH ESTETIKY',
    close: 'Zavřít',
    back: 'Zpět',
    service_time: 'Délka ošetření',
    minutes: 'minut',
    price: 'Cena',
    money: 'Kč',
    description: 'Popis',
    service_description: 'Popis služby',
    history: 'Historie',
    histrory_services: 'Absolvovaná ošetření',
    histrory_products: 'Zakoupené produkty',
    settings: 'Nastavení',
    info: 'Informace',
    bonus_system: 'Věrnostní systém',
    bonus: 'Věrnostní body',
    bonus_count: 'Počet věrnostních bodů',
    user_info: 'Osobní údaje',
    user_post: 'Poštovní adresa',
    vouchers: 'Dárkové poukázky',
    voucher_none: 'Žádné dárkové poukázky',
    pieces: 'ks',
    bonus_sum: 'Celkový počet věrnostních bodů',
    voucher_get: 'Za věrnostní body získáváte',
    vouchers_all: 'Celkový počet dárkových poukázek',
    vouchers_use: 'Dárkové poukázky můžete použít na slevu ošetření INSTITUT ESTHEDERM v našem kosmetickém salonu',
    bonus_about: 'Více o věrnostním systému',
    date: 'Datum',
    category: 'Kategorie',
    category_choose: 'Výběr kategorie',
    category_products: 'Kategorie produktů',
    details: 'Detaily',
    code: 'Obj.kód',
    orders: 'Historie nákupů',
    orders_products: 'Objednávky produktů z e-shopu',
    card_trade: 'Doprava',
    card_payment: 'Platba',
    sume: 'Suma',

    order_status_text: 'Po změně stavu objednávky, systém automaticky odešle e-mail zákazníkovi',
    order_created: 'Vytvořená',
    order_in_proccess: 'V přípravě ',
    order_ready: 'Připraveno na výdej',
    order_sended: 'Odeslaná',
    order_finished: 'Ukončena',
    order_canceled: 'Zrušena',
    order_cancel_request: 'Žádost o zrušení objednávky',
    order_cancel_request_sended: 'Odeslaný požadavek o zrušení objednávky',
    order_cancel: 'Zrušit objednávku',
    order_cancel_question: 'Zrušit objednávku produktů na sklad',
    order_cancel_question_text: 'Žádost o zrušení objednávky bude odeslána obchodnímu zástupci',
    order: 'Objednávka',
    order_kurier: 'Doručováno kurýrem',
    order_waiting: 'Čeká na zpracování',

    eur_piece: 'Cena za 1 kus',
    order_quantity: 'Počet zakoupených kusů',
    edit: 'Upravit',
    save: 'Uložit',
    number: 'Číslo',
    street: 'Ulice',
    town: 'Město',
    psc: 'PSČ',
    email_error: 'Zadanou E-mailovou adresu již používá jiný účet',
    update_error: 'Při ukládání změn došlo k chybě. Zkuste požadavek opakovat.',
    account_update_error: 'Při ukládání změn došlo k chybě.',
    required: 'Povinný údaj',
    product_in_order: 'Zakoupené produkty',

    verification_email_error: 'Nesprávná e-mailová adresa',
    verification_code: 'Ověřovací kód',
    verification_code_enter: 'Ověřovací kód jsme odeslali na vaši e-mailovou adresu. Zadejte kód z vašeho e-mailu',
    verification_code_error: 'Nesprávny kód',
    verification_code_resend: 'Nedostali jste ověřovací kód? Odeslat kód.',
    password_change: 'Změna hesla',
    password_new: 'Zadejte nové heslo (minimálně 8 znaků)',
    password_new_again: 'Zopakujte nové heslo',
    passwords_not_equal: 'Zadaná hesla se neshodují',
    password_ok: 'Heslo bylo úspěšně změněno',
    password_error: 'Při změně hesla nastala chyba. Zkuste požadavek opakovat.',
    password_min_len: 'Minimální délka hesla je 8 znaků',
    verify: 'Ověřit',
    town_error: 'Zadané město nebylo nalezeno',

    eshop_hands: 'Péče o ruce',
    eshop_body: 'Péče o tělo',
    eshop_face: 'Péče o obličej',
    eshop_summer: 'Opalování',
    eshop_new_products: 'Nové produkty v e-shope',
    ordering: 'Řadit podle',
    ordering_name: 'Názvu',
    ordering_price_low: 'Od nejnižší ceny',
    ordering_price_high: 'Od nejvyšší ceny',
    show_more: 'Zobrazit další',
    buy: 'Do košíku',
    instock: 'Skladem',
    nostock: 'Do tří dnů',
    interest_products: 'Mohlo by vás zajímat',
    search_result: 'Výsledek vyhledávání výrazu',
    search_min: 'Zadejte alespoň dva znaky',
    products_finded: 'Nalezených produktů',
    products_finded_none: 'Hledaný výraz nemůžeme v našem katalogu najít. Zkuste prosím použít jiný výraz.',
    added_to_card: 'Přidáno do košíku',
    continue_to_shop: 'Pokračovat v nákupu',
    continue_to_card: 'Pokračovat do košíku',
    card: 'Nákupní košík',
    card_sum: 'Celková cena včetně DPH',
    back_to_shop: 'Zpět do obchodu',
    to_checkout: 'K pokladně',
    checkout_step_1: 'Doprava a platba',
    checkout_step_2: 'Adresa dodání',
    checkout_step_3: 'Shrnutí objednávky',
    trade: 'Způsob dopravy',
    payment: 'Způsob platby',
    free: 'zdarma',
    continue: 'Pokračovat',
    send_order: 'Objednávka zavazující k platbě',
    client_info: 'Informace o Vás',
    client_address: 'Adresa doručení',
    client_contact: 'Kontaktní údaje',
    back_to_trade: 'Zpět na výběr dopravy',
    back_to_address: 'Zpět na údaje',
    products_price: 'Cena produktu',
    sum_with_dph: 'Celkem včetně DPH',
    sumar: 'Shrnutí objednávky',
    gdpr_agree: 'Souhlasím s všeobecnými obchodními podmínkami a zpracováním osobních údajů.',
    order_sended_ok: 'Děkujeme za objednávku.',
    order_sended_ok_text: 'Potvrzení jsme Vám zaslali na e-mail',
    back_to_main: 'Zpět na hlavní stránku',
    order_number: 'Číslo objednávky',
    order_info: 'Informace o stavu objednávky Vám budeme průběžně posílat na Váš e-mail',
    order_bonus: 'Nákupem jste získali',
    order_bonus_2: 'věrnostních bodů',
    empty_basket: 'Váš košík je prázdný',
    empty_basket_text: 'Najděte produkt a přidejte jej do košíku',
    order_error: 'Chyba',
    order_error_text: 'Při odesílání objednávky nastala chyba. Zkuste požadavek opakovat.',
    get_trade_free1: 'Nakupte ještě za',
    get_trade_free2: 'a získáte dopravu zdarma',
    trade_is_free: 'Doprava zdarma',
    register: 'Chci se zaregistrovat',
    buy_in_salon: 'Produkty zakoupené v salonu',
    register_error: 'Registrace',
    email_already_registered: 'E-mail, který jste zadali, je již registrován v naší databázi.',
    login_text: 'Po přihlášení budete přesměrováni na kosmetický salon, ve kterém jste zaregistrováni',
    buy_without_login: 'Zůstat nakupovat zde',
    warning: 'Upozornění',
    trade_free_ads: 'Doprava zdarma při koupi produktů za min. částku',
    delete_from_card: 'Odstranit',
    cookies: 'K poskytování služeb využíváme jen nezbytné cookies. Procházením webu vyjadřujete souhlas s jejich používáním.',
    cookies_info: 'Více informací',
    eshop_not_ready: 'Probíhá údržba e-shopu. Během údržby nelze nakupovat.',
    agree: 'Souhlasím',
    add_note: 'Přidat poznámku k nákupu',
    note: 'Poznámka',
    order_note: 'Poznámka k nákupu',
    ico: 'IČO',
    dic: 'DIČ',
    iban: 'IBAN',
    stack_question: 'Na vyžádání',
    change_password: 'Změnit heslo',
    captcha_error: 'Chybí ověření, že nejste robot',
    app_appstore: 'Aplikace pro zařízení Apple',
    app_googleplaystore: 'Aplikace pro zařízení Android',
    action: 'Dárková balení',

    section_action: 'Dárková balení',
    products_others: 'Ostatní produkty',
    section_no_products: 'V dané skupině se nenacházejí žádné produkty',
    section_face: 'Péče o obličej',
    section_face_id: 4,
    section_body: 'Péče o tělo',
    section_body_id: 6,
    section_summer: 'Opalování',
    section_summer_id: 7,

    category_prevychova: 'Převýchova',
    category_prevychova_id: 7,
    category_antiage: 'Antiage',
    category_antiage_id: 8,
    category_intensive: 'Intensive',
    category_intensive_id: 9,
    category_leto: 'Léto',
    category_leto_id: 10,
    category_telo: 'Tělo',
    category_telo_id: 11,
    category_cistenie: 'Čistení',
    category_cistenie_id: 12,

    booking: 'Objednávka produktů pro salon',
    booking_all: 'Celkový počet objednaných produktů',
    orderer: 'Odběratel',
    amount: 'Množství',
    order_days: 'Dodací doba do 7 pracovních dnů',

    app_download: 'Stáhněte si zdarma mobilní aplikaci Esthederm Salon',
    product_not_found: 'Žiaľ požadovaný produkt sa nenašiel. Je možné, že produkt sa už nenachádza v našej ponuke.',
};


export { sk, cz };